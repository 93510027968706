import axios from 'axios';
import Config from "../config.json";

///const API_BASE_URL = `${process.env.REACT_APP_APIREST_BASE_URL}/v1/Company`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/CompanyPlanSubscription`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
};    


const put  = async (auth_token, id) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.put( `${API_BASE_URL}/UpdateDisableAllByCompanyAsync/${id}`, null, CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}

const post = async (auth_token, values) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.post(  `${API_BASE_URL}/InsertAsync`, values , CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}

export const companyPlanSubscriptionService = {
    post,
    put
}