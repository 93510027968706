import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { clientService } from '../../Services/clientService';

import { useAuth } from '../../Context/auth';

const ClientAddEdit = () => {
    const auth = useAuth(); 

    const { id } = useParams();
    const isAddMode = !id;    
    const navigate =  useNavigate();   

    const [stateClient, setStateClient] = useState({
        id: 0,
        name: '',
        email: 0,
        phone: '',
        ssoId: auth.user.id
    });    
    
    useEffect(() => {
        if (!isAddMode) {
            clientService.getById(auth?.user.token,id).then(response => {           
                setStateClient(response.data);                
            });      
        }
    }, []);

    const onChangehandleChange = (event) => {
        const {name, value} = event.target;
        setStateClient((prevState) => ({
            ...prevState,
            [name]: value
        }));        
    }

    const onClickbtnSaveUpdate = () => {
        if (isAddMode) {           
            clientService.post(auth.user.token,stateClient);
        } else {
            clientService.put(auth.user.token,stateClient.id, stateClient);
        }     

        // Redireccionar
        navigate('/clientList', { replace: true });
    }

    const onClickbtnCancel = (e) => {
        e.preventDefault();

        try {
          navigate('/clientList', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    return (
        <>
            <div className="container">
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>{ isAddMode  ? 'Crear Cliente' : 'Editar Cliente' }</h2>
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>                                                        
                <div className="row">
                    <div className="col">
                        <label htmlFor="name" className="form-label">Nombre</label>
                        <input className="form-control" placeholder="" required="" 
                            id="name"
                            name="name"
                            type="text"
                            onChange={onChangehandleChange}
                            value={stateClient.name}            
                        />                                  
                    </div>
                    <div className="col">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input className="form-control" placeholder="" required="" 
                            id="email"
                            name="email"
                            type="text"
                            onChange={onChangehandleChange}
                            value={stateClient.email}              
                        />
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="phone" className="form-label">Telefono</label>
                        <input className="form-control" placeholder="" required="" 
                            id="phone"
                            name="phone"
                            type="text"
                            onChange={onChangehandleChange}
                            value={stateClient.phone}              
                        />                                    
                    </div>
                    <div className="col">                    
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <div className='row'>
                    <div className='col'>
                        <button style={{backgroundColor:'#FD9913', color:'white'}} type="submit" className="w-100 btn btn-success" onClick={e => onClickbtnSaveUpdate(e)}>{isAddMode ? ' Agregar cliente ' : ' Actualizar cliente '}</button>    
                    </div>
                    <div className='col'>
                        <button style={{backgroundColor:'#0D6EFD', color:'white'}} type="button" className="w-100 btn btn-secondary" onClick={e => onClickbtnCancel(e)}>Cancelar</button>                        
                    </div>                        
                </div>
            </div>                   
        </>
    )
}

export default ClientAddEdit;