import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import CommerceProductListUI from './CommerceProductListUI';

import { businessService } from '../../Services/businessService';
import { businessProductService } from '../../Services/businessProductService';

import { useAuth }from '../../Context/auth'

const CommerceProductList = () => {

    const auth = useAuth(); 
    
    const navigate =  useNavigate(); 

    const [stateBusiness, setStateBusiness] = useState([]);
    const [stateSelectedBusinessId, setStateSelectedBusinessId] = useState([]);
    const [stateBusinessProduct, setStateBusinessProduct] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            businessService.getAllByCompanyId(auth.user.token, auth.user.companyId).then( res => {            
                setStateBusiness(res.data);
            });             
          }, 1000);
    }, []);    

    const onClickbtnAdd = (e) => {
        e.preventDefault();

        try {
          navigate('/commerceProduct/Add', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    const onClickbtnEdit = (e, id) => {
        e.preventDefault();

        try {
          navigate(`/commerceProduct/Edit/${id}`, { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    const onClickbtnDelete = (e, id) => {
        businessProductService.delete(auth.user.token, id).then(() => {
            setStateBusinessProduct(stateBusiness => stateBusiness.filter(x => x.id !== id));
        });    
    }

    const onClickDOMEvent_btnFiltrar = (e) => {
        e.preventDefault();
        businessProductService.getByBusinessId(auth.user.token, stateSelectedBusinessId).then(res => {                    
            setStateBusinessProduct(res.data);
        });    
    }

    const onChangeDOMEvent_selectbusiness = (e) => {
        setStateSelectedBusinessId(e.target.value);
    }
    
    return (
        <CommerceProductListUI 
            stateBusinessProduct={stateBusinessProduct} 
            stateBusiness= {stateBusiness}
            onClickbtnAdd={onClickbtnAdd}
            onClickbtnEdit={onClickbtnEdit}
            onClickbtnDelete={onClickbtnDelete}
            onClickDOMEvent_btnFiltrar= {onClickDOMEvent_btnFiltrar}
            onChangeDOMEvent_selectbusiness= {onChangeDOMEvent_selectbusiness}
        > 
        </CommerceProductListUI>
    );
}

export default CommerceProductList;