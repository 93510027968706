import React, { useState, useEffect }from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import swal from 'sweetalert';

import { businessService } from '../../Services/businessService';
import {salesService} from '../../Services/salesService';
import {cxcService} from '../../Services/Cxc/cxcService';

import PopUpPlanSimulator from './popupPlanSimulator';

import { useAuth }from '../../Context/auth';
import { Utils }from '../../Utils/utils';

import { AiOutlineDislike, AiOutlineLike } from 'react-icons/ai';

const PaymentGatewayList = () => {
    const auth = useAuth(); 
    
    const navigate =  useNavigate(); 

    const [stateBusiness, setStateBusiness] = useState([]);
    const [stateSelectedBusinessId, setStateSelectedBusinessId] = useState([]);

    const [stateSales, setStateSales] = useState([]);
    const [stateSaleSeleted, setStateSaleSelected] = useState(0);
    const [statePagination, setStatePagination] = useState([]);
    const [stateSeenPopup, setstateSeenPopup] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            businessService.getAllByCompanyId(auth.user.token, auth.user.companyId).then( res => {   
                let newList = [];
                auth.user.commerceList.map(item =>  {
                    const index = res.data.findIndex((x) => x.id === item);
                    if(index != -1) {
                        newList = [...newList, res.data[index]];
                    }
                });               
                setStateBusiness(newList);                
            });                 
        }, 1000);        
    }, []);    

    const onClickbtnAdd = (e) => {
        e.preventDefault();

        try {
          navigate('/salesAddEdit/Add', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    // const onClickbtnEdit = (e, id) => {
    //     e.preventDefault();

    //     try {
    //       navigate(`/salesAddEdit/Edit/${id}`, { replace: true });
    //     } catch(e) {
    //         console.log('error: ' + e);
    //     }    
    // }

    const onClickbtnDelete = async (e, id) => {
        await cxcService.deleteByVentaId(id).then((res) => {
            console.log(res);
        })
        await salesService.delete(auth.user.token, id).then(() => {
            setStateSales(stateSales => stateSales.filter(x => x.id !== id));
        });
    }

    const onClickaSeeCxcPlan = (e, id) => {
        e.preventDefault();
        setStateSaleSelected(id)
        setstateSeenPopup(true);    
    };

    const onClickDOMEvent_btnFiltrar = () => {
        salesService.getAllWithBalanceBySSOId(auth.user.token, auth.user.companyId).then(res => {
            var dataFiltered = res.data.filter(f => f.businessId == stateSelectedBusinessId );    
            if(dataFiltered.length == 0) {
                setStateSales([]);
                swal("No existen datos!", "favor de realizar de nuevo la busqueda!", "success")
            } else {
                setStateSales(dataFiltered);
            }            
        });
    }

    const togglePop = () => {
        setstateSeenPopup(!stateSeenPopup);    
    };

    const onChangeDOMEvent_selectbusiness = (e) => {
        setStateSelectedBusinessId(e.target.value);
    }
    

    return (
        <>
            <br></br>
            <br></br>            
            <div className='container'>                                
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Listado de Ventas</h2>
                    </div>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-end'>
                        {/* <button type="button" className="btn btn-primary" onClick={e => onClickbtnAdd(e)}>Agregar</button> */}
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <div className='col-10'>
                        <label htmlFor="businessId" className="form-label">Listado de sucursales</label>
                        <select
                            className="form-control"
                            id="businessId"
                            name="businessId"                                            
                            onChange={ onChangeDOMEvent_selectbusiness.bind(this) }  
                        > 
                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                        {
                            stateBusiness.map((item, index) => <option key={index} value={item.id}>{item.businessName}</option>)
                        }
                        </select>
                    </div>
                    <div className='col-2' style={{textAlign:'right'}}>
                        <button type="button" className="btn btn-primary" style={{ marginTop: "30px" }}
                            onClick={e => onClickDOMEvent_btnFiltrar(e)}>Filtrar
                        </button> 
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>
                <div className='row'>
                <table className="table">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Comercio</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Descripcion</th>                            
                            <th scope="col">Cliente</th> 
                            <th scope="col">Venta</th> 
                            <th scope="col">Adelanto</th>                             
                            <th scope="col">Saldo</th>   
                            <th scope="col"># Cxc</th>   
                            <th scope="col"></th>   
                            <th scope="col">%</th>   
                            <th scope="col">Token</th>
                            <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                stateSales && stateSales?.map(item => 
                                    <tr key={item.id}>
                                        <th scope="row">{item.id}</th>                                        
                                        <td>{item.businessName}</td>
                                        <td>
                                            <Moment format="DD/MM/YYYY">
                                                {item.saleDate}
                                            </Moment>
                                        </td>
                                        <td>{item.description}</td>
                                        <td>{item.clientName}</td>
                                        <td>{Utils.currencyFormat(item.saleAmmount)}</td>
                                        <td>{Utils.currencyFormat(item.preAmmount)}</td>
                                        <td>{Utils.currencyFormat(item.balance)}</td>
                                        <td><a className='btn' onClick={e => onClickaSeeCxcPlan(e, item.id)}>{item.numCxc}</a></td>
                                        <td>{item.totalNumCxcPagos}/{item.numCxc}</td>
                                        <td>{(item.totalNumCxcPagos === '0' ? 0 : (item.totalNumCxcPagos * 100)/item.numCxc).toFixed(0)}%</td>
                                        <td>
                                            {
                                                item.tokenTarjetaParaPago ? <AiOutlineLike style={{fontSize: '25px'}}/> : <AiOutlineDislike style={{fontSize: '25px'}} />
                                            }
                                        </td>
                                        <td className='d-md-flex justify-content-md-end'>                                                                                        
                                            {/* <button type="button" className="btn btn-warning" onClick={e => onClickbtnEdit(e, item.id)}>Editar</button> |  */}
                                            {
                                                item.totalNumCxcPagos === 0 && item.tokenTarjetaParaPago === false ? 
                                                <button type="button" className="btn btn-danger" onClick={e => onClickbtnDelete(e, item.id)}>Eliminar</button> :
                                                null
                                            }   
                                            
                                        </td>
                                    </tr>                                
                                )
                            }
                        </tbody>
                    </table>                    
                </div>
                <div className='row'>
                    <ul className="pagination">
                        {
                          statePagination.map(item => {
                            <li key={item}><a href="#">item</a></li>
                          })
                        }                        
                    </ul>
                </div>
            </div>
            {stateSeenPopup ? <div> <PopUpPlanSimulator                                 
                                    ventaid={ stateSaleSeleted }                            
                                    datebegin={new Date()}
                                    dateend={new Date()}
                                    ammount={0}
                                    frecuency={0}
                                    togglePop={ togglePop } 
                                    setStatePlanFromPopUp = { [] }
                                /> 
                            </div> : null}
        </>
    );

};

export default PaymentGatewayList;