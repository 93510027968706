import React, { useState } from 'react';

import { Utils } from '../../Utils/utils'

const CxcPaymentByDateUI = (props) => {
    return (
        <>
            <br></br>
            <br></br>            
            <div className='container'>                                
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Bitacora fin de dia</h2>
                    </div>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-end'>
                        {/* <button type="button" className="btn btn-primary" onClick={e => onClickbtnAdd(e)}>Agregar</button> */}
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>
                <div className='row'>
                    <div className='col'>
                        <label htmlFor="dateBegin" className="form-label">Desde</label>
                        <input 
                            className="form-control"
                            id="dateBegin"
                            name="dateBegin"
                            type="date"
                            value={ props.stateDateBegin }
                            placeholder=""
                            onChange={ props.onChangeDOMEvent_inputDateBegin.bind(this) }  
                        />
                    </div>
                    <div className='col'>
                        <label htmlFor="dateEnd" className="form-label">Hasta</label>
                        <input 
                            className="form-control"
                            id="dateEnd"
                            name="dateEnd"
                            type="date"
                            value={ props.stateDateEnd }
                            placeholder=""      
                            onChange={ props.onChangeDOMEvent_inputDateEnd.bind(this) }                           
                        />
                    </div>
                    <div className='col'>
                        <button className="btn btn-primary" style={{ marginTop: "30px" }}
                            onClick={e => props.onClickDOMEvent_btnFiltrar(e)}>Filtrar
                        </button> 
                    </div>
                </div>
                <br></br>
                <div className='row'>                   
                    <hr></hr>
                    <h2>Informaciòn de fin de dìa</h2>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Comercio</th>                                                            
                                <th scope="col">Id Venta</th> 
                                <th scope="col">Fec Venta</th> 
                                <th scope="col">Cliente</th>                            
                                <th scope="col">Producto</th> 
                                <th scope="col">Fec Vencimiento</th>      
                                <th scope="col">Valor</th>      
                                <th scope="col">Id Cxc</th>                                
                                <th scope="col">Fec Exp</th>
                                <th scope="col">Valor</th>
                                <th scope="col">Notas</th>
                                <th scope="col">Referencia</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.stateCxcPaymentArray && props.stateCxcPaymentArray?.map(item =>                                 
                                    <tr key={item.Id}>
                                        <td>{item.nombreComercial}</td>
                                        <td>{item.ventaID}</td>
                                        <td>{item.fechaVta}</td>
                                        <td>{item.nombreCliente}</td>
                                        <td>{item.nombreProducto}</td>
                                        <td>{item.fechaVencimiento}</td>
                                        <td>{Utils.currencyFormat(item.valor)}</td>
                                        <td>{item.cxcId}</td>  
                                        <td>{item.fechaExpedicion}</td>  
                                        <td>{Utils.currencyFormat(item.monto)}</td>
                                        <td>{item.notas}</td>                                            
                                        <td>{item.referencia01}</td>                                            
                                    </tr>                                    
                                )
                            }
                        </tbody>
                    </table>                                            
                </div>              
            </div>    
        </>    
    );
}

export default CxcPaymentByDateUI;