import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Utils } from '../../Utils/utils'

const SalesWithPaymentsUI = (props) => {
    const navigate =  useNavigate(); 

    const onClickbtnViewPlan = (e, id) => {
        e.preventDefault();

        // Redireccionar
        navigate(`/paymentPlanView/${id}`, { replace: true });        
    }

    return (
        <>                     
            <div className='container'>                                
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Saldos x venta</h2>
                    </div>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-end'>                        
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>
                <div className='row'>
                    <div className='col'>
                        <label htmlFor="businessId" className="form-label">Listado de sucursales</label>
                        <select
                            className="form-control"
                            id="businessId"
                            name="businessId"                                            
                            onChange={ props.onChangeDOMEvent_selectbusiness.bind(this) }  
                        > 
                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                        {
                            props.stateBusiness.map((item, index) => <option key={index} value={item.id}>{item.businessName}</option>)
                        }
                        </select>
                    </div>
                    <div className='col'>
                        <label htmlFor="dateBegin" className="form-label">Desde</label>
                        <input 
                            className="form-control"
                            id="dateBegin"
                            name="dateBegin"
                            type="date"
                            value={ props.stateDateBegin }
                            placeholder=""
                            onChange={ props.onChangeDOMEvent_inputDateBegin.bind(this) }  
                        />
                    </div>
                    <div className='col'>
                        <label htmlFor="dateEnd" className="form-label">Hasta</label>
                        <input 
                            className="form-control"
                            id="dateEnd"
                            name="dateEnd"
                            type="date"
                            value={ props.stateDateEnd }
                            placeholder=""      
                            onChange={ props.onChangeDOMEvent_inputDateEnd.bind(this) }                           
                        />
                    </div>                    
                    <div className='col'>
                        <button className="btn btn-primary" style={{ marginTop: "30px" }}
                            onClick={e => props.onClickDOMEvent_btnFiltrar(e)}>Filtrar
                        </button> 
                    </div>
                </div>
                <br></br>
                <div className='row'>                   
                    <hr></hr>
                    <h2>Saldos x ventas</h2>
                    <table className="table">
                        <thead>
                            <tr>                                
                                <th scope="col">Id Venta</th> 
                                <th scope="col">Fec Venta</th> 
                                <th scope="col">Descripcion</th>                            
                                <th scope="col">Comercio</th> 
                                <th scope="col">Cliente</th>      
                                <th scope="col">Monto venta</th>      
                                <th scope="col">Monto adelanto</th>                                
                                <th scope="col">Total pagos</th>
                                <th scope="col">Saldo</th>
                                <th scope="col">Plan de pagos</th>                              
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.stateSaleCxcPaymentArray && props.stateSaleCxcPaymentArray?.map(item =>                                 
                                    <tr key={item.ventaId}>                                        
                                        <td>{item.ventaId}</td>
                                        <td>{item.fechaVta}</td>
                                        <td>{item.descripcion}</td>                                        
                                        <td>{item.nombreComercial}</td>
                                        <td>{item.nombre}</td>                                        
                                        <td>{Utils.currencyFormat(item.montoVenta)}</td>
                                        <td>{Utils.currencyFormat(item.montoAdelanto)}</td>                                        
                                        <td>{Utils.currencyFormat(item.totalPagos)}</td>                                        
                                        <td>{Utils.currencyFormat(((item.montoVenta - item.montoAdelanto) - item.totalPagos))}</td>
                                        <td>
                                            <button type="button" className="btn btn-warning" onClick={e => onClickbtnViewPlan(e, item.ventaId)}>Vista</button>
                                        </td>
                                    </tr>                                    
                                )
                            }
                        </tbody>
                    </table>                                            
                </div>              
            </div>    
        </>    
    );
}

export default SalesWithPaymentsUI;