import React from 'react'

import { AppRouter } from './Routers/AppRouter';

import './App.css';

function App() {
  return (
    <AppRouter></AppRouter> 
  );
}

export default App;
