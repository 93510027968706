import React, { useState, useEffect }from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsDownToLine } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { businessService } from '../../Services/businessService';
import { cxcService } from '../../Services/Cxc/cxcService';

import PopUpAddPay from './popupAddPay';

import { useAuth } from '../../Context/auth';

import { Utils } from '../../Utils/utils';

const PaymentGatewayList = () => {
    const auth = useAuth();  
    const current = new Date();      

    const [stateBusiness, setStateBusiness] = useState([]);
    const [stateSelectedBusinessId, setStateSelectedBusinessId] = useState(0);
    const [stateVentaId, setStateVentaId] = useState(0);
    const [stateDateBegin, setStateDateBegin] = useState(moment(current).format("YYYY-MM-DD"));
    const [stateDateEnd, setStateDateEnd] = useState(moment(current).format("YYYY-MM-DD"));

    const [stateSaleSelected, setStateSaleSelected] = useState({});
    const [stateCxcIdSelected, setStateCxcIdSelected] = useState(0);
    const [stateSales, setStateSales] = useState([]);
    const [stateCxcs, setStateCxcs] = useState([]);

    const [stateSeenPopup, setstateSeenPopup] = useState(false);

    //const [stateRefresh, setstaterefresh] = useState(1);
    
    useEffect(() => {
        setTimeout(() => {            
            businessService.getAllByCompanyId(auth.user.token, auth.user.companyId).then( res => {  
                let newList = [];
                auth.user.commerceList.map(item =>  {
                    const index = res.data.findIndex((x) => x.id === item);
                    if(index != -1) {
                        newList = [...newList, res.data[index]];
                    }
                });               
                setStateBusiness(newList);               
            });                      
        }, 1000);
    }, []);    

    const onChangeDOMEvent_inputDateBegin = (item) => {
        setStateDateBegin(item.target.value);
    }

    const onChangeDOMEvent_inputDateEnd = (item) => {
        setStateDateEnd(item.target.value);
    }

    const onChangeDOMEvent_selectbusiness = (e) => {
        setStateSelectedBusinessId(e.target.value);
    }

    const onChangeDOMEvent_inputVentaid = (e) => {
        setStateVentaId(e.target.value);
    }

    const onClickbtnAdd = () => {
    }

    const onClickDOMEvent_btnFiltrar = (e) => {
        e.preventDefault();
        cxcService.getByBusinesidVentaDateH(auth.user.companyId, stateSelectedBusinessId, stateVentaId, 
            stateDateBegin, stateDateEnd).then(res => {        
            setStateSales(res.data);      
            setStateSaleSelected({});      
            setStateCxcs([]);
        });   
    }

    const onClickDOMEvent_btnVisCxc = (e, vtaId) => {
        e.preventDefault();
        cxcService.getByBusinesidVentaDateD(vtaId).then(res => {                                            
            setStateCxcs(res);   
            
            const vta = stateSales.find(f => f.ventaId === vtaId);

            setStateSaleSelected(vta);
        });   
    }

    const onClickbtnPay = (e, id) => {
        e.preventDefault();
        setStateCxcIdSelected(id)
        setstateSeenPopup(true);
    }

    const togglePop = (option) => {
        setstateSeenPopup(!stateSeenPopup);
        if (option === 'Refresh') {
            cxcService.getByBusinesidVentaDate(auth.id, stateSelectedBusinessId, stateVentaId, stateDateBegin, stateDateEnd).then(res => {                                
                setStateCxcs(res);
            });   
        }        
    };

    return (
        <>
            <br></br>
            <br></br>            
            <div className='container'>                                
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Listado de CXC</h2>
                    </div>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-end'>
                        {/* <button type="button" className="btn btn-primary" onClick={e => onClickbtnAdd(e)}>Agregar</button> */}
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>
                <div className='row'>
                    <div className='col'>
                        <label htmlFor="businessId" className="form-label">Sucursal</label>
                        <select
                            className="form-control"
                            id="businessId"
                            name="businessId"                                            
                            onChange={ onChangeDOMEvent_selectbusiness.bind(this) }  
                        > 
                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                        {
                            stateBusiness.map((item, index) => <option key={index} value={item.id}>{item.businessName}</option>)
                        }
                        </select>
                    </div>                    
                    <div className='col'>
                        <label htmlFor="dateBegin" className="form-label">Desde</label>
                        <input 
                            className="form-control"
                            id="dateBegin"
                            name="dateBegin"
                            type="date"
                            value={ stateDateBegin }
                            placeholder=""
                            onChange={ onChangeDOMEvent_inputDateBegin.bind(this) }  
                        />
                    </div>
                    <div className='col'>
                        <label htmlFor="dateEnd" className="form-label">Hasta</label>
                        <input 
                            className="form-control"
                            id="dateEnd"
                            name="dateEnd"
                            type="date"
                            value={ stateDateEnd }
                            placeholder=""      
                            onChange={ onChangeDOMEvent_inputDateEnd.bind(this) }                           
                        />
                    </div>
                    <div className='col'>
                        <label htmlFor="ventaid" className="form-label"># venta</label>
                        <input 
                            className="form-control"
                            id="ventaid"
                            name="ventaid"
                            type="number"
                            value={ stateVentaId }
                            placeholder=""
                            onChange={ onChangeDOMEvent_inputVentaid.bind(this) }  
                        />
                    </div>
                    <div className='col'>
                        <button className="btn btn-primary" style={{ marginTop: "30px" }}
                            onClick={e => onClickDOMEvent_btnFiltrar(e)}>Filtrar
                        </button> 
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <div className='col'>
                        <div id="accordion">
                        {
                            stateSales && stateSales?.map(item =>                                
                                <div className="card">
                                    <div className="card-header" id={`heading${item.ventaId}`}>
                                        <div className='row'>
                                            <div className='col'>
                                                <h5 className="card-title">Venta : {item.ventaId}</h5>
                                                <h6>Fecha : {item.fechaVta}</h6>
                                                <h6>Monto: {Utils.currencyFormat(item.montoVenta)}</h6>
                                                <h6>Adelanto: {Utils.currencyFormat(item.montoAdelanto)}</h6>
                                            </div>
                                            <div className='col'>
                                            <p className="card-text">                                     
                                                Sucursal: {item.nombreComercial}
                                            </p>                     
                                            <p className="card-text">
                                                Cliente: {item.nombre}
                                            </p>                       
                                            <p className="card-text">
                                                Abonos realizados: {Utils.currencyFormat(item.totalPagos)}
                                            </p>
                                            <p className="card-text">
                                                {item.descripcion}
                                            </p>      
                                            </div>
                                            <div className='col' style={{textAlign: "right"}}>
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link" data-toggle="collapse" data-target={`#collapse${item.ventaId}`} aria-expanded="true" 
                                                        aria-controls={`collapse${item.ventaId}`}>
                                                        <FontAwesomeIcon icon={faArrowsDownToLine} />                                                
                                                    </button>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div id={`collapse${item.ventaId}`} className="collapse" aria-labelledby={`heading${item.ventaId}`} data-parent="#accordion">
                                        <div className="card-body">
                                            <table className="table">
                                                <thead>
                                                    <tr style={{backgroundColor: "orange"}}>
                                                        <th scope="col">Id</th>                                                            
                                                        <th scope="col">Fecha de Vencimiento</th> 
                                                        <th scope="col">Factura</th> 
                                                        <th scope="col">Valor</th>                            
                                                        <th scope="col">Notas</th> 
                                                        <th scope="col">Pagos</th>      
                                                        <th scope="col"></th>      
                                                        <th scope="col">Saldo</th>                                
                                                        <th scope="col">Operaciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    item.cxcList && item.cxcList?.map(item1 =>                                 
                                                        <tr key={item1.id}>
                                                            <td>{item1.id}</td>
                                                            <td>{item1.fechaVencimiento}</td>   
                                                            <td>{item1.numFactura}</td>                                        
                                                            <td>{Utils.currencyFormat(item1.valor)}</td>
                                                            <td>{item1.notas}</td>                                            
                                                            <td>{Utils.currencyFormat(item1.pagos)}</td>                                            
                                                            <td>
                                                                <ul style={{ marginTop: "30px" }}>
                                                                    {
                                                                    item1.cxcPagos.map(item2 => 
                                                                        <li key={item2.cxcPagoId}>{item2.fechaExpedicion} 
                                                                        | {item2.monto} | {item2.notasPagos} | {item2.referencia01}</li>    
                                                                    )
                                                                    }
                                                                </ul>    
                                                            </td>
                                                            <td>{Utils.currencyFormat(item1.saldo)}</td>
                                                            <td className='d-md-flex justify-content-md-end'>  
                                                                {
                                                                    (item1.saldo) > 0 ? <h6>Tiene saldo</h6> : <h6>Pagado</h6>
                                                                }                                                                                        
                                                                {/* {
                                                                    (item.saldo) > 0 ? 
                                                                    <button type="button" className="btn btn-warning" onClick={e => onClickbtnPay(e, item.id)}>Realizar pago</button> :
                                                                    null
                                                                }                                             */}
                                                            </td>
                                                        </tr>                                    
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>                                
                            )
                        }
                        </div>
                    </div>
                </div>
                             
            </div>    
            {stateSeenPopup ? <div> <PopUpAddPay                                 
                                    cxcid={ stateCxcIdSelected }                            
                                    togglePop={ togglePop } 
                                /> 
                            </div> : null}       
        </>
    );

};

export default PaymentGatewayList;