import React, { useState, useEffect }from 'react';
import { useNavigate } from 'react-router-dom';

import { ssoService } from '../../Services/ssoService';

import { useAuth }from '../../Context/auth';

const UserList = () => {
    const auth = useAuth(); 

    const navigate =  useNavigate(); 

    const [stateUser, setStateUser] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            ssoService.GetByIdCompany(auth.user.companyId).then(res => {     
                //console.log(res.data);               
                setStateUser(res.data.filter(x => x.role != 2));                
            });      
          }, 1000);
    }, []);    

    const onClickbtnAdd = (e) => {
        e.preventDefault();

        try {
          navigate('/UserAddEdit/Add', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    const onClickbtnEdit = (e, id) => {
        e.preventDefault();

        try {
          navigate(`/UserAddEdit/Edit/${id}`, { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    const onClickbtnDelete = (e, id) => {
        // ssoService.delete(auth?.user.token, id).then(() => {
        //     setStateClient(stateClient => stateClient.filter(x => x.id !== id));
        // });    
    }

    return (
        <>                     
            <div className='container'>                                
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Listado de usuarios</h2>
                    </div>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-end'>
                        <button type="button" class="btn btn-primary" onClick={e => onClickbtnAdd(e)}>Agregar usuario</button>
                    </div>
                </div>                
                              
                <div className='row'>
                    <table className="table">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Usuario</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Rol</th>                            
                            <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                stateUser && stateUser?.map(item => 
                                    <tr key={item.id}>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.user}</td>
                                        <td>{item.userName}</td>
                                        <td>{item.roleName}</td>                            
                                        <td className='d-md-flex justify-content-md-end'>
                                            <button style={{backgroundColor:'#FD9913', color:'white'}} type="button" className="btn btn-warning" onClick={e => onClickbtnEdit(e, item.id)}>Editar</button> | 
                                            {/* <button type="button" className="btn btn-danger" onClick={e => onClickbtnDelete(e, item.id)}>Eliminar</button>                                 */}
                                        </td>
                                    </tr>                                
                                )
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    );
}

export default UserList;