import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { businessService } from '../../Services/businessService';
import { businessTypeService } from '../../Services/businessTypeService';
import { paymentgatewayService } from '../../Services/paymentGatewayService';
import { paymentFrecuencyService } from '../../Services/paymentFrecuencyService';

import { useAuth } from '../../Context/auth';
import swal from 'sweetalert';

const CommerceAddEdit = () => {
    const auth = useAuth(); 

    const { id } = useParams();
    const isAddMode = !id;    
    const navigate =  useNavigate();   

    const validKeyForPayment = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
      ];

    const [stateBusiness, setStateBusiness] = useState({
        id: 0,
        businessName: '',
        tradeName: '',
        rfc: '',
        businessTypeId: '',
        contact: '',
        phone: '',
        email: '',
        paymentGatewayId: '0',
        // percentDeposit: 0,
        // fracuencyPay: 0,        
        // planPaymentDuration:0,
        // ammountoCancel:0,
        companyId: auth.user.companyId,
        externalReferenceAntad: '',
        ssoId: auth.user.id
    });
    const [stateBusinessType, setStateBusinessType] = useState([]);
    const [statePaymentGateway, setStatePaymentGateway] = useState([]);
    const [statePaymentFrecuency, setStatePaymentFrecuency] = useState([]);

    const [stateEnableBtnSave, setStateEnableBtnSave] = useState('');
       
    useEffect(() => {
        paymentgatewayService.getAllByCompanyId(auth.user.token, auth.user.companyId).then( response => {                  
            setStatePaymentGateway(response.data);
            stateBusiness.paymentGatewayId = response.data[0].id
        });       

        businessTypeService.getAll(auth.user.token).then( response => {                  
            setStateBusinessType(response.data);
        });       
        
        paymentFrecuencyService.getAll(auth.user.token).then( response => {                  
            setStatePaymentFrecuency(response.data);
        });       

        if (!isAddMode) {
            businessService.getById(auth.user.token, id).then(response => {              
                setStateBusiness(response.data);
            });      
        }
    }, []);


    // const onKeyPressEvent = (event) => {
    //     const keyCode = event.keyCode || event.which;
    //     const keyValue = String.fromCharCode(keyCode);
    //     if (!(new RegExp("[0-9]").test(keyValue)))
    //       event.preventDefault();
    //     return;
    //   }


    const onChangehandleChange = (event) => {
        const {name, value} = event.target;
        setStateBusiness((prevState) => ({
            ...prevState,
            [name]: value
        }));        

        validateFormData();
    }

    const onBlurhandleblur = () => {
        const validateResult = validateFormData();
    }
    
    const onClickbtnSaveUpdate = () => {
        const validateResult = validateFormData();
        if(validateResult) {
            if (isAddMode) {           
                businessService.post(auth.user.token, stateBusiness);
            } else {
                businessService.put(auth.user.token, stateBusiness.id, stateBusiness);
            }
            // Redireccionar
            setTimeout(() => {
                navigate('/commerceList', { replace: true });    
            }, 1000);
        } else {
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Los datos esta mal!',
                footer: 'Favor de revisar los datos'
            });
        }
    }

    const onClickbtnCancel = (e) => {
        e.preventDefault();

        try {
          navigate('/commerceList', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    const validateFormData = () => {
        let validData = true;
        setStateEnableBtnSave('A');

        if (stateBusiness.businessName.length == 0 || stateBusiness.businessName == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        if (stateBusiness.tradeName.lenght == 0 || stateBusiness.tradeName == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        if (stateBusiness.rfc.lenght == 0 || stateBusiness.rfc == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        if (stateBusiness.businessTypeId == 0 || stateBusiness.businessTypeId == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        if (stateBusiness.contact.lenght == 0 || stateBusiness.contact == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        if (stateBusiness.phone.lenght == 0 || stateBusiness.phone == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        if (stateBusiness.email.lenght == 0 || stateBusiness.email == '') {
            validData = false;
            setStateEnableBtnSave('');
        }
        // if (stateBusiness.paymentGatewayId == 0 || stateBusiness.paymentGatewayId == '') {
        //     validData = false;
        //     setStateEnableBtnSave('');
        // }
        // if (stateBusiness.percentDeposit == 0 || stateBusiness.percentDeposit == '') {
        //     validData = false;
        //     setStateEnableBtnSave('');
        // }
        // if (stateBusiness.fracuencyPay == 0 || stateBusiness.fracuencyPay == '') {
        //     validData = false;
        //     setStateEnableBtnSave('');
        // }
        // if (stateBusiness.planPaymentDuration == 0 || stateBusiness.planPaymentDuration == '') {
        //     validData = false;
        //     setStateEnableBtnSave('');
        // }
        // if (stateBusiness.ammountoCancel == 0 || stateBusiness.ammountoCancel == '') {
        //     validData = false;
        //     setStateEnableBtnSave('');
        // }
        if (stateBusiness.externalReferenceAntad.length == 0 || stateBusiness.externalReferenceAntad == '') {
            validData = false;
            setStateEnableBtnSave('');
        }

        return validData;
    }


    return (
        <>                    
            <div className="container">                
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>{ isAddMode  ? 'Crear sucursal' : 'Editar sucursal' }</h2>
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>                    
                <div className="row">
                    <div className="col">
                        <label htmlFor="businessName" className="form-label">Razón social</label>
                        <input type="text" className={`form-control ${stateBusiness.businessName.length > 0 ? 'is-valid':'is-invalid'}`} 
                            placeholder="" required="" 
                            id="businessName"
                            name="businessName"                                        
                            onChange={onChangehandleChange}
                            onBlur={onBlurhandleblur}
                            value={stateBusiness.businessName}
                            autoComplete="off"                                  
                        />
                        <div className="invalid-feedback">Es requerida la razón social!</div>                                  
                    </div>
                    <div className="col">
                        <label htmlFor="tradeName" className="form-label">Nombre comercial</label>
                        <input type="text" className={`form-control ${stateBusiness.tradeName.length > 0 ? 'is-valid':'is-invalid'}`} 
                            placeholder="" required="" 
                            id="tradeName"
                            name="tradeName"                                        
                            onChange={onChangehandleChange}
                            value={stateBusiness.tradeName}
                            autoComplete="off"           
                        />
                        <div className="invalid-feedback">Es requerido el nombre comercial!</div>                                
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div className="col">
                        <label htmlFor="rfc" className="form-label">Rfc</label>
                        <input type="text" className={`form-control ${stateBusiness.rfc.length > 0 ? 'is-valid':'is-invalid'}`} 
                            placeholder="" required="" 
                            id="rfc"
                            name="rfc"                                        
                            onChange={onChangehandleChange}
                            value={stateBusiness.rfc}
                            autoComplete="off"                                                            
                        />
                        <div className="invalid-feedback">Es requerido el rfc!</div>                 
                    </div>
                    <div className="col">
                        <label htmlFor="businessTypeId" className="form-label">Giro de negocio</label>
                        <select
                            className="form-control"
                            id="businessTypeId"
                            name="businessTypeId"                                            
                            onChange={onChangehandleChange}                                                                              
                            value={stateBusiness.businessTypeId}
                        > 
                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                        {
                            stateBusinessType.map((item, index) => <option key={index} value={item.id}>{item.businessTypeName}</option>)
                        }
                        </select>
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div className="col">
                        <label htmlFor="contact" className="form-label">Contacto</label>
                        <input type="text" className={`form-control ${stateBusiness.contact.length > 0 ? 'is-valid':'is-invalid'}`} 
                            placeholder=""  required="" 
                            id="contact"
                            name="contact"                                        
                            onChange={onChangehandleChange}
                            value={stateBusiness.contact}
                            autoComplete="off"                                                            
                        />
                        <div className="invalid-feedback">Es requerido el contacto!</div>                               
                    </div>
                    <div className="col">
                        <label htmlFor="phone" className="form-label">Teléfono</label>
                        <input type="text" className={`form-control ${stateBusiness.phone.length > 0 ? 'is-valid':'is-invalid'}`} 
                            placeholder="" required="" 
                            id="phone"
                            name="phone"                                        
                            onChange={onChangehandleChange}
                            value={stateBusiness.phone}
                            autoComplete="off"                                                            
                        />
                        <div className="invalid-feedback">Es requerido el telefono!</div>                                
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div className="col">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="email" className={`form-control ${stateBusiness.email.length > 0 ? 'is-valid':'is-invalid'}`} 
                            placeholder="" required="" 
                            id="email"
                            name="email"                                        
                            onChange={onChangehandleChange}
                            value={stateBusiness.email}
                            autoComplete="off"                                                            
                        />
                        <div className="invalid-feedback">Es requerido el email!</div>                                
                    </div>
                    <div className='col'>
                        <label htmlFor="externalReferenceAntad" className="form-label">Referencia Externa Antad</label>
                        <input type="text" className={`form-control ${stateBusiness.externalReferenceAntad.length > 0 ? 'is-valid':'is-invalid'}`}
                            placeholder="" required="" 
                            id="externalReferenceAntad"
                            name="externalReferenceAntad"                                        
                            onChange={onChangehandleChange}
                            value={stateBusiness.externalReferenceAntad}
                            autoComplete="off"                                                            
                        />
                        <div className="invalid-feedback">Es requerida la referencía externa de antad!</div>     
                    </div>
                    {/* <div className="col">
                        <label htmlFor="paymentGatewayId" className="form-label">Pasarela de pago</label>
                        <select
                            className="form-control"
                            id="paymentGatewayId"
                            name="paymentGatewayId"                                            
                            onChange={onChangehandleChange}                                                                              
                            value={stateBusiness.paymentGatewayId}
                            disabled
                        > 
                        <option value="0">Selecciona una opcion</option>                 
                        {
                            statePaymentGateway.map((item, index) => <option key={index} value={item.id}>{item.description}</option>)
                        }
                        </select>
                    </div>     */}
                    
                </div>           
                <br></br>
                {/* <div className="row">
                    <div className="col">
                        <label htmlFor="percentDeposit" className="form-label">% para el apartado</label>
                        <input type="text" className={`form-control ${stateBusiness.percentDeposit.length > 0 ? 'is-valid':'is-invalid'}`} 
                            placeholder="" required="" 
                            id="percentDeposit"
                            name="percentDeposit"        
                            maxLength= {2}                               
                            onChange={onChangehandleChange}                            
                            value={stateBusiness.percentDeposit}      
                            onKeyDown={(e) => {
                                if (!validKeyForPayment.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              autoComplete="off"                                                    
                        />                                
                        <div className="invalid-feedback">Es requerido el % para el partado!</div>
                    </div>
                    <div className="col">
                        <label htmlFor="fracuencyPay" className="form-label">frecuencia de cobro (días)</label>
                        <select
                            className="form-control"
                            id="fracuencyPay"
                            name="fracuencyPay"                                            
                            onChange={onChangehandleChange}                                                                              
                            value={stateBusiness.fracuencyPay}                            
                        > 
                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                        {
                            statePaymentFrecuency.map((item, index) => <option key={index} value={item.id}>{item.description}</option>)
                        }
                        </select>                        
                    </div>
                </div>            */}
                {/* <br></br>
                <div className="row">
                    <div className="col">
                        <label htmlFor="planPaymentDuration" className="form-label">Duración Plan de pagos (Meses)</label>
                        <input type="number" className={`form-control ${stateBusiness.planPaymentDuration.length > 0 ? 'is-valid':'is-invalid'}`} 
                            placeholder="" required="" 
                            id="planPaymentDuration"
                            name="planPaymentDuration"       
                            maxLength={1}                                 
                            onChange={onChangehandleChange}
                            value={stateBusiness.planPaymentDuration}   
                            onKeyDown={(e) => {
                                if (!validKeyForPayment.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              autoComplete="off"                                                        
                        />                                
                        <div className="invalid-feedback">Es requerido la duración del plan!</div>
                    </div>
                    <div className="col">
                        <label htmlFor="ammountoCancel" className="form-label">Monto por cancelación ($)</label>
                        <div class="input-group mb-3">                        
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="text" className={`form-control ${stateBusiness.ammountoCancel.length > 0 ? 'is-valid':'is-invalid'}`}
                                aria-label="Monto por cancelación ($)"
                                id="ammountoCancel"
                                name="ammountoCancel"      
                                maxLength={3}                                   
                                onChange={onChangehandleChange}
                                value={stateBusiness.ammountoCancel}
                                onKeyDown={(e) => {
                                    if (!validKeyForPayment.includes(e.key)) {
                                    e.preventDefault();
                                    }
                                }}
                                autoComplete="off"                      
                            />
                            <div class="input-group-append"><span class="input-group-text">.00</span></div>
                            <div className="invalid-feedback">Es requerido el monto por cancelación!</div>
                        </div>
                    </div>                   
                </div>    */}
                <div className='row'>
                    {/* <div className='col'>
                        <label htmlFor="externalReferenceAntad" className="form-label">Referencia Externa Antad</label>
                        <input type="text" className={`form-control ${stateBusiness.externalReferenceAntad.length > 0 ? 'is-valid':'is-invalid'}`}
                            placeholder="" required="" 
                            id="externalReferenceAntad"
                            name="externalReferenceAntad"                                        
                            onChange={onChangehandleChange}
                            value={stateBusiness.externalReferenceAntad}
                            autoComplete="off"                                                            
                        />
                        <div className="invalid-feedback">Es requerida la referencía externa de antad!</div>     
                    </div> */}
                    <div className='col'></div>
                </div>
                <div className='row'>
                    <hr></hr>
                </div>
                <div className='row'>
                    <div className='col'>
                        <button style={{backgroundColor:'#FD9913', color:'white'}} type="button" 
                            className="w-100 btn btn-success"
                            onClick={e => onClickbtnSaveUpdate(e)}
                            disabled={!stateEnableBtnSave}
                            >{isAddMode ? ' Agregar comercio ' : ' Actualizar comercio '}</button>    
                    </div>
                    <div className='col'>
                        <button style={{backgroundColor:'#0D6EFD', color:'white'}} type="button" 
                            class="w-100 btn btn-secondary" onClick={e => onClickbtnCancel(e)}>Cancelar</button>                        
                    </div>                        
                </div>                    
            </div>            
        </>
    )
}

export default CommerceAddEdit;