import React, { useRef, useEffect, useState }from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from "react-to-print";
import ComponentToPrintB from './ComponentToPrintB';
import { useAuth }from '../../Context/auth';

import { Utils } from '../../Utils/utils';

import { salesService } from '../../Services/salesService';
import { businessService } from '../../Services/businessService';
import { cxcService } from '../../Services/Cxc/cxcService';

const PosPymeTicketB = () => {
    const auth = useAuth(); 
    const { id, soid, bussiid } = useParams();
    const componentRef = useRef();
    const navigate =  useNavigate(); 

    const [stateSaleData, setstateSaleData] = useState({
        companyName: '',
        saleDate: new Date,
        reference: 0,
        businessName: '',
        cashier: '',
        ammount: 0,
        commision:0,
        total: 0
    });

    useEffect(() => {

        GetInfo();

    }, []);
    
    const GetInfo = async () => {
        let responseGetRef = await cxcService.getByIdWithBalance(id); 
        
        if (responseGetRef.data !== null)
        {          
            const responseSale = await salesService.getById(auth.user.token, responseGetRef.data.ventaId);
            
            setstateSaleData({
                companyName: auth.user.companyName,
                saleDate: responseGetRef.data.fechaVencimiento,
                reference: responseGetRef.data.id,
                businessName: responseSale.data.description,
                cashier: auth.user.userName,
                ammount: responseGetRef.data.valor,
                commision:0,
                total: responseGetRef.data.valor
            });

        } else {
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Cxc incorrecto',
                footer: 'Favor revisar'
            });            
        }     
             
    }

    const onclickBackPayment = (e) => {
        e.preventDefault();

        if (soid == 'Ex') {
            navigate('/SelOperation/' + bussiid, { replace: true });
        }
        if (soid == 'Py') {
            navigate('/ServicePaymentB', { replace: true });
        }        
    }

    return (
        <>        
            <ReactToPrint
                trigger={() => <button type="button" className="btn btn-primary btn-md">Imprimir</button>}
                content={() => componentRef.current}
            />
            <button type="button" className="btn btn-primary btn-md" onClick={onclickBackPayment}>Regresar a Abonos</button>}
            <ComponentToPrintB ref={componentRef} stateSaleData={JSON.stringify(stateSaleData)} />
        </>
    )
};

export default PosPymeTicketB;