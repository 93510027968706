import React from 'react';
import { useNavigate } from 'react-router-dom';

const ReportsList = () => {
    const navigate =  useNavigate(); 

    const onClickbtnprodQR = (e) => {
        e.preventDefault();

        try {
          navigate(`/productBycommerceQR`, { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    const onClickbtnCxcPaymentFilter = (e) => {
        e.preventDefault();
        try {
            navigate(`/cxcPaymentByDate`, { replace: true });
          } catch(e) {
              console.log('error: ' + e);
          }    
  
    }

    const onClickbtnSalesWithPayments = (e) => {
        e.preventDefault();
        try {
            navigate(`/salesWithPayments`, { replace: true });
          } catch(e) {
              console.log('error: ' + e);
          }    

    }

    return (
        <>
            <br></br>
            <br></br>            
            <div className='container'>                                
                <div className='card-group'>

                        <div className='card' style={{ 'text-align': 'center'}}>
                            <img src="/assets/img//img/hand-pay.png" className="card-img-top" alt="..."  
                                style={{ 'height': '100px',  'width': '100px', 'margin-left': '40%', 'margin-top':'10%'}}/>
                            <div class="card-body">
                                <h5 className="card-title">Plantilla productos x comercio</h5>
                                <p className="card-text">Listado de productos x comercio con QR.</p>                                
                                <button type="button" className="btn btn-primary" onClick={e => onClickbtnprodQR(e)}>Visualizar</button>
                            </div>
                        </div>    

                        <div className='card' style={{ 'text-align': 'center'}}>
                            <img src="/assets/img//img/hand-pay.png" className="card-img-top" alt="..."  
                                style={{ 'height': '100px',  'width': '100px', 'margin-left': '40%', 'margin-top':'10%'}}/>
                            <div class="card-body">
                                <h5 className="card-title">Cuentas x cobrar</h5>
                                <p className="card-text">Listado de pagos a cxc.</p>                                
                                <button type="button" className="btn btn-primary" onClick={e => onClickbtnCxcPaymentFilter(e)}>Visualizar</button>
                            </div>
                        </div> 

                        <div className='card' style={{ 'text-align': 'center'}}>
                            <img src="/assets/img//img/hand-pay.png" class="card-img-top" alt="..." 
                                style={{ 'height': '100px',  'width': '100px', 'margin-left': '40%', 'margin-top':'10%'}}/>
                            <div class="card-body">
                                <h5 class="card-title">Reporte de Saldos</h5>
                                <p class="card-text">Listado de ventas con saldos.</p>
                                <button type="button" className="btn btn-primary" onClick={e => onClickbtnSalesWithPayments(e)}>Visualizar</button>
                            </div>
                        </div>             
                    
                </div>                        
            </div>
        </>
    );

};

export default ReportsList;