import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { fintechService } from '../../Services/fintechService';
import { paymentgatewayService } from '../../Services/paymentGatewayService';

import { useAuth } from '../../Context/auth'

const PaymenGatewayAddEdit = () => {
    const auth = useAuth(); 

    const { id } = useParams();
    const isAddMode = !id;    
    const navigate =  useNavigate(); 
   
    const [stateFintechs, setStateFintech] = useState([]);
    const [statePaymentGateway, setStatePaymentGateway] = useState({
        id: 0,
        description: '',
        fintechId: 0,
        appKeyPublic: '',
        appKeyPrivate: '',
        companyId: auth.user.companyId,
        SSOId: auth.user.id
    });    

    useEffect(() => {
        fintechService.getAll(auth.user.token).then( fintechs => {                  
            setStateFintech(fintechs.data);
        });       

        if (!isAddMode) {
            paymentgatewayService.getById(auth.user.token, id).then(response => {                        
                setStatePaymentGateway(response.data);
            });      
        }
    }, []);

    const onChangehandleChange = (event) => {
        const {name, value} = event.target;
        setStatePaymentGateway((prevState) => ({
            ...prevState,
            [name]: value
        }));        
    }

    const onClickbtnSaveUpdate = () => {
        if (isAddMode) {           
            paymentgatewayService.post(auth.user.token, statePaymentGateway);
        } else {
            paymentgatewayService.put(auth.user.token, statePaymentGateway.id, statePaymentGateway);
        }     

        // Redireccionar
        navigate('/paymentGatewaylist', { replace: true });
    }

    const onClickbtnCancel = (e) => {
        e.preventDefault();

        try {
          navigate('/paymentGatewaylist', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    return (
        <>                
            <div className="container">                
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Editar pasarela de pago</h2>
                    </div>
                </div>                
                <div className='row'>
                    <hr></hr>
                </div>                   
                <div className="row">
                    <div className="col">
                        <label htmlFor="description" className="form-label">Descripcion</label>
                        <input className="form-control" placeholder="" required="" 
                            id="description"
                            name="description"                                            
                            type="text"
                            onChange={onChangehandleChange}
                            value={statePaymentGateway.description}
                        />
                        
                    </div>
                    <div className="col">
                        <label htmlFor="fintechId" className="form-label">Fintech</label>
                        <select
                            className="form-control"
                            id="fintechId"
                            name="fintechId"                                            
                            onChange={onChangehandleChange}                                                                              
                            value={statePaymentGateway.fintechId}
                        > 
                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                        {
                            stateFintechs.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)
                        }
                        </select>
                    </div>
                </div>                
                <div className='row'>                
                    <div className="col">
                        <label htmlFor="appKeyPublic" className="form-label">AppKey publica</label>
                        <input  className="form-control"  placeholder="" required="" 
                            id="appKeyPublic"
                            name="appKeyPublic"                                            
                            type="text"
                            onChange={onChangehandleChange}
                            value={statePaymentGateway.appKeyPublic}
                        />                        
                    </div>
                    <div className="col">
                        <label htmlFor="appKeyPrivate" className="form-label">AppKey privada</label>
                        <input  className="form-control"  placeholder="" required="" 
                            id="appKeyPrivate"
                            name="appKeyPrivate"                                            
                            type="text"
                            onChange={onChangehandleChange}                                
                            value={statePaymentGateway.appKeyPrivate}                                        
                        />                        
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <div className='row'>
                    <div className='col'>
                        <button style={{backgroundColor:'#FD9913', color:'white'}} 
                            type="button" className="w-100 btn btn-success" onClick={onClickbtnSaveUpdate}>
                            {isAddMode ? ' Agregar pasarela de pago ' : ' Actualizar pasarela de pago '}
                        </button>    
                    </div>
                    <div className='col'>
                        <button style={{backgroundColor:'#0D6EFD', color:'white'}} 
                            type="button" className="w-100 btn btn-secondary" onClick={onClickbtnCancel}>
                            Cancelar
                        </button>                        
                    </div>    
                </div>              
                
            </div>
        </>
    );

}

export default PaymenGatewayAddEdit;