import { useState} from 'react';
import { GlobalVariableContext } from './globalVariablesContext';

const GlobalVariableProvider = ({children}) =>{
    let globalVariable = {
        refChangePass: ''
    };

    return (
        <GlobalVariableContext.Provider value = {globalVariable}>
          {children}
        </GlobalVariableContext.Provider>
      );
}

export {
    GlobalVariableProvider    
};


// import { createContext , useState} from 'react';

// const GlobalVariableContext = createContext(defaultValue);

// const GlobalVariableProvider = ({children}) =>{

//     const [globalVariable, setGlobalVariable] = useState({
//         refChangePass: ''
//     });

//     const setValuesToGlobalVariable = (pValues) => {
//         setGlobalVariable(pValues);
//     }

//     const globalData = {globalVariable, setValuesToGlobalVariable};

//     return (
//         <GlobalVariableContext.Provider value = {globalData}>
//           {children}
//         </GlobalVariableContext.Provider>
//       );
// }

// const useGlobalVariableContext = () => {
//     const useGlobalVariable = React.useContext(GlobalVariableContext);
//     return useGlobalVariable;
//   }

// export {
//     GlobalVariableProvider,
//     useGlobalVariableContext  
// };