import React from 'react';

import { Utils } from '../../Utils/utils'

import QRCode from "react-qr-code";

var CryptoJS = require("crypto-js");

export const ProductByCommerceQRUIToPrint = React.forwardRef((props, ref) => {
    
    return (
        <>
        <div ref={ref}>
        {            
            props.stateBusinessProduct && props.stateBusinessProduct?.map(item =>
                <div className='card' style={{width: "18rem", display: 'inline-block', marginLeft:'25px', marginTop:'15px'}} key={item.id}>                        
                    <QRCode value={item.encryptText} />   
                    <div className='card-body'>
                        <h5 className='card-title'>{item.name}</h5>
                        <p className='card-text'>Sku: {item.sku}</p>
                        <p className='card-text'>Precio: {Utils.currencyFormat(item.salePrice)}</p>                                    
                    </div>
                </div>                        
            )            
        }
        </div>
        </>
    );
});