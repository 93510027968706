import React from 'react';
import { NumericFormat } from 'react-number-format';

const CommerceProductAddEditUI = (props) => {          
   

    return (
        <>
            <br></br>
            <br></br>            
            
            <div className="container">                
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>{ props.isAddMode  ? 'Agregar producto de la sucursal' : 'Editar producto de la sucursal' }</h2>
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>
                <div className='row'>                        
                    <div className="col">
                        <label htmlFor="name" className="form-label">Nombre de producto</label>
                        <input type="text" className={`form-control ${props.stateProductBusiness.name.length > 0 ? 'is-valid':'is-invalid'}`}
                            placeholder="" 
                            id="name"
                            name="name"                                                                                
                            value={props.stateProductBusiness.name}
                            onChange={props.handleChange}
                            autoComplete="off"
                        />         
                        <div className="invalid-feedback">Es requerido el nombre producto!</div>                            
                    </div>
                    <div className="col">
                        <label htmlFor="sku" className="form-label">SKU</label>
                        <input type="text" className={`form-control ${props.stateProductBusiness.sku.length > 0 ? 'is-valid':'is-invalid'}`} 
                            placeholder="" required="" 
                            id="sku"
                            name="sku"                                                                                
                            value={props.stateProductBusiness.sku}            
                            onChange={props.handleChange}
                            autoComplete="off"
                        />                                    
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div className="col">
                        <label htmlFor="salePrice" className="form-label">Precio</label>
                        <div class="input-group mb-3">                        
                            {/* <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="text" className={`form-control ${props.stateProductBusiness.salePrice.length > 0 ? 'is-valid':'is-invalid'}`}
                                aria-label="Precio de venta ($)"
                                id="salePrice"
                                name="salePrice"      
                                maxLength={6}                                   
                                onChange={props.handleChange}
                                value={props.stateProductBusiness.salePrice}
                                onBlur={props.onBlursalePrice}
                                onKeyDown={(e) => {
                                    if (!props.validKeyForPayment.includes(e.key)) {
                                    e.preventDefault();
                                    }
                                }}
                                autoComplete="off"                      
                            />
                            <div class="input-group-append"><span class="input-group-text">.00</span></div>
                            <div className="invalid-feedback">Es requerido el precio!</div>*/}
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <NumericFormat className={`form-control ${props.stateProductBusiness.salePrice.length > 0 ? 'is-valid':'is-invalid'}`}
                                id="salePrice"
                                name="salePrice" 
                                value={props.stateProductBusiness.salePrice} 
                                allowLeadingZeros thousandSeparator="," 
                                onChange={props.handleChange}
                            />
                            <div className="invalid-feedback">Es requerido el precio!</div>
                        </div>                                  
                    </div>
                    <div className="col">                                            
                        <label htmlFor="businessId" className="form-label">Sucursal</label>
                        <select
                            className={`form-control ${props.stateProductBusiness.businessId > 0 ? 'is-valid':'is-invalid'}`}
                            id="businessId"
                            name="businessId"                                                                   
                            value={props.stateProductBusiness.businessId}
                            onChange={props.handleChange}                            
                        > 
                        <option value="" seleted="true">Selecciona una opcion</option>                 
                        {
                            props.stateBusiness.map((item, index) => <option key={index} value={item.id}>{item.businessName}</option>)
                        }
                        </select>   
                        <div className="invalid-feedback">Es requerido seleccionar una sucursal!</div>                                   
                    </div>                            
                </div>
                <br></br>
                <div className="row">
                    <div className="col">
                        <label htmlFor="description" className="form-label">Descripciòn</label>
                        <input type="text"  className={`form-control ${props.stateProductBusiness.description.length > 0 ? 'is-valid':'is-invalid'}`}
                            placeholder="" required="" 
                            id="description"
                            name="description"                                                                                
                            value={props.stateProductBusiness.description}   
                            onChange={props.handleChange}
                            autoComplete="off"
                        />                                    
                    </div>                                              
                </div>
                <br></br>
                <div className="row">
                    <div className="col">
                        <label htmlFor="percentDeposit" className="form-label">% para el apartado</label>
                        <input type="text" className={`form-control ${props.stateProductBusiness.percentDeposit.length > 0 ? 'is-valid':'is-invalid'}`} 
                            placeholder="" required="" 
                            id="percentDeposit"
                            name="percentDeposit"        
                            maxLength= {6}                               
                            onChange={props.handleChange}                            
                            value={props.stateProductBusiness.percentDeposit}      
                            onKeyDown={(e) => {
                                if (!props.validKeyForPaymentForPerDep.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            autoComplete="off"                                                    
                        />                                
                        <div className="invalid-feedback">Es requerido el % para el partado!</div>
                    </div>
                    <div className="col">
                        <label htmlFor="fracuencyPay" className="form-label">frecuencia de cobro (días)</label>
                        <select
                            className={`form-control ${props.stateProductBusiness.fracuencyPay > 0 ? 'is-valid':'is-invalid'}`}
                            id="fracuencyPay"
                            name="fracuencyPay"                                            
                            onChange={props.handleChange}                                                                              
                            value={props.stateProductBusiness.fracuencyPay}                            
                        > 
                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                        {
                            props.statePaymentFrecuency.map((item, index) => <option key={index} value={item.id}>{item.description}</option>)
                        }
                        </select>     
                        <div className="invalid-feedback">Es requerido seleccionar una frecuencía de pago!</div>                    
                    </div>
                </div>      
                <br></br>
                <div className="row">
                    <div className="col">
                        <label htmlFor="planPaymentDuration" className="form-label">Duración Plan de pagos (Meses)</label>
                        <input type="number" className={`form-control ${props.stateProductBusiness.planPaymentDuration.length > 0 ? 'is-valid':'is-invalid'}`} 
                            placeholder="" required="" 
                            id="planPaymentDuration"
                            name="planPaymentDuration"       
                            maxLength={1}                                 
                            onChange={props.handleChange}
                            value={props.stateProductBusiness.planPaymentDuration}   
                            onKeyDown={(e) => {
                                if (!props.validKeyForPayment.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              autoComplete="off"                                                        
                        />                                
                        <div className="invalid-feedback">Es requerido la duración del plan!</div>
                    </div>
                    <div className="col">
                        <label htmlFor="ammountoCancel" className="form-label">Monto por cancelación ($)</label>
                        <div class="input-group mb-3">                        
                            {/* <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="text" className={`form-control ${props.stateProductBusiness.ammountoCancel.length > 0 ? 'is-valid':'is-invalid'}`}
                                aria-label="Monto por cancelación ($)"
                                id="ammountoCancel"
                                name="ammountoCancel"      
                                maxLength={6}                                   
                                onChange={props.handleChange}
                                value={props.stateProductBusiness.ammountoCancel}
                                onKeyDown={(e) => {
                                    if (!props.validKeyForPayment.includes(e.key)) {
                                    e.preventDefault();
                                    }
                                }}
                                autoComplete="off"                      
                            />
                            <div class="input-group-append"><span class="input-group-text">.00</span></div>
                            <div className="invalid-feedback">Es requerido el monto por cancelación!</div>                            */}
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <NumericFormat className={`form-control ${props.stateProductBusiness.ammountoCancel.length > 0 ? 'is-valid':'is-invalid'}`}
                                id="ammountoCancel"
                                name="ammountoCancel" 
                                value={props.stateProductBusiness.ammountoCancel} 
                                allowLeadingZeros thousandSeparator="," 
                                onChange={props.handleChange}
                            />
                            <div className="invalid-feedback">Es requerido el precio!</div>
                        </div>
                    </div>                   
                </div>   
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>                
                <div className='row'>
                    <div className='col'>
                        <button style={{backgroundColor:'#FD9913', color:'white'}} type="button" className="w-100 btn btn-success" onClick={e => props.onClickbtnSaveUpdate(e)}>{props.isAddMode ? ' Agregar producto de sucursal ' : ' Actualizar producto de sucursal '}</button>    
                    </div>
                    <div className='col'>
                        <button style={{backgroundColor:'#0D6EFD', color:'white'}} type="button" className="w-100 btn btn-secondary" onClick={e => props.onClickbtnCancel(e)}>Cancelar</button>                        
                    </div>                        
                </div>     
            </div>    
          
        </>
    )

}

export default CommerceProductAddEditUI