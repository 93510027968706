import axios from 'axios';
import Config from "../../config.json";

const API_BASE_URL = `${Config.REACT_APP_APIREST_CXC_URL}/v1/PullApartReference`;

const getById = async (id) => {    
    
    const response = await axios.get(`${API_BASE_URL}/${id}`);
    const data = await response.data;       

    return data;
}


const post = async (values) => {    
    const response = await axios.post( API_BASE_URL, values );
    const data = await response.data;   

    return data;
}

export const pullApartRefService = {    
    getById,
    post
}