import axios from 'axios';
import Config from "../config.json";

///const API_BASE_URL = `${process.env.REACT_APP_APIREST_BASE_URL}/v1/Business`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/Rol`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
};    

const GetAllForViewFromCommerce = async (auth_token) => { 
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetAllForViewFromCommerceAsync`, CONFIG_REQUEST);
    const data = await response.data;

    return data;
}

export const rolService = {
    GetAllForViewFromCommerce
}