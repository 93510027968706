import { forwardRef } from 'react';

import { Utils } from '../../Utils/utils';

const ComponentToPrintA = forwardRef(( props, ref ) => {

  const res = JSON.parse(props.stateSaleData);

  return (
    <div ref={ref}>
      <h4>{res.companyName}</h4>
      <br></br>
      Pago de servicios
      <br></br>
      Axify
      <br></br>    
      Fecha: {res.saleDate}
      <br></br>
      Referencía: {res.reference}
      <br></br>
      Sucursal: {res.businessName}
      <br></br>
      Cajero: {res.cashier}
      <br></br>
      Importe: {res.ammount}
      <br></br>
      Comisión: {res.commision}
      <br></br>
      Total: {res.total}
      <br></br>
      <h5>APARTADO APROBADO</h5>
    </div>
  )
});

export default ComponentToPrintA;