import axios from 'axios';
import Config from "../../config.json";

const API_BASE_URL = `${Config.REACT_APP_APIREST_FINTECHS_URL}/v1/Conekta`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
};    

const postCreateCustomer = async (auth_token, values) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.post(`${API_BASE_URL}/Customer/CreateConektaCustomer`, values, CONFIG_REQUEST );
    const data = await response.data;   

    return data;    
}

const getConektaCustomerById = async (auth_token, id) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/Customer/GetCustomerByIdAsync/${id}`, CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}

const postGetConektaToken = async (auth_token, values) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.post(`${API_BASE_URL}/GetConektaToken`, values, CONFIG_REQUEST );
    const data = await response.data;   

    return data;
}

const postCreatePaymentMethod = async (auth_token, customerid, values) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.post(`${API_BASE_URL}/Customer/CreateConektaPaymentMethod/${customerid}`, values, CONFIG_REQUEST );
    const data = await response.data;   

    return data;    
}

const deletePaymentMethod = async (auth_token, customerid, paymentmethodid) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.delete(`${API_BASE_URL}/Customer/DeleteConektaPaymentMethod/${customerid}/${paymentmethodid}`, CONFIG_REQUEST );
    const data = await response.data;   

    return data;    
}

export const conektaService = {  
    postCreateCustomer,
    getConektaCustomerById,
    postGetConektaToken,
    postCreatePaymentMethod,
    deletePaymentMethod
}