import React, { useState } from 'react';
import moment from 'moment';

import CxcPaymentByDateUI from './CxcPaymentByDateUI'

import { cxcPaymentService } from '../../Services/Cxc/cxcPaymentService';

import { useAuth }from '../../Context/auth'

const CxcPaymentByDate = () => {
    const auth = useAuth();     
    const current = new Date();

    const [stateCxcPaymentArray, setStateCxcPaymentArray] = useState([]);

    const [stateDateBegin, setStateDateBegin] = useState(moment(current).format("YYYY-MM-DD"));
    const [stateDateEnd, setStateDateEnd] = useState(moment(current).format("YYYY-MM-DD"));

    const onChangeDOMEvent_inputDateBegin = (item) => {
        setStateDateBegin(item.target.value);
    }

    const onChangeDOMEvent_inputDateEnd = (item) => {
        setStateDateEnd(item.target.value);
    }

    const onClickDOMEvent_btnFiltrar = (e) => {
        e.preventDefault();
        cxcPaymentService.getByDates(auth.user.companyId, stateDateBegin, stateDateEnd).then(res => {            
            setStateCxcPaymentArray(res.data);            
        });    
    }


    return (
        <CxcPaymentByDateUI 
            stateDateBegin = {stateDateBegin}
            setStateDateBegin = {setStateDateBegin}
            stateDateEnd = {stateDateEnd}
            setStateDateEnd  ={setStateDateEnd}
            onChangeDOMEvent_inputDateBegin= {onChangeDOMEvent_inputDateBegin}
            onChangeDOMEvent_inputDateEnd= {onChangeDOMEvent_inputDateEnd}
            onClickDOMEvent_btnFiltrar= {onClickDOMEvent_btnFiltrar}
            stateCxcPaymentArray= {stateCxcPaymentArray}
        > 
        </CxcPaymentByDateUI>
    );
}

export default CxcPaymentByDate;