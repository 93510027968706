import axios from 'axios';
import Config from "../../config.json";

const API_BASE_URL = `${Config.REACT_APP_APIREST_BOROMIR_URL}/User`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json'        
    }
};    

const postAuthenticate = async (values) => {   

    const response = await axios.post(  `${API_BASE_URL}/authenticate`, values, CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}

export const boromirUserService = {
    postAuthenticate
}