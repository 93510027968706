import React, { useRef } from 'react';
import ReactToPrint from "react-to-print";
//import QRCode from "react-qr-code";

import {ProductByCommerceQRUIToPrint} from './ProductByCommerceQRUIToPrint';

const CommerceProductListUI = (props) => {    

    let componentRef = useRef();

    return (
        <>
            <br></br>
            <br></br>            
            <div className='container'>                                
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Listado de productos con  QR</h2>
                    </div>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-end'>
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>
                <div className='row'>
                    <div className='col-7'>
                        <label htmlFor="businessId" className="form-label">Listado de sucursales</label>
                        <select
                            className="form-control"
                            id="businessId"
                            name="businessId"                                            
                            onChange={ props.onChangeDOMEvent_selectbusiness.bind(this) }  
                        > 
                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                        {
                            props.stateBusiness.map((item, index) => <option key={index} value={item.id}>{item.businessName}</option>)
                        }
                        </select>
                    </div>
                    <div className='col-4'>
                        <label htmlFor="sku" className="form-label">SKU</label>
                        <input className="form-control" placeholder="" required="" 
                            id="sku"
                            name="sku"
                            type="text"
                            onChange={props.onChangeDOMEvent_inputSku}
                            value={props.stateSku}            
                        />  
                    </div>
                    <div className='col-1' style={{textAlign:'right'}}>
                        <button type="button" className="btn btn-primary" style={{ marginTop: "30px" }}
                            onClick={e => props.onClickDOMEvent_btnFiltrar(e)}>Filtrar
                        </button> 
                    </div>
                </div>
                <br></br>
                <div className='row'>     
                    {/* button to trigger printing of target component */}
                    <ReactToPrint
                        trigger={() => <button type='button' className="btn btn-primary">Imprimir!</button>}
                        content={() => componentRef.current}
                    />
                    <br></br>
                    <br></br>
                    {/* component to be printed */}
                    <ProductByCommerceQRUIToPrint 
                        ref={componentRef} 
                        stateBusinessProduct={props.stateBusinessProduct}                        
                    />                    
                </div>
            </div>
        </>
    );

};

export default CommerceProductListUI;