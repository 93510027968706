import React from 'react'
import { BrowserRouter, Routes , Route, useLocation  } from 'react-router-dom';

import { AuthProvider, AuthRoute } from '../Context/auth'

import { GlobalVariableProvider} from '../Context/globalVariablesProvider';

import EmptyLayout from '../Containers/EmptyLayout';
import MainLayout from '../Containers/MainLayout';

import Login from '../Pages/Login/Login';
import ResetYourPassword from '../Pages/Login/ResetYourPassword';
import ChangeYourPassword from '../Pages/Login/ChangeYourPassword';
import CommerceRegister from '../Pages/Commerce/commerceRegister';
import NotFound from '../Pages/NotFound/index';

import Home from '../Pages/Home/Home';
import BusinessDashboard from '../Pages/Dashboard/BusinessDashboard';

import PaymentGatewayList from '../Pages/paymentgateway/paymentGatewayList';
import PaymenGatewayAddEdit from '../Pages/paymentgateway/paymentgatewayAddEdit';

import CommerceList from '../Pages/Commerce/commerceList';
import CommerceAddEdit from '../Pages/Commerce/commerceAddEdit';

import CommerceProductList from '../Pages/CommerceProduct/CommerceProductList';
import CommerceProductAddEdit from '../Pages/CommerceProduct/CommerceProductAddEdit';

import ClientList from '../Pages/Client/ClientList';
import ClientAddEdit from '../Pages/Client/ClientAddEdit';

import SalesList from '../Pages/sales/SalesList';
import SalesAddEdit from '../Pages/sales/SalesAddEdit';

import CxcList from '../Pages/Cxc/CxcList';

import ReportsList from '../Pages/reports/reportsList';
import ProductByCommerceQR from '../Pages/reports/ProductByCommerceQR';
import CxcPaymentByDate from '../Pages/reports/CxcPaymentByDate';
import SaleswithPayments from '../Pages/reports/SaleswithPayments';

import PaymentPlanView from '../Pages/reports/PaymentPlanView';

import UserList from '../Pages/user/UserList';
import UserAddEdit from '../Pages/user/UserAddEdit'

import ContactUs from '../Pages/support/ContactUs';

import PosPymeIndex from '../Pages/PosPyme/PosPymeIndex';
import ServicePaymentA from '../Pages/PosPyme/ServicesPaymentA';
import ServicePaymentB from '../Pages/PosPyme/ServicesPaymentB';

import PosPymeTicketA  from '../Pages/PosPyme/PosPymeTicketA';
import PosPymeTicketB  from '../Pages/PosPyme/PosPymeTicketB';
import CompanyEdit from '../Pages/Company/CompanyEdit';
import CompanyUpdateSubscription from '../Pages/Company/CompanyUpdateSubscription';
import PullApartSelStore from '../Pages/PullApart/PullApartSelStore';
import PullApartIndex from '../Pages/PullApart/PullApartIndex';

import SelOperation from '../Pages/PullApart/SelOperation';

export const AppRouter = () => {    

    return (        
        <BrowserRouter>
            <AuthProvider>        
                <GlobalVariableProvider>
                    <Routes>           
                        <Route exact path="/" element={
                            <EmptyLayout>
                                <Login />
                            </EmptyLayout>
                        }/>

                        <Route exact path="*" element={
                            <EmptyLayout>
                                <NotFound />
                            </EmptyLayout>
                        }/>                    

                        <Route exact path="/login" element={
                            <EmptyLayout>
                                <Login />
                            </EmptyLayout>
                        }/>

                        <Route exact path="/commerceRegister" element={
                            <EmptyLayout>
                                <CommerceRegister />
                            </EmptyLayout>
                        }/>
                        
                        <Route exact path="/resetYourPassword" element={
                            <EmptyLayout>
                                <ResetYourPassword />
                            </EmptyLayout>
                        }/>

                        <Route  exact path='changeYourPassword' element={
                            <EmptyLayout>
                                <ChangeYourPassword />
                            </EmptyLayout>
                        }/>                        

                        <Route exact path='/home' element={                            
                            <AuthRoute>
                                <MainLayout>
                                    <Home />
                                </MainLayout>
                            </AuthRoute>
                        }/>

                        
                        <Route exact path='/businessDashboard' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <BusinessDashboard />
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/paymentGatewayList' element={
                            <AuthRoute>
                                <MainLayout>
                                    <PaymentGatewayList /> 
                                </MainLayout>
                            </AuthRoute>
                        } />                                        
                        <Route exact path='/paymentGatewayList' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <PaymentGatewayList /> 
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/paymenGatewayAddEdit/Add' element={
                            <AuthRoute>
                                <MainLayout>
                                <PaymenGatewayAddEdit /> 
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/paymenGatewayAddEdit/Edit/:id' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <PaymenGatewayAddEdit />  
                                </MainLayout>
                            </AuthRoute>
                        } />

                        
                        <Route exact path='/paymentGatewayClientList' element={ 
                            <AuthRoute>
                                <PaymentGatewayList /> 
                            </AuthRoute>
                        } />
                        <Route exact path='/paymenGatewayClientAddEdit/Add' element={
                            <AuthRoute>
                                <MainLayout>
                                    <PaymenGatewayAddEdit /> 
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/paymenGatewayClientAddEdit/Edit/:id' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <PaymenGatewayAddEdit /> 
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/commerceList' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <CommerceList />
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/commerceAddEdit/Add' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <CommerceAddEdit /> 
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/commerceAddEdit/Edit/:id' element={
                            <AuthRoute>
                                <MainLayout>
                                    <CommerceAddEdit />
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/commerceProductList' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <CommerceProductList />
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/commerceProduct/Add' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <CommerceProductAddEdit /> 
                            </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/commerceProduct/Edit/:id' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <CommerceProductAddEdit />
                                </MainLayout>
                            </AuthRoute>
                        }/>

                        
                        <Route exact path='/clientList' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <ClientList /> 
                                </MainLayout>
                            </AuthRoute>
                        }/>
                        <Route exact path='/clientAddEdit/Add' element={                             
                            <AuthRoute>
                                <MainLayout>
                                    <ClientAddEdit /> 
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/clientAddEdit/Edit/:id' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <ClientAddEdit />
                                </MainLayout> 
                            </AuthRoute>
                        }/>

                        
                        <Route exact path='/salesList' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <SalesList /> 
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/salesAddEdit/Add' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <SalesAddEdit />
                                </MainLayout> 
                            </AuthRoute>
                        } />
                        <Route exact path='/salesAddEdit/Edit/:id' element={
                            <AuthRoute>
                                <MainLayout>
                                    <SalesAddEdit />
                                </MainLayout> 
                            </AuthRoute>
                        } />

                        
                        <Route exact path='/cxcList' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <CxcList />
                                </MainLayout>
                            </AuthRoute> 
                        } />

                        
                        <Route exact path='/reportList' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <ReportsList />
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/productBycommerceQR' element={
                            <AuthRoute>
                                <MainLayout>
                                    <ProductByCommerceQR />
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/cxcPaymentByDate' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <CxcPaymentByDate /> 
                                </MainLayout>
                            </AuthRoute>
                        } />
                        <Route exact path='/salesWithPayments' element={
                            <AuthRoute>
                                <MainLayout>
                                    <SaleswithPayments /> 
                                </MainLayout>
                            </AuthRoute>
                        } /> 

                        <Route exact path='/paymentPlanView/:id' element={
                            <AuthRoute>
                                <MainLayout>
                                    <PaymentPlanView />
                                </MainLayout>
                            </AuthRoute>
                        }/>

                        <Route exact path='/contactus' element={
                            <AuthRoute>
                                <MainLayout>
                                    <ContactUs />
                                </MainLayout>
                            </AuthRoute>
                        }/>

                        <Route exact path='/UserList' element={
                            <AuthRoute>
                                <MainLayout>
                                    <UserList /> 
                                </MainLayout>
                            </AuthRoute>
                        } /> 
                        <Route exact path='/UserAddEdit/Add' element={ 
                            <AuthRoute>
                                <MainLayout>
                                    <UserAddEdit />
                                </MainLayout> 
                            </AuthRoute>
                        } />
                        <Route exact path='/UserAddEdit/Edit/:id' element={
                            <AuthRoute>
                                <MainLayout>
                                    <UserAddEdit />
                                </MainLayout> 
                            </AuthRoute>
                        } />

                        <Route exact path='/PosPymeIndex' element={
                            <AuthRoute>
                                <MainLayout>
                                    <PosPymeIndex />
                                </MainLayout> 
                            </AuthRoute>
                        } />
                        <Route exact path='/ServicePaymentA' element={
                            <AuthRoute>
                                <MainLayout>
                                    <ServicePaymentA />
                                </MainLayout> 
                            </AuthRoute>
                        } />
                        <Route exact path='/ServicePaymentB/:id/:soid' element={
                            <AuthRoute>
                                <MainLayout>
                                    <ServicePaymentB />
                                </MainLayout> 
                            </AuthRoute>
                        } />

                        <Route exact path='/PosPymeTicketA/:id' element={
                            <AuthRoute>
                                <MainLayout>
                                    <PosPymeTicketA />
                                </MainLayout> 
                            </AuthRoute>
                        } />
                        <Route exact path='/PosPymeTicketB/:id/:soid/:bussiid' element={
                            <AuthRoute>
                                <MainLayout>
                                    <PosPymeTicketB />
                                </MainLayout> 
                            </AuthRoute>
                        } />
                        <Route exact path='/PullApartSelStore' element={
                            <AuthRoute>
                                <MainLayout>
                                    <PullApartSelStore />
                                </MainLayout>                                
                            </AuthRoute>
                        } />
                        <Route exact path='/PullApartIndex/:id' element={
                            <AuthRoute>
                                <MainLayout>
                                    <PullApartIndex />
                                </MainLayout>                                
                            </AuthRoute>
                        } />
                        <Route exact path='/SelOperation/:id' element={
                            <AuthRoute>
                                <MainLayout>
                                    <SelOperation />
                                </MainLayout>                                
                            </AuthRoute>
                        } />
                        <Route exact path='/CompanyEdit' element={
                            <AuthRoute>
                                <MainLayout>
                                    <CompanyEdit />
                                </MainLayout> 
                            </AuthRoute>
                        } />

                        <Route exact path='/CompanyUpdateSubscription/:id' element={
                            <AuthRoute>
                                <MainLayout>
                                    <CompanyUpdateSubscription />
                                </MainLayout>
                            </AuthRoute>
                        }/>
                    </Routes>
                </GlobalVariableProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}


