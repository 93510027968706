import React, { useState } from 'react';
import { useNavigate , NavLink} from 'react-router-dom';
import swal from 'sweetalert';
import { ssoService } from '../../Services/ssoService';
import { companyService } from '../../Services/companyService';

import CommerceregisterUI from './commerceRegisterUI';

const CommerceRegister = () => {  
  const navigate = useNavigate();

  const [stateRegisterData, setstateRegisterData] = useState({        
    userName: '',
    businessId: 0,
    businessName: '',
    phone: '',
    user: '',
    password: '',
    password2: ''
  });  

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [stateEmailValid, setstateEmailValid] = useState(false);
  const [stateisEmailFromatValid, setstateisEmailFromatValid] = useState(false);
  const [stateCompanyValid, setstateCompanyValid] = useState(false);  
  const [statePhone, setstatePhone] = useState('');

  const onclickbuttonCrear = () => {
    if (stateEmailValid) {
      const isvalid = Validatedata();
      if(isvalid){
        if(stateRegisterData.password == stateRegisterData.password2) {         
          ssoService.post(stateRegisterData).then( response => {
            setLoading(false);    
            swal("Felicidades!", "Se creo la cuenta correctamente!", "success")
            .then((value) => {
                navigate('/login', { replace: true });
            });         
          }).catch(error => {
            setLoading(false);
            console.log(error.response);          
          });
        } else {
          swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Los campos de password no coinciden!',
            footer: 'Favor de revisar los datos'
          });
        }
      } else {
        swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Los datos esta mal!',
          footer: 'Favor de revisar los datos'
        });
      }
    } else {
      //alert("El Email ya existe!");
      swal({
        icon: 'error',
        title: 'Oops...',
        text: 'El Email ya existe!',
        footer: 'Favor de revisar los datos'
      });
    }
  }

  const onBlur_inputEmail = (e) => { 
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(e.target.value)) {
      setstateisEmailFromatValid(false);
    } else {
      setstateisEmailFromatValid(true); 
    }

    ssoService.getbyEmail(e.target.value).then( response => {
      if (response.data != null){
        if(response.data.id > 0){          
          //alert("El Email ya existe!");
          swal({
            icon: 'error',
            title: 'Oops...',
            text: 'El Email ya existe!',
            footer: 'Favor de revisar los datos'
          });          
          setstateEmailValid(false);
        } else {          
          setstateEmailValid(true);
        }  
      } else {
        setstateEmailValid(true);
      }
    });
  }

  const onBlur_inputCompanyName = (e) => { 
    companyService.getByName(e.target.value).then( response => {
      if (response.data != null){
        if(response.data.id > 0){          
          swal({
            icon: 'error',
            title: 'Oops...',
            text: 'La empresa ya existe!',
            footer: 'Favor de crear una nueva'
          });
          setstateRegisterData(
          {        
            userName: '',
            businessId: response.data.id,
            businessName: '',
            phone: '',
            user: '',
            password: '1',
            password2: '2'
          });
          setstateCompanyValid(false);
          setstateEmailValid(false);
        } else {          
          setstateRegisterData({...stateRegisterData, businessId: 0});
          setstateCompanyValid(true);
        }  
      } else {
        setstateRegisterData({...stateRegisterData, businessId: 0});        
        setstateCompanyValid(true);
      }
    });
  }

  const handleChange = (event) => {
    const {name, value} = event.target;    
    setstateRegisterData((prevState) => ({ ...prevState, [name]: value }));
  }

  const Validatedata  = () => {
    let validate = true;

    validate = validate && (stateRegisterData.phone.length > 0 ? true : false);
    validate = validate && (stateRegisterData.businessName.length > 0 ? true : false);
    validate = validate && (stateRegisterData.userName.length > 0 ? true : false);
    validate = validate && (stateRegisterData.user.length > 0 ? true : false);
    validate = validate && (stateRegisterData.password.length > 0 ? true : false);

    return validate;
  }

  return (
    <>              
        <br></br><br></br><br></br>
        <br></br><br></br><br></br> 
        <CommerceregisterUI
          stateRegisterData={stateRegisterData}      
          setstateRegisterData={setstateRegisterData}    
          handleChange={handleChange}
          onclickbuttonCrear={onclickbuttonCrear}          
          onBlur_inputEmail={onBlur_inputEmail}         
          onBlur_inputCompanyName={onBlur_inputCompanyName}
          stateisEmailFromatValid = {stateisEmailFromatValid}     
          stateCompanyValid={stateCompanyValid}         
        />
      
    </>
  )
}

export default CommerceRegister;