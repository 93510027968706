import React, { cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';

const PosPymeIndex = () => {
    const navigate =  useNavigate(); 

    const onClickBtnGoservicesA = (e) => {
        e.preventDefault();

        navigate('/ServicePaymentA', { replace: true });
    }

    const onClickBtnGoservicesB = (e) => {
        e.preventDefault();

        navigate('/ServicePaymentB/0/Py', { replace: true });
    }

    return (
        <>
            <div className='row'>
                <div className='col'>Sucursal:</div>
                <div className='col'>Version: 1.0</div>                
            </div>
            <br></br>
            <div className='row'>
                <div className='col'>
                    <div class="alert alert-primary" role="alert">
                        Que deseas hacer?
                    </div>
                </div>
            </div>
            <br></br>
            <div className='row'>
                <div className='col' >                    
                    <div className="card" style={{width: '18rem'}}>
                        <div style={{textAlign: 'center'}}>
                            <i className="mdi mdi-sale mdi-48px"></i>                        
                        </div>                        
                        <div className="card-body" style={{textAlign: 'center'}}>
                            <h5 className="card-title">Apartados</h5>
                            <p className="card-text">Realizar cobro de apartados por medio de # referencías</p>
                            <a href="#" className="btn btn-primary" onClick={onClickBtnGoservicesA}>Ir....</a>
                        </div>
                    </div>                    
                </div>
                <div className='col'>                    
                    <div className="card" style={{width: '18rem'}}>
                        <div style={{textAlign: 'center'}}>
                            <i className="mdi mdi-sale mdi-48px"></i>                        
                        </div>                        
                        <div className="card-body" style={{textAlign: 'center'}}>
                            <h5 className="card-title">Abonos</h5>
                            <p className="card-text">Realizar cobro de abonos por medio de # referencías</p>
                            <a href="#" className="btn btn-primary" onClick={onClickBtnGoservicesB}>Ir....</a>
                        </div>
                    </div>                    
                </div>
                {/* <div className='col'>
                    <div className="card" style={{width: '18rem'}}>
                        <div style={{textAlign: 'center'}}>
                            <i className="mdi mdi-briefcase-check mdi-48px"></i>                        
                        </div>                        
                        <div className="card-body" style={{textAlign: 'center'}}>
                            <h5 className="card-title">Confirmar transacciones</h5>
                            <p className="card-text">: aquí se podrá confirmar en línea, el estatus de la última 
                                transacción realizada a determinada referencia de pago</p>
                            <a href="#" className="btn btn-primary">Ir....</a>
                        </div>
                    </div>          
                </div> */}
                {/* <div className='col'>
                    <div className="card" style={{width: '18rem'}}>
                        <div style={{textAlign: 'center'}}>
                            <i className="mdi mdi-library-books mdi-48px"></i>                        
                        </div>                        
                        <div className="card-body" style={{textAlign: 'center'}}>
                            <h5 className="card-title">Consulta de movimientos</h5>
                            <p className="card-text">aquí se podrán consultar las ventas realizadas por la 
                                    sucursal, por día. EN CONSTRUCCION</p>
                            <a href="#" className="btn btn-primary">Ir....</a>
                        </div>
                    </div> 
                </div>                 */}
            </div>
        </>
    );
}

export default PosPymeIndex;