import axios from 'axios';
import Config from '../../config.json'

const API_BASE_URL = `${Config.REACT_APP_APIREST_FINTECHS_URL}/v1/Users`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json'       
    }
};    

const post = async (values) => {       

    const response = await axios.post(`${API_BASE_URL}/Authenticate`, values, CONFIG_REQUEST );
    const data = await response.data;   

    return data;
}

export const userConektaService = {    
    post
}