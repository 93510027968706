import React from 'react';

const CommerceProductListUI = (props) => {    

    return (
        <>                    
            <div className='container'>                                
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Listado de productos por sucursal</h2>
                    </div>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-end'>
                        <button type="button" class="btn btn-primary" onClick={e => props.onClickbtnAdd(e)}>Agregar producto</button>
                    </div>
                </div>                
                <div className='row'>
                    <hr></hr>
                </div>                
                <div className='row'>
                    <div className='col-10'>
                        <label htmlFor="businessId" className="form-label">Listado de sucursales</label>
                        <select
                            className="form-control"
                            id="businessId"
                            name="businessId"                                            
                            onChange={ props.onChangeDOMEvent_selectbusiness.bind(this) }  
                        > 
                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                        {
                            props.stateBusiness.map((item, index) => <option key={index} value={item.id}>{item.businessName}</option>)
                        }
                        </select>
                    </div>
                    <div className='col-2' style={{textAlign:'right'}}>
                        <button type="button" className="btn btn-primary" style={{ marginTop: "30px" }}
                            onClick={e => props.onClickDOMEvent_btnFiltrar(e)}>Filtrar
                        </button> 
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">SKU</th>                            
                            <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.stateBusinessProduct && props.stateBusinessProduct?.map(item => 
                                    <tr key={item.id}>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.name}</td>
                                        <td>{item.sku}</td>                            
                                        <td className='d-md-flex justify-content-md-end'>
                                            <button style={{backgroundColor:'#FD9913', color:'white'}} type="button" className="btn btn-warning" onClick={e => props.onClickbtnEdit(e, item.id)}>Editar</button> | 
                                            <button type="button" className="btn btn-danger" onClick={e => props.onClickbtnDelete(e, item.id)}>Eliminar</button>                                
                                        </td>
                                    </tr>                                
                                )
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    );

};

export default CommerceProductListUI;