import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { companyService } from '../../Services/companyService';
import { planSubscriptionService } from '../../Services/planSubscriptionService';
import { companyPlanSubscriptionService } from '../../Services/companyPlanSubscriptionService';
import { userConektaService } from '../../Services/Fintechs/usersConektaService';
import { conektaService } from '../../Services/Fintechs/conektaService';

import { useAuth } from '../../Context/auth';

import swal from 'sweetalert';

const CompanyUpdateSubscription = () => {
    const navigate =  useNavigate();  
    const auth = useAuth();     
    
    const { id } = useParams();

    const [stateIframeVisible, setStateIframeVisible] = useState(false);
    const [stateIfVisibleBtnUpdatePlan, setstateIfVisibleBtnUpdatePlan] = useState(false);

    const [statePlanSubscription, setstatePlanSubscription] = useState({});

    const [stateConektaCustomer, setstateConektaCustomer] = useState('');

    const [hiddenSpinner1, sethiddenSpinner1] = useState(false);

    useEffect(() => {
        planSubscriptionService.getById(auth?.user.token, id).then((response) =>{            
            setstatePlanSubscription(response.data);
        });

        InitProcessCompany();
    }, []);

    const InitProcessCompany = async () => {
        sethiddenSpinner1(true);

        const companyResponse = await companyService.getById(auth?.user.token, auth?.user.companyId);
        setstateConektaCustomer(companyResponse.data.conekta_Customer);
        const tokenfintech = await GetTokenFromApFintechs();        
        
        var conektacustomer = await conektaService.getConektaCustomerById(tokenfintech, companyResponse.data.conekta_Customer);
        
        if (conektacustomer.data.payment_sources == null){
            CleanLocalstorageVariables();

            const requestGetConektaToken = {
                "checkout": {
                    "returns_control_on": "Token"
                }
            }
            const responseGetConektaToken = await conektaService.postGetConektaToken(tokenfintech, requestGetConektaToken);
   
            SetLocalstorageVariables(responseGetConektaToken);
                              
            setStateIframeVisible(true);
            setstateIfVisibleBtnUpdatePlan(false);
        } else {
            setStateIframeVisible(false);
            setstateIfVisibleBtnUpdatePlan(true);
        }
        sethiddenSpinner1(false);
    }

    const onClickBtnforCreatePaymentMethod = async (e) => {
        e.preventDefault();

        setStateIframeVisible(true);

        const resTokenidConfirm = localStorage.getItem("TokenIdConfirm");

        if (resTokenidConfirm != ''){
            const tokenfintech = await GetTokenFromApFintechs();

            const requestPaymentMethod = {
                "type": "card",
                "token_id": resTokenidConfirm
            };
            const responsePaymentMethod = await conektaService.postCreatePaymentMethod(tokenfintech, stateConektaCustomer, requestPaymentMethod)
            
            UpdatePlanProcess();
            navigate('/home', { replace: true });
        }
        setStateIframeVisible(false);
    }

    const onClickBtnforUpdatePlan =(e) =>{
        e.preventDefault();

        UpdatePlanProcess();
        navigate('/home', { replace: true });
    }

    const onClickBtnforCancelProcess = (e) => {
        e.preventDefault();

        navigate('/home', { replace: true });
    }

    const GetTokenFromApFintechs = async () => {
        const userForAuthenticate = {
            "Id": 0,
            "User": "soporte@axify.mx",
            "Password": "Axify2023",
            "Token": ""
        };
        const responseConektaUser = await userConektaService.post(userForAuthenticate);
        
        //console.log(responseConektaUser);
        
        const tokenconekta = responseConektaUser.data.token;

        return tokenconekta;
    }

    const CleanLocalstorageVariables =  () => {
        localStorage.setItem("TokenId", '');  
        localStorage.setItem("CheckOutId", "");    
        localStorage.setItem("TokenIdConfirm", '');  
        localStorage.setItem("Publickey", "");       
    }

    const SetLocalstorageVariables =  (information) => {
        localStorage.setItem("TokenId", information.data.id);  
        localStorage.setItem("CheckOutId", information.data.checkout.id);
        localStorage.setItem("Publickey", "key_URsCz51wMDH4hTCziATbUhg");
    }

    const UpdatePlanProcess = async () =>{
        const resultCompanyplanA = await companyPlanSubscriptionService.put(auth?.user.token, auth?.user.companyId);
        const requestCompanyPlansubs = {
            id: 0,
            companyId: auth?.user.companyId,
            planSubscriptionId: +id,  
            numDay: 0,
            creationDateTime: new Date ,
            status: true
        };
        const resultCompanyplanB = await companyPlanSubscriptionService.post(auth?.user.token, requestCompanyPlansubs);
    }
    
    return (
        <>
         <div className="container">
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Subscripción seleccionada</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Nombre</th>
                                    <th>Importe mensual</th>
                                    <th># Pos maximos</th>
                                    <th>% de transacción</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{statePlanSubscription.id}</td>
                                    <td>{statePlanSubscription.name}</td>
                                    <td>{statePlanSubscription.ammount}</td>
                                    <td>{statePlanSubscription.numMaxPos}</td>
                                    <td>{statePlanSubscription.percTransaction}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <div className='col'>
                        { hiddenSpinner1 &&
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden"></span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <div className='col'>
                        <div className='row' style={{ display: (stateIframeVisible ? 'block' : 'none')}}>
                            <div className='col'></div>
                            <div className='col'>
                                <iframe title="static_html" src="/CheckoutConektaV1.html" style={{border:"none", width:"800px", height:"600px"}}></iframe> 
                            </div>                     
                            <div className='col'></div>               
                        </div>                       
                    </div>
                </div>
                <br></br>                
                <div className="row">                   
                    <div className='col'>
                        <button id="btnFroCreatePaymentMethod" onClick={e => onClickBtnforCreatePaymentMethod(e)} style={{ display: "none" }}>Process</button>                                    
                    </div>             
                </div>    
                <div className="row">                   
                    <div className='col'>
                        <button id="btnForUpdatePlan" className="btn btn-primary" onClick={e => onClickBtnforUpdatePlan(e)} style={{ display: (stateIfVisibleBtnUpdatePlan ? 'block' : 'none')}}>Actualizar plan</button>                                    
                        
                    </div>  
                    <div className='col'>
                    <button id="btnForCancelProcess" className="btn btn-primary" onClick={e => onClickBtnforCancelProcess(e)} style={{ display: (stateIfVisibleBtnUpdatePlan ? 'block' : 'none')}}>Cancelar accion</button>                                    
                    </div>           
                </div>                 
                <br></br>
                         
            </div>   
        </>
    );
}

export default CompanyUpdateSubscription;