import axios from 'axios';
import Config from "../config.json";

///const API_BASE_URL = `${process.env.REACT_APP_APIREST_BASE_URL}/v1/BusinessDashboard`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/BusinessDashboard`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
};    

const GetBusinessDashboardData01 = async (auth_token, begin , end, companyId) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;    

    const response = await axios.get(`${API_BASE_URL}/GetBusinessDashboardData01/${begin}/${end}/${companyId}`, CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}

const GetBusinessDashboardData02 = async (auth_token, begin , end, companyId) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetBusinessDashboardData02/${begin}/${end}/${companyId}`, CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}

const GetBusinessDashboardData03 = async (auth_token, begin , end, companyId) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetBusinessDashboardData03/${begin}/${end}/${companyId}`, CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}

const GetBusinessDashboardData04 = async (auth_token, begin , end, companyId) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetBusinessDashboardData04/${begin}/${end}/${companyId}`, CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}

export const businessDashboardService = {
    GetBusinessDashboardData01,
    GetBusinessDashboardData02,
    GetBusinessDashboardData03,
    GetBusinessDashboardData04
}