import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ssoService } from '../../Services/ssoService';
import { rolService } from '../../Services/rolService';
import { businessService } from '../../Services/businessService';

import { useAuth } from '../../Context/auth';

import swal from 'sweetalert';

const UserAddEdit = () => {
    const auth = useAuth(); 

    const { id } = useParams();
    const isAddMode = !id;    
    const navigate =  useNavigate();   

    const [stateDisabledInputEmail, setstateDisabledInputEmail] = useState(false);
    const [stateRole, setStateRol] = useState([]);
    const [stateUser, setStateUser] = useState({
        id : 0,
		user:'',
		userName:'',
		businessId: +auth.user.companyId,
        businessName: auth.user.companyName,
		phone:'',		
		password:'',
		role: 0,
        commerceList: [],
        active: false
    });    
    const [stateBussinessByCompanyA, setStateBussinesByCompanyA] = useState([]);
    const [stateBussinessByCompanyB, setStateBussinesByCompanyB] = useState([]);   

    const [ firstCheckbox, setFirstCheckbox ] = useState(false); // true - first check box defaults to checked.

    const [stateEmailValid, setstateEmailValid] = useState(false);
    const [stateisEmailFormatValid, setstateisEmailFormatValid] = useState(false);
    
    useEffect(() => {

        if(isAddMode) {
            setstateDisabledInputEmail(false);
        } else{
            setstateDisabledInputEmail(true);
        }

        rolService.GetAllForViewFromCommerce(auth.user.token).then(response => {                       
            setStateRol(response.data);                
        });  
       
        loadDataAtBegin();

    }, []);

    const loadDataAtBegin = async  () => {   

        var res01 = await businessService.getAllByCompanyId(auth.user.token, auth.user.companyId);
        var res01Data = await res01.data;        

        setStateBussinesByCompanyA(res01Data);

        if (!isAddMode) {         
            var res02 = await ssoService.GetById(id);  
            var res02Data = await res02.data;       
            
            setStateUser(res02Data);

            setFirstCheckbox(res02Data.active);
            
            var newarrayforB = [];
            res02Data.commerceList.map(item => {
                const resfindA = res01Data.find((element) => element.id === item);
                if(resfindA != undefined){
                    newarrayforB.push(resfindA);
                }

                var fidx = res01Data.findIndex(a => a.id === item);
                if(fidx >= 0){
                    res01Data.splice(fidx, 1);
                }                
            });

            setStateBussinesByCompanyB(newarrayforB);
            setStateBussinesByCompanyA(res01Data);
        }         
    }

    const onChangehandleChange = (event) => {
        const {name, value} = event.target;
        setStateUser((prevState) => ({
            ...prevState,
            [name]: value
        }));        
    }

    const onClickbtnSaveUpdate = () => {
        stateUser.commerceList = [];
        if (isAddMode) {                       
            stateBussinessByCompanyB.map(item => {
                stateUser.commerceList.push(item.id);
            });
            stateUser.active = firstCheckbox;
            ssoService.post(stateUser);
        } else {            
            stateBussinessByCompanyB.map(item => {
                stateUser.commerceList.push(item.id);
            });
            stateUser.active = firstCheckbox;

            //console.log(JSON.stringify(stateUser));
            ssoService.putForCommerceUser( id, stateUser);
        }     

        // Redireccionar
        setTimeout(() => {
            navigate('/UserList', { replace: true });
        }, 1000);        
    }

    const onClickbtnCancel = (e) => {
        e.preventDefault();

        try {
          navigate('/UserList', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    const onClickstateBussinessByCompanyA = (event, id)=> {
        event.preventDefault();

        const arrA = [...stateBussinessByCompanyA.filter(item => item.id !== id)];      
        const arrBB = stateBussinessByCompanyA.find(item => item.id == id);           
        const arrB = [...stateBussinessByCompanyB, arrBB];

        setStateBussinesByCompanyA(arrA);     
        setStateBussinesByCompanyB(arrB);   
    }  

    const onClickstateBussinessByCompanyB = (event, id)=> {
        event.preventDefault();

        const arrB = [...stateBussinessByCompanyB.filter(item => item.id !== id)];        
        const arrAA = stateBussinessByCompanyB.find(item => item.id == id);
        const arrA = [...stateBussinessByCompanyA, arrAA];

        setStateBussinesByCompanyB(arrB);
        setStateBussinesByCompanyA(arrA);
    }

    const onBlur_inputEmail = async (e) => { 
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(e.target.value)) {
            setstateisEmailFormatValid(false);
        } else {
            setstateisEmailFormatValid(true); 
        }
        
        const responseSSoServiceGetserByemail = await ssoService.getbyEmail(e.target.value);
        
        if (responseSSoServiceGetserByemail.data != null){
            if(responseSSoServiceGetserByemail.data.id > 0){  
                swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'El Email ya existe!',
                    footer: 'Favor de revisar los datos'
                  })        
                setStateUser((prevState) => ({
                    ...prevState,
                    ['user']: ''
                }));                
                setstateEmailValid(false);
            } else {          
                setstateEmailValid(true);
            }  
        } else {
            setstateEmailValid(true);
        }       
    }
    
   

    return (
        <>
            <div className="container">
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>{ isAddMode  ? 'Crear Usuario' : 'Editar Usuario' }</h2>
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>                                                        
                <div className="row">
                    <div className="col">
                        <label htmlFor="user" className="form-label">Email</label>
                        <input className="form-control" placeholder="" required="" disabled={stateDisabledInputEmail}
                            id="user"
                            name="user"
                            type="text"
                            onChange={onChangehandleChange}
                            onBlur={onBlur_inputEmail}
                            value={stateUser.user}     
                            autoComplete="off"       
                        />                                  
                    </div>
                    <div className="col">
                        {
                            isAddMode ? 
                                <div>
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input className="form-control" placeholder="" required="" 
                                        id="password"
                                        name="password"
                                        type="password"
                                        onChange={onChangehandleChange}
                                        value={stateUser.password}  
                                        autoComplete="off"            
                                    />   
                                </div>                               
                            : null
                        }                                                                                                                    
                    </div>                    
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="userName" className="form-label">Nombre</label>
                        <input className="form-control" placeholder="" required="" 
                            id="userName"
                            name="userName"
                            type="text"
                            onChange={onChangehandleChange}
                            value={stateUser.userName}
                            autoComplete="off"              
                        />                        
                    </div>
                    <div className="col">
                        <label htmlFor="phone" className="form-label">Telefono</label>
                        <input className="form-control" placeholder="" required="" 
                            id="phone"
                            name="phone"
                            type="text"
                            onChange={onChangehandleChange}
                            value={stateUser.phone}
                            autoComplete="off"              
                        />                                    
                    </div>                   
                </div>
                <div className="row">                    
                    <div className="col">
                        <label htmlFor="role" className="form-label">Tipo de usuario</label>
                        <select
                            className="form-control"
                            id="role"
                            name="role"                                            
                            value={stateUser.role}
                            onChange={onChangehandleChange}
                        > 
                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                        {
                            stateRole?.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)
                        }
                        </select>                     
                    </div>
                    <div className='col'>             
                        <div class="form-check" style={{marginTop: '35px'}}>
                            <input class="form-check-input" type="checkbox"                                                                
                                checked={firstCheckbox} 
                                onChange={() => setFirstCheckbox(!firstCheckbox)}/>
                            <label class="form-check-label" htmlFor="active">Activo</label>
                        </div>                       
                    </div>
                </div>
                <br></br>   
                <div className='row'>
                    <div className='col'>
                        Inventario de sucursales
                        <div className="list-group">
                            {
                                stateBussinessByCompanyA  && stateBussinessByCompanyA?.map(item => 
                                    <a href="#" className="list-group-item list-group-item-action" 
                                        key={item.id} onClick={event => onClickstateBussinessByCompanyA(event, item.id)}>{item.businessName}</a>                                
                                )
                            }                            
                        </div>
                    </div>                           
                    <div className='col'>
                        Inventario de sucursales con permiso
                        <div className="list-group">
                            {
                                stateBussinessByCompanyB  && stateBussinessByCompanyB?.map(item => 
                                    <a href="#" className="list-group-item list-group-item-action" 
                                        key={item.id} onClick={event => onClickstateBussinessByCompanyB(event, item.id)}>{item.businessName}</a>                                
                                )
                            }                            
                        </div>
                    </div>            
                </div>
                <br></br>              
                <div className='row'>
                    <hr></hr>
                </div>
                <div className='row'>
                    <div className='col'>
                        <button style={{backgroundColor:'#FD9913', color:'white'}} type="submit" className="w-100 btn btn-success" onClick={e => onClickbtnSaveUpdate(e)}>{isAddMode ? ' Agregar usuario ' : ' Actualizar usuario '}</button>    
                    </div>
                    <div className='col'>
                        <button style={{backgroundColor:'#0D6EFD', color:'white'}} type="button" className="w-100 btn btn-secondary" onClick={e => onClickbtnCancel(e)}>Cancelar</button>                        
                    </div>                        
                </div>
            </div>                   
        </>
    )
}

export default UserAddEdit;