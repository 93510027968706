import React, {useState} from 'react';
import { NavLink, useNavigate} from 'react-router-dom';

import { ssoService } from '../../Services/ssoService';

import swal from 'sweetalert';
import base64 from 'react-native-base64';

//import './Login.css';

import {notificationService} from '../../Services/notificationsService';

const ResetYourPassword = () => {     
    const navigate =  useNavigate();  

    const [stateEmail, setstateEmail] = useState('');
    const [stateisEmailFromatValid, setstateisEmailFromatValid] = useState(false);

    const onChangehandleChangeEmail = (e) => {
        setstateEmail(e.target.value);
    }

    const onclickDOMEventBtnsubmit = async (e) => {
        e.preventDefault();

        if (stateisEmailFromatValid) {        
            const securityData = await ssoService.getbyEmail(stateEmail);        

            if(securityData.data == null) {
                swal("Error de correo!", "No existe el correo dentro de nuestra lista de usuarios", "error")
                .then((value) => {
                    navigate('/login', { replace: true });
                });  
            } else {
                const encText = base64.encode(stateEmail);         

                const ciphertext = `${window.location.origin}?refChangePass=${encText}`;       

                var request = {
                    email:   stateEmail,
                    link:  ciphertext,
                    dateHourCreate: new Date 
                }
                notificationService.postSendResetPasswordEmail(request);
                swal("Correo enviado!", "Se envio el correo con el link de cambio de password!", "success")
                .then((value) => {
                    navigate('/login', { replace: true });
                });
            }
        }
    }

    const onblurinputEmail = (e) =>{
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(e.target.value)) {
            setstateisEmailFromatValid(false);
            setstateEmail('');
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'el correo no tiene un formato correcto!',
                footer: 'Favor de revisar los datos'
              });
        } else {
            setstateisEmailFromatValid(true); 
        }
    }

    return (    
    <>
        <div className="container d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}}>
            <div className="d-flex flex-column justify-content-between">
                <div className="row justify-content-center mt-5">
                    <div className="col-md-12">
                        <div className="card card-default">
                            <div className="card-header">
                                <div className="app-brand w-100 d-flex justify-content-center border-bottom-0">
                                    <a className="w-auto pl-0" href="/">
                                        <img src="./assets/images/logoaxify.jpg" alt="Mono" />                                            
                                    </a>
                                </div>
                            </div>
                            <div className="card-body p-5">
                                <h4 className="text-dark mb-5">Introduce el email registrado </h4>
                                <h4 className="text-dark mb-5"></h4>                                
                                <div className="row">
                                    <div className="form-group col-md-12 mb-4">
                                        <input 
                                            type="email" 
                                            className="form-control input-lg" 
                                            id="email" 
                                            name="email"
                                            aria-describedby="nameHelp" 
                                            placeholder="Email"
                                            value={stateEmail}
                                            onChange={e => onChangehandleChangeEmail(e)}
                                            onBlur={e => onblurinputEmail(e)}
                                            />
                                    </div>                                                                       
                                </div>
                                <div className='row'>
                                    <div className="col">
                                        <button className="btn btn-primary btn-pill mb-4" 
                                            onClick={e => onclickDOMEventBtnsubmit(e)}>
                                                Enviar
                                        </button>                                        
                                    </div>
                                    <div className='col'>
                                        <NavLink
                                            className="btn btn-primary btn-pill mb-4" 
                                            aria-current="page"
                                            to="/login"
                                            >
                                                Regresar            
                                        </NavLink>                                     
                                    </div>   
                                </div>                                                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default ResetYourPassword;