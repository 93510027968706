import React, { useState, useEffect }from 'react';
import { useNavigate } from 'react-router-dom';

import { businessService } from '../../Services/businessService';

import { useAuth }from '../../Context/auth'


const CommerceList = () => {
    const auth = useAuth(); 
    
    const navigate =  useNavigate(); 

    const [stateBusiness, setStateBusiness] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            businessService.getAllByCompanyId( auth.user.token, auth.user.companyId).then(res => {                    
                setStateBusiness(res.data);
            });      
          }, 1000);
    }, []);    

    const onClickbtnAdd = (e) => {
        e.preventDefault();

        try {
          navigate('/commerceAddEdit/Add', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    const onClickbtnEdit = (e, id) => {
        e.preventDefault();

        try {
          navigate(`/commerceAddEdit/Edit/${id}`, { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    const onClickbtnDelete = (e, id) => {
        businessService.delete(auth.user.token, id).then(() => {
            setStateBusiness(stateBusiness => stateBusiness.filter(x => x.id !== id));
        });    
    }

    return (
        <>         
            <div className='container'>                                
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>Listado de sucursales</h2>
                    </div>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-end'>
                        <button type="button" class="btn btn-primary" onClick={e => onClickbtnAdd(e)}>Agregar sucursal</button>
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <hr></hr>
                </div>
                <br></br>
                <div className='row'>
                    <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Razon social</th>
                            <th scope="col">Nombre comercial</th>                            
                            <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                stateBusiness && stateBusiness?.map(item => 
                                    <tr key={item.id}>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.businessName}</td>
                                        <td>{item.tradeName}</td>                            
                                        <td className='d-md-flex justify-content-md-end'>
                                            <button style={{backgroundColor:'#FD9913', color:'white'}} type="button" className="btn btn-warning" onClick={e => onClickbtnEdit(e, item.id)}>Editar</button> | 
                                            <button type="button" className="btn btn-danger" onClick={e => onClickbtnDelete(e, item.id)}>Eliminar</button>                                
                                        </td>
                                    </tr>                                
                                )
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    );

};

export default CommerceList;