import React, { useState, useEffect, useContext } from 'react';
import { Navigate, useLocation, useNavigate} from 'react-router-dom';

import { tokenService } from '../../Services/tokenService';
import { useAuth } from '../../Context/auth';

import { GlobalVariableContext } from '../../Context/globalVariablesContext';

import LoginUI from './LoginUI';

const Login = () => {   
  const navigate =  useNavigate();  
  const auth = useAuth();
  const search = useLocation().search;

  const useGlobalVariableContextTmp = useContext(GlobalVariableContext);
  
  const [stateUserdata, setstateUserdata] = useState({
    email: '',
    password: ''    
  });  

  const [stateShowValidUser, setstateShowValidUser] = useState(false);

  const [hiddenSpinner1, sethiddenSpinner1] = useState(false);
  const [stateEnableBtnLogIn, setStateEnableBtnLogIn] = useState('A');

  useEffect(() => {
    const queryParameters = new URLSearchParams(search);
    const refChangePass = queryParameters.get("refChangePass");     
    
    if(refChangePass != null) {
      //sessionStorage.setItem('refChangePass', refChangePass);
      useGlobalVariableContextTmp.refChangePass = refChangePass;

      navigate('/changeYourPassword', { replace: true });
    }   
    
  }, []);


  const handleChange = (event) => {
    const {name, value} = event.target;    
    setstateUserdata((prevState) => ({ ...prevState, [name]: value }));
  }

  const onClickDOMEvent_btnI = async (e) => {
    e.preventDefault();

    setStateEnableBtnLogIn('');
    sethiddenSpinner1(true);

    const { status, data } = await tokenService.Authentication({user: stateUserdata.email, password: stateUserdata.password});   

    if(status == 200) {       
       auth?.login(data.data);         
       //console.log(data.data);               
    } else {
      setstateShowValidUser(true);
    }

    sethiddenSpinner1(false);
    setStateEnableBtnLogIn('A');
  }

  const onclickBtnHideAlertUserNotExist = (e) => {
    e.preventDefault()

    setstateShowValidUser(false);
  }

  if(auth.user) {
    return <Navigate to="/home" />
  }

  return (    
    <>
      
        <br></br><br></br><br></br>
        <br></br><br></br><br></br>
        <LoginUI 
          stateUserdata= {stateUserdata}          
          handleChange={handleChange}          
          onClickDOMEvent_btnI={onClickDOMEvent_btnI}    
          stateShowValidUser={stateShowValidUser}      
          hiddenSpinner1={hiddenSpinner1}
          stateEnableBtnLogIn={stateEnableBtnLogIn}
          onclickBtnHideAlertUserNotExist={onclickBtnHideAlertUserNotExist}
        ></LoginUI>     
      
    </>
  );
}

export default Login;
