import axios from 'axios';
import Config from "../config.json";

///const APIREST_BILBOBOLSON_URL = `${process.env.REACT_APP_APIREST_BILBOBOLSON_URL}/v1/Notifications`;
const APIREST_BILBOBOLSON_URL = `${Config.REACT_APP_APIREST_BILBOBOLSON_URL}/v1/Notifications`;

const postSendResetPasswordEmail = async (values) => {

    const response = await axios.post( `${APIREST_BILBOBOLSON_URL}/SendResetPasswordEmail`, values);
    const data = await response.data;   

    return data;
}

const postSendEmailContactUs= async (values) => {

    const response = await axios.post( `${APIREST_BILBOBOLSON_URL}/SendEmailContactUs`, values);
    const data = await response.data;   

    return data;
}

const postSendEmailByNewSale = async (values) => {

    const response = await axios.post( `${APIREST_BILBOBOLSON_URL}/SendEmailByNewSale`, values);
    const data = await response.data;   

    return data;

}


export const notificationService = {    
    postSendResetPasswordEmail,    
    postSendEmailContactUs,
    postSendEmailByNewSale
}