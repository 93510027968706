import React, { useEffect, useState , useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';

import { useAuth } from '../Context/auth';

import { AzureBlobStorageService } from '../Services/azureBlobStorageService';

import { Utils } from '../Utils/utils' 

const LeftSideBar = ({children}) => {

	const [imgUrl, setImgUrl] = useState();

	const wrapperRefwrapperRefMenuProduct = useRef(null);

	window.isMinified = false;
	window.isCollapsed = false;

	const auth = useAuth();

	//console.log(auth);

    const navigate = useNavigate();
	 // handle click event of logout button
	const handleLogout = () => {
        auth.logout();
        navigate('/login');
    }

	const sidebarTogler =()=>{
		const body = document.getElementById('body');

		// console.log(body.classList);
		// console.log(window.outerWidth);

		if (window.outerWidth < 768) {
			const min = "sidebar-mobile-in";
			const min_out = "sidebar-mobile-out";
				
			if (body.classList.contains(min)) {
				body.classList.remove(min);
				body.classList.add(min_out);
			} else {
				body.classList.add(min);
				body.classList.remove(min_out);
			}

		}
		
		if (window.outerWidth >= 768){	
			

			if (body.classList.contains('sidebar-fixed-offcanvas') || body.classList.contains('sidebar-static-offcanvas')) 
			{
				body.classList.add('sidebar-offcanvas-toggle');
				body.classList.remove('sidebar-toggle');
				if (window.isCollapsed === false) {
					body.classList.add('sidebar-collapse');
					window.isCollapsed = true;
					window.isMinified = false;
				} else {
					body.classList.remove('sidebar-collapse');
					body.classList.add('sidebar-collapse-out');
				setTimeout(function () {
					body.classList.remove('sidebar-collapse-out');
				}, 300);
				window.isCollapsed = false;
				}
			}
		
			if (body.classList.contains('sidebar-fixed') || body.classList.contains('sidebar-static')) {
				body.classList.add("sidebar-toggle");
				body.classList.remove('sidebar-offcanvas-toggle');
				if (window.isMinified === false) {
					body.classList.remove("sidebar-collapse");
					body.classList.remove("sidebar-minified-out");
					body.classList.add("sidebar-minified");
					window.isMinified = true;
					window.isCollapsed = false;
				} else {
					body.classList.remove("sidebar-minified");
					//body.classList.remove("sidebar-toggle");
					body.classList.add("sidebar-minified-out");
					window.isMinified = false;
				}
			}
		}

		// if (window.outerWidth >= 768 && window.outerWidth < 992768){
		// 	if (body.classList.contains("sidebar-fixed") || body.classList.contains("sidebar-static")) {
		// 		body.classList.remove("sidebar-collapse");
		// 		body.classList.remove("sidebar-minified-out");				
		// 		body.classList.add("sidebar-minified");
		// 		window.isMinified = true;
		// 	  }
		// }
	}

	const onclicNavlinkPaymentGateway = () => {

		if (window.outerWidth < 768) {
			const min = "sidebar-mobile-in";
			const min_out = "sidebar-mobile-out";
				
			if (body.classList.contains(min)) {
				body.classList.remove(min);
				body.classList.add(min_out);
			} else {
				body.classList.add(min);
				body.classList.remove(min_out);
			}

		}
	}	

	const deleteOptionByuser = () => {
		//console.log(auth.user.rolAndMenuOptions);		
		const opcMenuarray = [...auth.user.rolAndMenuOptions];

		opcMenuarray.map(pElement => {			
			if (pElement.esvisible ==  false) {
				var element = document.getElementById(pElement.referencia);
				if(element != null){
					element.parentNode.removeChild(element);	
				}
			}			
		});
	}	

	const onclicNavlinkCompany = () => {}

	const onclicNavlinkTicket = () => {}

	useEffect(() =>{
		//wrapperRef.current.remove();	

		deleteOptionByuser();

		AzureBlobStorageService.Download(auth.user.token, auth.user.companyLogo).then((response) =>{
			var contentArray = response.data.contentByteArray;				
			setImgUrl(`data:image/jpeg;base64,${contentArray}`);			
		});
	}, [])	
	
    return(
        <>
			{/* ====================================
			——— WRAPPER
			===================================== */}
			<div className="wrapper">
				{/* ====================================
				——— LEFT SIDEBAR WITH OUT FOOTER
				===================================== */}
				<aside className="left-sidebar sidebar-dark" id="left-sidebar">
					<div id="sidebar" className="sidebar sidebar-with-footer">
						{/* -- Aplication Brand -- */}
						<div className="app-brand">
							<a href="/">
								{/* <img src="./assets/images/logo.png" alt="Mono" /> */}
								<img src={imgUrl} />
								<span className="brand-name"></span>
							</a>
						</div>
						{/* -- begin sidebar scrollbar -- */}						
						<div className="sidebar-left" data-simplebar style={{ height: "100%" }}>
							{/* -- sidebar menu -- */}
              				<ul className="nav sidebar-inner" id="sidebar-menu">	  						    
								<li>
									<NavLink className="sidenav-item-link" to="/businessDashboard">
										<i className="mdi mdi-chart-line"></i>
										<span className="nav-text">Dashboard Negocio</span>
									</NavLink>									
								</li>
								<li className="section-title">
									Paginas
								</li>								
								<li className="has-sub" id="wrapperRefMenuProduct">								
									<a className="sidenav-item-link" 
										href="#" onClick={(e) => {e.preventDefault();}} 
										data-toggle="collapse" data-target="#products"
										aria-expanded="false" aria-controls="users"
										style={{ pointerEvents: 'default'}}>
										<i className="mdi mdi-image-filter-none"></i>
										<span className="nav-text">Productos</span> <b className="caret"></b>
									</a>
									<ul  className="collapse"  id="products" data-parent="#sidebar-menu">
										<div className="sub-menu">										
											<li>
												<NavLink className="sidenav-item-link" id="wrapperRefOptionAdminProd"
												to="/commerceProductList" 
												onClick={onclicNavlinkPaymentGateway}>
													<span className="nav-text">Admin. productos</span>
												</NavLink>												
											</li>				
											<li>
												<NavLink className="sidenav-item-link" id="wrapperRefOptionQRProd"
												to="/productBycommerceQR" 
												onClick={onclicNavlinkPaymentGateway}>
													<span className="nav-text">QR de productos</span>
												</NavLink>
											</li>														
										</div>
									</ul>
								</li>
								<li  className="has-sub" >								
									<a className="sidenav-item-link" id="wrapperRefMenuSales"
										href="#" onClick={(e) => {e.preventDefault();}} 
										data-toggle="collapse" data-target="#sales"
										aria-expanded="false" aria-controls="users"
										style={{ pointerEvents: 'default'}}>
										<i className="mdi mdi-sale"></i>
										<span className="nav-text">Ventas</span> <b className="caret"></b>
									</a>
									<ul  className="collapse"  id="sales" data-parent="#sidebar-menu">
										<div className="sub-menu">										
											<li>
												<NavLink className="sidenav-item-link" to="/clientList" id="wrapperRefOptionClients">
													<span className="nav-text">Clientes</span>	
												</NavLink>												
											</li>			
											<li>
												<NavLink className="sidenav-item-link" id="wrapperRefTicket"
													to="/PullApartSelStore" 
													onClick={onclicNavlinkTicket}>
														<span className="nav-text">Registro de apartados</span>
												</NavLink>
											</li>	
											<li>
												<NavLink className="sidenav-item-link" id="wrapperRefOptionSalesList"
													to="/salesList" 
													onClick={onclicNavlinkPaymentGateway}>
													<span className="nav-text">Listado de ventas</span>
												</NavLink>		
											</li>		
											<li>
												<NavLink className="sidenav-item-link" id="wrapperRefOptionSaleBalance"
													to="/salesWithPayments" 
													onClick={onclicNavlinkPaymentGateway}>
													<span className="nav-text">Saldos x venta</span>
												</NavLink>												
											</li>										
										</div>
									</ul>
								</li>
								<li  className="has-sub" >								
									<a className="sidenav-item-link" id="wrapperRefMenuConfig"
										href="#" onClick={(e) => {e.preventDefault();}} 
										data-toggle="collapse" data-target="#files"
										aria-expanded="false" aria-controls="users"
										style={{ pointerEvents: 'default'}}>
										<i className="mdi mdi-settings"></i>
										<span className="nav-text">Configuración</span> <b className="caret"></b>
									</a>
									<ul  className="collapse"  id="files" data-parent="#sidebar-menu">
										<div className="sub-menu">	
											<li>
												<NavLink className="sidenav-item-link" id="wrapperRefOptionCompany"
													to="/CompanyEdit" onClick={onclicNavlinkPaymentGateway}>
													<span className="nav-text">Compañia</span>
												</NavLink>
											</li>											
											<li>
												<NavLink className="sidenav-item-link" id="wrapperRefOptionBranch"
													to="/commerceList" onClick={onclicNavlinkPaymentGateway}>
													<span className="nav-text">Listado de Sucursales</span>
												</NavLink>
											</li>		
											<li>
												<NavLink className="sidenav-item-link" id="wrapperRefUsers"
													to="/UserList" onClick={onclicNavlinkPaymentGateway}>
													<span className="nav-text">Listado de Usuarios</span>
												</NavLink>
											</li>																
										</div>
									</ul>
								</li>								
								<li  className="has-sub" >
									<a className="sidenav-item-link" href="#" onClick={(e) => {e.preventDefault();}} id="wrapperRefMenuReports"
										data-toggle="collapse" data-target="#reports"
										aria-expanded="false" aria-controls="reports">
										<i className="mdi mdi-book-open-variant"></i>
										<span className="nav-text">Informes</span> <b className="caret"></b>
									</a>
									<ul  className="collapse"  id="reports" data-parent="#sidebar-menu">
										<div className="sub-menu">										
											<li>
												<NavLink className="sidenav-item-link" id="wrapperRefOptionCxc"
													to="/cxcList" 
													onClick={onclicNavlinkPaymentGateway}>
													<span className="nav-text">Cuentas X Cobrar</span>
												</NavLink>
											</li>																			
										</div>
									</ul>
								</li>
								<li  className="has-sub" >
									<a className="sidenav-item-link" href="#" onClick={(e) => {e.preventDefault();}} id="wrapperRefPointOfSale"
										data-toggle="collapse" data-target="#pospymeindexb"
										aria-expanded="false" aria-controls="reports">
										<i className="mdi mdi-square-inc-cash"></i>
										<span className="nav-text">Punto de venta</span> <b className="caret"></b>
									</a>
									<ul  className="collapse"  id="pospymeindexb" data-parent="#sidebar-menu">
										<div className="sub-menu">										
											<li>
												<NavLink className="sidenav-item-link" id="wrapperRefCashier"
													to="/PosPymeIndex" 
													onClick={onclicNavlinkPaymentGateway}>
														<span className="nav-text">Caja</span>
												</NavLink>
											</li>
																																					
										</div>
									</ul>									
								</li>								
								<li  className="has-sub" >
									<a className="sidenav-item-link" href="#" onClick={(e) => {e.preventDefault();}} id="wrapperRefMenuHelp"
										data-toggle="collapse" data-target="#support"
										aria-expanded="false" aria-controls="reports">
										<i className="mdi mdi-square-edit-outline"></i>
										<span className="nav-text">Ayuda</span> <b className="caret"></b>
									</a>
									<ul  className="collapse"  id="support" data-parent="#sidebar-menu">
										<div className="sub-menu">											
											<li>
												<NavLink className="sidenav-item-link" id="wrapperRefOptionContactMe"
													to="/contactus" 
													onClick={onclicNavlinkPaymentGateway}>
													<span className="nav-text">Contactanos</span>
												</NavLink>
											</li>																											
										</div>
									</ul>									
								</li>
								
							</ul>
						</div>

						{/* <div className="sidebar-footer">
							<div className="sidebar-footer-content">
								<ul class="d-flex">
								<li>
									<a href="user-account-settings.html" data-toggle="tooltip" title="Profile settings"><i className="mdi mdi-settings"></i></a></li>
								<li>
									<a href="#" data-toggle="tooltip" title="No chat messages"><i className="mdi mdi-chat-processing"></i></a>
								</li>
								</ul>
							</div>
						</div> */}

					</div>
				</aside>

				{/* -- ====================================
				——— PAGE WRAPPER
				===================================== -- */}
				<div className="page-wrapper">
					{/* -- Header -- */}
					<header className="main-header" id="header">
						<nav className="navbar navbar-expand-lg navbar-light" id="navbar">
							{/* -- Sidebar toggle button -- */}
							<button id="sidebar-toggler" className="sidebar-toggle" onClick={sidebarTogler}>
								<span className="sr-only">Toggle navigation</span>
							</button>
							<span className="page-title"></span>
							<div className="navbar-right ">
								{/* -- search form -- */}
								{/* <div className="search-form">
									<form action="index.html" method="get">
										<div className="input-group input-group-sm" id="input-group-search">
										<input type="text" autoComplete="off" name="query" id="search-input" className="form-control" placeholder="Search..." />
										<div className="input-group-append">
											<button className="btn" type="button">/</button>
										</div>
										</div>
									</form>
									<ul className="dropdown-menu dropdown-menu-search">
										<li className="nav-item">
											<a className="nav-link" href="index.html">Morbi leo risus</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" href="index.html">Dapibus ac facilisis in</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" href="index.html">Porta ac consectetur ac</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" href="index.html">Vestibulum at eros</a>
										</li>
									</ul>
								</div> */}
								<ul className="nav navbar-nav">
									{/* -- Offcanvas -- */}
									{/* <li className="custom-dropdown">
										<a className="offcanvas-toggler active custom-dropdown-toggler" data-offcanvas="contact-off" href="javascript:void(0)" >
											<i className="mdi mdi-contacts icon"></i>
										</a>
									</li>
									<li className="custom-dropdown">
										<button className="notify-toggler custom-dropdown-toggler">
											<i className="mdi mdi-bell-outline icon"></i>
											<span className="badge badge-xs rounded-circle">21</span>
										</button>
										<div className="dropdown-notify">
											<header>
												<div className="nav nav-underline" id="nav-tab" role="tablist">
												<a className="nav-item nav-link active" id="all-tabs" data-toggle="tab" href="#all" role="tab" aria-controls="nav-home"
													aria-selected="true">All (5)</a>
												<a className="nav-item nav-link" id="message-tab" data-toggle="tab" href="#message" role="tab" aria-controls="nav-profile"
													aria-selected="false">Msgs (4)</a>
												<a className="nav-item nav-link" id="other-tab" data-toggle="tab" href="#other" role="tab" aria-controls="nav-contact"
													aria-selected="false">Others (3)</a>
												</div>
											</header>

											<div className="" data-simplebar style={{height: "325px"}}>
												<div className="tab-content" id="myTabContent">

												<div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tabs">

													<div className="media media-sm bg-warning-10 p-4 mb-0">
														<div className="media-sm-wrapper">
															<a href="user-profile.html">
															<img src="./assets/images/user/user-sm-02.jpg" alt="User Image" />
															</a>
														</div>
														<div className="media-body">
															<a href="user-profile.html">
																<span className="title mb-0">John Doe</span>
																<span className="discribe">Extremity sweetness difficult behaviour he of. On disposal of as landlord horrible. Afraid at highly months do things on at.</span>
																<span className="time">
																	<time>Just now</time>...
																</span>
															</a>
														</div>
													</div>

													<div className="media media-sm p-4 bg-light mb-0">
													<div className="media-sm-wrapper bg-primary">
														<a href="user-profile.html">
														<i className="mdi mdi-calendar-check-outline"></i>
														</a>
													</div>
													<div className="media-body">
														<a href="user-profile.html">
														<span className="title mb-0">New event added</span>
														<span className="discribe">1/3/2014 (1pm - 2pm)</span>
														<span className="time">
															<time>10 min ago...</time>...
														</span>
														</a>
													</div>
													</div>

													<div className="media media-sm p-4 mb-0">
														<div className="media-sm-wrapper">
															<a href="user-profile.html">
															<img src="./assets/images/user/user-sm-03.jpg" alt="User Image" />
															</a>
														</div>
														<div className="media-body">
															<a href="user-profile.html">
																<span className="title mb-0">Sagge Hudson</span>
																<span className="discribe">On disposal of as landlord Afraid at highly months do things on at.</span>
																<span className="time">
																	<time>1 hrs ago</time>...
																</span>
															</a>
														</div>
													</div>

													<div className="media media-sm p-4 mb-0">
														<div className="media-sm-wrapper bg-info-dark">
															<a href="user-profile.html">
															<i className="mdi mdi-account-multiple-check"></i>
															</a>
														</div>
														<div className="media-body">
															<a href="user-profile.html">
															<span className="title mb-0">Add request</span>
															<span className="discribe">Add Dany Jones as your contact.</span>
															<div className="buttons">
																<a href="#" className="btn btn-sm btn-success shadow-none text-white">accept</a>
																<a href="#" className="btn btn-sm shadow-none">delete</a>
															</div>
															<span className="time">
																<time>6 hrs ago</time>...
															</span>
															</a>
														</div>
													</div>

													<div className="media media-sm p-4 mb-0">
														<div className="media-sm-wrapper bg-info">
															<a href="user-profile.html">
															<i className="mdi mdi-playlist-check"></i>
															</a>
														</div>
														<div className="media-body">
															<a href="user-profile.html">
															<span className="title mb-0">Task complete</span>
															<span className="discribe">Afraid at highly months do things on at.</span>
															<span className="time">
																<time>1 hrs ago</time>...
															</span>
															</a>
														</div>
													</div>

												</div>

												<div className="tab-pane fade" id="message" role="tabpanel" aria-labelledby="message-tab">
													<div className="media media-sm p-4 mb-0">
														<div className="media-sm-wrapper">
															<a href="user-profile.html">
															<img src="./assets/images/user/user-sm-01.jpg" alt="User Image" />
															</a>
														</div>
														<div className="media-body">
															<a href="user-profile.html">
																<span className="title mb-0">Selena Wagner</span>
																<span className="discribe">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
																<span className="time">
																	<time>15 min ago</time>...
																</span>
															</a>
														</div>
													</div>

													<div className="media media-sm p-4 mb-0">
													<div className="media-sm-wrapper">
														<a href="user-profile.html">
														<img src="./assets/images/user/user-sm-03.jpg" alt="User Image" />
														</a>
													</div>
													<div className="media-body">
														<a href="user-profile.html">
														<span className="title mb-0">Sagge Hudson</span>
														<span className="discribe">On disposal of as landlord Afraid at highly months do things on at.</span>
														<span className="time">
															<time>1 hrs ago</time>...
														</span>
														</a>
													</div>
													</div>

													<div className="media media-sm bg-warning-10 p-4 mb-0">
													<div className="media-sm-wrapper">
														<a href="user-profile.html">
														<img src="./assets/images/user/user-sm-02.jpg" alt="User Image" />
														</a>
													</div>
													<div className="media-body">
														<a href="user-profile.html">
														<span className="title mb-0">John Doe</span>
														<span className="discribe">Extremity sweetness difficult behaviour he of. On disposal of as landlord horrible. Afraid
															at highly months do things on at.</span>
														<span className="time">
															<time>Just now</time>...
														</span>
														</a>
													</div>
													</div>

													<div className="media media-sm p-4 mb-0">
													<div className="media-sm-wrapper">
														<a href="user-profile.html">
														<img src="images/user/user-sm-04.jpg" alt="User Image" />
														</a>
													</div>
													<div className="media-body">
														<a href="user-profile.html">
														<span className="title mb-0">Albrecht Straub</span>
														<span className="discribe"> Beatae quia natus assumenda laboriosam, nisi perferendis aliquid consectetur expedita non tenetur.</span>
														<span className="time">
															<time>Just now</time>...
														</span>
														</a>
													</div>
													</div>

												</div>
												<div className="tab-pane fade" id="other" role="tabpanel" aria-labelledby="contact-tab">

													<div className="media media-sm p-4 bg-light mb-0">
													<div className="media-sm-wrapper bg-primary">
														<a href="user-profile.html">
														<i className="mdi mdi-calendar-check-outline"></i>
														</a>
													</div>
													<div className="media-body">
														<a href="user-profile.html">
														<span className="title mb-0">New event added</span>
														<span className="discribe">1/3/2014 (1pm - 2pm)</span>
														<span className="time">
															<time>10 min ago...</time>...
														</span>
														</a>
													</div>
													</div>

													<div className="media media-sm p-4 mb-0">
													<div className="media-sm-wrapper bg-info-dark">
														<a href="user-profile.html">
														<i className="mdi mdi-account-multiple-check"></i>
														</a>
													</div>
													<div className="media-body">
														<a href="user-profile.html">
														<span className="title mb-0">Add request</span>
														<span className="discribe">Add Dany Jones as your contact.</span>
														<div className="buttons">
															<a href="#" className="btn btn-sm btn-success shadow-none text-white">accept</a>
															<a href="#" className="btn btn-sm shadow-none">delete</a>
														</div>
														<span className="time">
															<time>6 hrs ago</time>...
														</span>
														</a>
													</div>
													</div>

													<div className="media media-sm p-4 mb-0">
													<div className="media-sm-wrapper bg-info">
														<a href="user-profile.html">
														<i className="mdi mdi-playlist-check"></i>
														</a>
													</div>
													<div className="media-body">
														<a href="user-profile.html">
														<span className="title mb-0">Task complete</span>
														<span className="discribe">Afraid at highly months do things on at.</span>
														<span className="time">
															<time>1 hrs ago</time>...
														</span>
														</a>
													</div>
													</div>

												</div>
												</div>
											</div>

											<footer className="border-top dropdown-notify-footer">
												<div className="d-flex justify-content-between align-items-center py-2 px-4">
												<span>Last updated 3 min ago</span>
												<a id="refress-button" href="javascript:void(0)" className="btn mdi mdi-cached btn-refress"></a>
												</div>
											</footer>
										</div>
									</li> */}
									{/* -- User Account -- */}
									<li className="dropdown user-menu">
										<button className="dropdown-toggle nav-link" data-toggle="dropdown">
											<img src="./assets/images/user/user-xs-01.jpg" 
												className="user-image rounded-circle" alt="User Image" />
											<span className="d-none d-lg-inline-block">{auth?.user.userName}</span>
										</button>
										<ul className="dropdown-menu dropdown-menu-right">
											{/* <li>
												<a className="dropdown-link-item" href="user-profile.html">
												<i className="mdi mdi-account-outline"></i>
												<span className="nav-text">My Profile</span>
												</a>
											</li> */}
											{/*
											<li>
												<a class="dropdown-link-item" href="email-inbox.html">
												<i class="mdi mdi-email-outline"></i>
												<span class="nav-text">Message</span>
												<span class="badge badge-pill badge-primary">24</span>
												</a>
											</li>
											<li>
												<a class="dropdown-link-item" href="user-activities.html">
												<i class="mdi mdi-diamond-stone"></i>
												<span class="nav-text">Activitise</span></a>
											</li>
											<li>
												<a class="dropdown-link-item" href="user-account-settings.html">
												<i class="mdi mdi-settings"></i>
												<span class="nav-text">Account Setting</span>
												</a>
											</li> */}
											<li className="dropdown-footer">
												<a className="dropdown-link-item" href="#" onClick={(e) => {e.preventDefault();}} > 
													<i className="mdi mdi-logout" onClick={handleLogout}> Salir</i>  
												</a>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</nav>
					</header>

					{/* -- ====================================
					——— CONTENT WRAPPER
					===================================== -- */}
					<div className="content-wrapper">
						<div className="content">
							<div className="row">
								<div className="col-lg-12">
									{children}
								</div>
							</div>
						</div>
					</div>


					{/* <!-- Footer --> */}
					<footer className="footer mt-auto">
						<div className="copyright bg-white">
						<p>
							&copy; <span id="copy-year"></span> Copyright Axify Admin CxC by <a className="text-primary" href="https://www.axify.shop/" target="_blank" >Axify & TabanoSoftware</a>.  v 1.3
						</p>
						</div>
					</footer>
				</div>
			</div>
        </>
    )
}

export default LeftSideBar;

