import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const AuthContext = React.createContext();

const AuthProvider = ({children}) => {
  const navigate = useNavigate();
  const [user, setUser] = React.useState(null);
 
  const login  = (user) => {
    setUser(user);    
    navigate('/home');
  }

  const logout = () => {
    setUser(null);    
    navigate('/');
  }
 
  const auth = {user, login, logout};

  return (
    <AuthContext.Provider value = {auth}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const auth = React.useContext(AuthContext);
  return auth;
}

function AuthRoute(props) {
  const auth = useAuth();

  if (!auth.user) {
    return <Navigate to="/login" replace/>;
  }

  return props.children;
}

export  {
  AuthProvider,
  AuthRoute,
  useAuth,
};