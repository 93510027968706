import axios from 'axios';
import Config from "../config.json";

const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/AzureBlobStorage`;

// const CONFIG_REQUEST = {
//     headers: { 
//         'Content-Type': 'application/json',
//         'Authorization': ''
//     }
// };    

const Download = async (auth_token, logoname) => {
    //CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;   

    const CONFIG_REQUEST = {
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth_token}`
        }
    };    

    const response = await axios.get(`${API_BASE_URL}/Download/${logoname}`, CONFIG_REQUEST);
    const data = await response.data;

    return data;
};

const Upload = async (auth_token, formData) => { 
    const CONFIG_REQUEST = {
        headers: { 
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${auth_token}`
        }
    };    

    const response = await axios.post(`${API_BASE_URL}/UpLoad/`, formData, CONFIG_REQUEST);
    const data = await response.data;

    return data;
};

export const AzureBlobStorageService = {
    Download,
    Upload
}