import axios from 'axios';
import Config from "../../config.json";

///const API_BASE_URL = `${process.env.REACT_APP_APIREST_CXC_URL}/Cxc`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_CXC_URL}/v1/Cxc`;

const getAll = async () => { 
    const objRequest = await axios.get(`${API_BASE_URL}`);
    const response = objRequest.data;

    return response;
}


const getByVentaId = async (ventaid) => { 
    const objRequest = await axios.get(`${API_BASE_URL}/ByVentaId/${ventaid}`);
    const response = objRequest.data;

    return response;
}


const getByIdWithBalance = async (id) => { 
    const objRequest = await axios.get(`${API_BASE_URL}/ByIdWithBalance/${id}`);
    const response = objRequest.data;

    return response;
}


const getByBusinesidVentaDateH = async (companyid, businessid, ventaid, datebegin, dateend) => { 
    const objRequest = await axios.get(`${API_BASE_URL}/ByBusinesidVentaidDateH/${companyid}/${businessid}/${ventaid}/${datebegin}/${dateend}`);
    const response = objRequest.data;

    return response;
}

const getByBusinesidVentaDateD = async (ventaid) => { 
    const objRequest = await axios.get(`${API_BASE_URL}/ByBusinesidVentaidDateD/${ventaid}`);
    const response = objRequest.data;

    return response;
}


const getPlan = async (ventaid, datebegin, dateend, ammount, frecuency) => { 
    const objRequest = await axios.get(`${API_BASE_URL}/plan/${ventaid}/${datebegin}/${dateend}/${ammount}/${frecuency}`);
    const response = objRequest.data;

    return response;
}

const postList = async (values) => {    
    const response = await axios.post(`${API_BASE_URL}/byList`, values );
    const data = await response.data;   

    return data;
}

const _deleteByVentaId  = async (id) => {    
    const response = await axios.delete( `${API_BASE_URL}/ByVentaId/${id}` );
    const data = await response.data;       

    return data;
}

export const cxcService = {
    getAll,
    getByVentaId,
    getByIdWithBalance,
    getByBusinesidVentaDateH,
    getByBusinesidVentaDateD,
    getPlan,
    postList,
    deleteByVentaId: _deleteByVentaId
}