import React from 'react';

import LeftSideBar from '../Components/LeftSideBar';

const MainLayout = ({ children }) => {
	return (
		<>			
			{/* <!-- [ Pre-loader ] start --> */}
			<div className="loader-bg">
				<div className="loader-track">
					<div className="loader-fill"></div>
				</div>
			</div>
			{/* <!-- [ Pre-loader ] End --> */}			
			<LeftSideBar>
				{children}			
			</LeftSideBar>
			
		</>
	)
}


export default MainLayout;
