import axios from 'axios';

const getIP = async () => {
    const objResponse = await axios.get("https://api.ipify.org/?format=json");    
    const response = objResponse.data;

    return response;
}

export const IpifyService = {
    getIP
}