import axios from 'axios';
import Config from "../config.json";

///const API_BASE_URL = `${process.env.REACT_APP_APIREST_BASE_URL}/v1/PaymentGateway`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/PlanSubscription`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
};     

const getAll = async (auth_token) => {        
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetAllAsync`, CONFIG_REQUEST);
    const data = await response.data;

    return data;
}

const getById = async (auth_token,id) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetAsync/${id}`, CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}

export const planSubscriptionService = {
    getAll,
    getById,
}