import React, { useRef,useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import base64 from 'react-native-base64';
import swal from 'sweetalert';
import { IpifyService } from '../../Services/api_ipify_org';
import {v4 as uuidv4} from 'uuid';

import { useAuth }from '../../Context/auth';
import { businessService} from '../../Services/businessService';
import { businessProductService } from '../../Services/businessProductService';

import {pullApartRefService} from '../../Services/Cxc/PullApartService';

import { boromirUserService } from '../../Services/Boromir/boromirUserService';

import { Utils } from '../../Utils/utils';
import { boromirService } from '../../Services/Boromir/boromirService';



const ServicePaymentA = () => {
    const auth = useAuth(); 
    
    const navigate =  useNavigate(); 
   
    const [stateRefApartOrPayment, setStateRefApartOrPayment] = useState('');
    const [stateConfirmRefApartOrPayment, setStateConfirmRefApartOrPayment] = useState('');

    const [stateCommerce, setStateCommerce] = useState({
        id: 0,
        businessName: '',
        tradeName: '',
        rFC: '',
        tblBusinessTypeId: '',
        contact: '',
        phone: '',
        email: '',
        tblPasarelaPagoId: '',
        percentDeposit: 0,
        fracuencyPay: 0,        
        planPaymentDuration:0,
        ammountoCancel:0,
        companyId: 0,
        externalReferenceAntad: '',
        ssoId: 0
    });

    const [stateProductCommerce, setStateProductCommerce] = useState({
        id : 0,
        businessId : 0,
        name : '',
        description : '',
        sku : '',
        salePrice : 0
    });

    const [stateAmmount, setStateAmmount] = useState(0);
    const [stateAmmountApart, setStateAmmountapart] = useState(0);
    const [stateAmmountComision, setStateAmmountCommision] = useState(0);
    const [stateAmmountTotal, setStateAmmountTotal] = useState(0);
        
    //const [statecodigo01, setstatecodigo01] = useState('abcdefghi');

    const [stateMyIp, setStateMyIp] = useState('');

    const [stateResponseBoromirPullApart, setstateResponseBoromirPullApart] = useState(
        {           
            "Data": {
                "Id": 0,
                "SaleDate": new Date,
                "Description": "",
                "Details": "",
                "Sku": "",
                "Ammount": 0,
                "AmmountPre": 0
            },
            "IsSuccess": false,
            "Meta": null,
            "Error": []           
        }
    );

    const [stateSeeAlertSuccess, setstateSeeAlertSuccess] = useState(false);
    const [stateSeeAlertWarning, setstateSeeAlertWarning] = useState(false);

    const [stateEnableBtnSearchRef, setStateEnableBtnSearchRef] = useState('A');
    const [stateEnableBtnApplyRef, setStateEnableBtnApplyRef] = useState('');
    const [stateEnableBtnPrintTicket, setStateEnableBtnPrintTicket] = useState('');

    const [hiddenSpinner1, sethiddenSpinner1] = useState(false);

    useEffect(() => {
        IpifyService.getIP().then((response) =>{
            setStateMyIp(response.ip);
        });
    }, []);  

    const onChangeHandleChangeSelect = (event) => {
        setStateOperationType(event.target.value);
    }

    const onChangeRefApartOrPayment = (event)=> {
        setStateRefApartOrPayment(event.target.value);
    }    

    const onKeyDownRefApartOrPayment = (event) => {        
        // if (event.keyCode == 17 || event.keyCode == 86){
        //     console.log('ok');
        // } else {
        //     event.preventDefault();            
        // }
    }

    const onChangeConfirmRefApartOrPayment = (event) => {         
        setStateConfirmRefApartOrPayment(event.target.value);
    }

    const onKeyDownConfirmRefApartOrPayment = (event) => {        
        // if (event.keyCode == 17 || event.keyCode == 86){
        //     console.log('ok');
        // } else {
        //     event.preventDefault();            
        // }
    }

    const onClickBtnSearchOperation = () => {
        const operationType = stateRefApartOrPayment.substring(0, 1);
        const refTmp = stateRefApartOrPayment.substring(1, stateRefApartOrPayment.length);

        if (operationType === Utils.OperationTypeEnum.PullApart) {            
            SearchPullApartProcess(refTmp);
        } else {
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'las referencía no es para realizar un apartado!',
                footer: 'Favor de revisar los datos de las referencías'
            });
        }
    }

    const onClickBtnApplyOperation = async() => {
        if (stateRefApartOrPayment === stateConfirmRefApartOrPayment) {
            
			const operationType = stateConfirmRefApartOrPayment.substring(0, 1);
			
			const refTmp = stateConfirmRefApartOrPayment.substring(1, stateConfirmRefApartOrPayment.length);
            
            if (operationType === Utils.OperationTypeEnum.PullApart) {                
                PullApartProcess(refTmp);                               
            } else {
                
                swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'las referencía no es para realizar un apartado!',
                    footer: 'Favor de revisar los datos de las referencías'
                });
            }
        } else {
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'las referencías no son iguales!',
                footer: 'Favor de revisar los datos de las referencías'
              });
        }
    }

    const onClickBtnPrintTicket = () => {
        navigate(`/PosPymeTicketA/${stateResponseBoromirPullApart.data?.id}`, { replace: true });
        //navigate(`/PosPymeTicketA/854`, { replace: true });
    }

    const onClickBtnExit = () => {
        navigate('/PosPymeIndex', { replace: true });
    }

    const onClickBtnClean = () =>{
        setstateSeeAlertSuccess(false);
        setstateSeeAlertWarning(false);
        setStateRefApartOrPayment('');
        setStateConfirmRefApartOrPayment('');
        setStateCommerce(
            {
                id: 0,
                businessName: '',
                tradeName: '',
                rFC: '',
                tblBusinessTypeId: '',
                contact: '',
                phone: '',
                email: '',
                tblPasarelaPagoId: '',
                percentDeposit: 0,
                fracuencyPay: 0,        
                planPaymentDuration:0,
                ammountoCancel:0,
                companyId: 0,
                externalReferenceAntad: '',
                ssoId: 0
            }
        );
        setStateAmmount(0);
        setStateAmmountapart(0);
        setStateAmmountCommision(0);
        setStateAmmountTotal(0);

        setStateEnableBtnSearchRef('A');
        setStateEnableBtnApplyRef('');
        setStateEnableBtnPrintTicket('');
    }   

    /* common functions */
    const SearchPullApartProcess = async (refTmp) => {
        sethiddenSpinner1(true);

        let responseGetRef = await pullApartRefService.getById(+refTmp);

        if (responseGetRef.data.estatus)
        {
            const myArray = responseGetRef.data.referencia.split("|");  

            //console.log(JSON.stringify(myArray))

            businessService.getById(auth.user.token, myArray[1]).then((response) => {
                setStateCommerce(response.data);
            });
    
            businessProductService.getById(auth.user.token, myArray[2]).then((response) => {
                setStateProductCommerce(response.data);
            });

            setStateAmmount(+myArray[4]);
            setStateAmmountapart(+myArray[6]);
            setStateAmmountCommision(0);
            setStateAmmountTotal(+myArray[6]+0);           

            setStateEnableBtnSearchRef('');
            setStateEnableBtnApplyRef('A');

            sethiddenSpinner1(false);

        } else {
            sethiddenSpinner1(false);
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'las referencías ya esta caducada! --- Favor de crear una nueva referencía',
                footer: 'Favor de crear una nueva referencía'
            });            
        }
    }

    const PullApartProcess = async (refTmp) => {
        sethiddenSpinner1(true);
        setStateEnableBtnApplyRef('');
        let responseGetRef = await pullApartRefService.getById(+refTmp);
        const myArray = responseGetRef.data.referencia.split("|");  

        //console.log(response.data);        

        if (responseGetRef.data.estatus)
        {
            const userForAuthenticate = {
                "User": "soporte@axify.mx",
                "Password": "Axify2023"
            };
            const responseUserBoromir = await boromirUserService.postAuthenticate(userForAuthenticate);
                        
            //console.log(responseUserBoromir);

            const tokentoboromir = responseUserBoromir.token;
            const pullApartData = {
                "referencia": stateConfirmRefApartOrPayment,
                "importe": myArray[6],
                "compañia": `${auth.user.companyId}`,
                "sucursal": `${auth.user.commerceList[0]}`,
                "idtransaccion" : uuidv4()
            };

            //console.log(JSON.stringify(pullApartData));

            let responseBoromir = await boromirService.postProductApart(tokentoboromir, pullApartData);

            //console.log(responseBoromir);

            setstateResponseBoromirPullApart(responseBoromir);
            if(responseBoromir.error.length == 0) {
                setstateSeeAlertSuccess(true);
                
                setStateEnableBtnSearchRef('');
                setStateEnableBtnApplyRef('');
                setStateEnableBtnPrintTicket('A');
            } else {
                setstateSeeAlertWarning(true);
                
                setStateEnableBtnSearchRef('');
                setStateEnableBtnApplyRef('');
                setStateEnableBtnPrintTicket('');
            }

            sethiddenSpinner1(false);
        } else {
            sethiddenSpinner1(false);
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'las referencías ya esta caducada! --- Favor de crear una nueva referencía',
                footer: 'Favor de crear una nueva referencía'
            });            
        }        
    }  
   
    return (
        <>            
            <div className='row'>
                <div className='col'>
                    <h2>Aplicar Apartados</h2>
                </div>
                <div className='col'>
                    {
                        stateSeeAlertSuccess ? 
                            <div className="alert alert-success" role="alert">
                                {stateResponseBoromirPullApart.data?.description} FOLIO : {stateResponseBoromirPullApart.data?.id}
                            </div> : null}
                    {
                        stateSeeAlertWarning ?
                            <div className="alert alert-warning" role="alert">
                               {stateResponseBoromirPullApart.error[0]?.description}
                            </div> : null}
                </div>
            </div>
            <br></br>
            <hr></hr>
            <div className='row'>
                <div className='col'>
                    <div className='row'>
                        <div className='col'>
                            <label htmlFor="inputRefApartOrPayment" className="form-label">Escribe o escanea la referencía</label>
                            <input id="inputRefApartOrPayment" className="form-control" placeholder="Referencia" 
                                onChange={onChangeRefApartOrPayment}                                
                                onKeyDown={onKeyDownRefApartOrPayment}  
                                value={stateRefApartOrPayment}
                                autoComplete='off'
                            ></input>
                        </div>
                    </div>           
                    <div className='row'>
                        <div className='col'>
                            <button type="button" className="btn btn-primary btn-lg btn-block" 
                                onClick={onClickBtnSearchOperation}                                
                                disabled={!stateEnableBtnSearchRef}
                                >
                                    Buscar información de referencía                                
                            </button>                    
                        </div>
                    </div>  
                    <div className='row'>
                        <div className='col'>
                            <label htmlFor="inputConfirmRefApartOrPayment" className="form-label">Confirma la referencía</label>
                            <input id="inputConfirmRefApartOrPayment" className="form-control" placeholder="confirma Referencia" 
                                onChange={onChangeConfirmRefApartOrPayment}                               
                                onKeyDown={onKeyDownConfirmRefApartOrPayment}  
                                value={stateConfirmRefApartOrPayment}
                                autoComplete='off'  
                            ></input>
                        </div>
                    </div>      
                    <div className='row'>
                        <div className='col'>
                            <button type="button" className="btn btn-primary btn-lg btn-block" 
                                onClick={onClickBtnApplyOperation}
                                disabled={!stateEnableBtnApplyRef}                                
                                >Aplicar operación</button>
                        </div>
                    </div>
                    <br></br>
                    <div className='row'>
                        <div className='col' style={{textAlign: "center"}}>
                            { hiddenSpinner1 &&
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>                
                </div>
                <div className='col'>
                    <label for="OperationId" className="form-label">Resumen de la operación</label>
                    <div className="mb-3 row">
                        <label htmlFor="inputCompanyId" className="col-sm-2 col-form-label">Compania</label>
                        <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputCompanyId" value={auth.user.companyName}/>
                        </div>
                    </div>
                    
                    <div className="mb-3 row">
                        <label htmlFor="inputCompanyId" className="col-sm-2 col-form-label">Sucursal</label>
                        <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputCommerceId" value={stateCommerce.tradeName}/>
                        </div>
                    </div>                                 
                    
                    <div className="mb-3 row">
                        <label htmlFor="inputImport" className="col-sm-2 col-form-label">Importe</label>
                        <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputImport" value={Utils.currencyFormat(stateAmmount)}/>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="inputImportApart" className="col-sm-2 col-form-label">Apartado</label>
                        <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputImportApart" value={Utils.currencyFormat(stateAmmountApart)}/>
                        </div>
                    </div>
                    
                    <div className="mb-3 row">
                        <label htmlFor="inputCom" className="col-sm-2 col-form-label">Comición</label>
                        <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputCom" value={Utils.currencyFormat(stateAmmountComision)}/>
                        </div>
                    </div>
                    
                    <div className="mb-3 row">
                        <label htmlFor="inputtotal" className="col-sm-2 col-form-label">Total</label>
                        <div className="col-sm-10">
                        <input type="text" className="form-control" id="inputtotal" value={Utils.currencyFormat(stateAmmountTotal)}/>
                        </div>
                    </div>                   
                    
                    <div className='row'>
                        <div className='col'>
                            <button type="button" className="btn btn-primary btn-md" onClick={onClickBtnClean}>Limpiar</button>
                        </div>
                        <div className='col'>
                            <button type="button" className="btn btn-primary btn-md" 
                            onClick={onClickBtnPrintTicket}
                            disabled={!stateEnableBtnPrintTicket}
                        >Imprimir Ticket</button>                        
                        </div>
                        <div className='col' style={{textAlign: 'right'}}>
                            <button type="button" className="btn btn-danger btn-md" onClick={onClickBtnExit}>Salir</button>
                        </div>
                    </div>                    
                </div>
            </div>
        </>
    );
}

export default ServicePaymentA;
