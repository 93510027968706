import axios from 'axios';
import Config from "../config.json";

//const API_BASE_URL = `${process.env.REACT_APP_APIREST_BASE_URL}/v1/Security`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/Security`;

const CONFIG_REQUEST = {
    headers: { 'Content-Type': 'application/json' }    
};     

const getbyEmail = async (email) => {        
    const response = await axios.get( `${API_BASE_URL}/GetByEmailAsync/${email}`, CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}

const GetByIdCompany = async (companyid) => {        
    const response = await axios.get( `${API_BASE_URL}/GetByIdCompanyAsync/${companyid}`, CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}

const GetById = async (id) => {        
    const response = await axios.get( `${API_BASE_URL}/GetByIdAsync/${id}`, CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}

const post = async (values) => {

    const response = await axios.post( `${API_BASE_URL}/InsertAsync`, values, CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}

const putPassword  = async (id, values) => {        

    const response = await axios.put( `${API_BASE_URL}/UpdatePasswordAsync/${id}`, values , CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}

const putForCommerceUser = async (id, values) => {
    const response = await axios.put( `${API_BASE_URL}/UpdateForCommerceUserAsync/${id}`, values , CONFIG_REQUEST);
    const data = await response.data;       

    return data;


}

export const ssoService = {
    getbyEmail,
    GetByIdCompany,
    GetById,
    post,
    putPassword,
    putForCommerceUser
}