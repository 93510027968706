import axios from 'axios';
import Config from "../../config.json";

///const API_BASE_URL = `${process.env.REACT_APP_APIREST_CXC_URL}/CxcPayment`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_CXC_URL}/v1/CxcPayment`;

const getByDates = async (companyid, datebegin, dateend) => { 
    const objRequest = await axios.get(`${API_BASE_URL}/ByDates/${companyid}/${datebegin}/${dateend}`);
    const response = await objRequest.data;

    return response;
}

const post = async (values) => {    
    const response = await axios.post( API_BASE_URL, values );
    const data = await response.data;   

    return data;
}

export const cxcPaymentService = {
    post,
    getByDates
}