import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

const SelOperation = () => {
    const { id } = useParams();
    const navigate =  useNavigate();      

    const onclickHandlePullApart = (event) => {
        event.preventDefault()
        navigate(`/PullApartIndex/${id}`, { replace: true });
    }

    const onclickHandlePayment = (event) => {
        event.preventDefault()

        navigate(`/ServicePaymentB/${id}/${'Ex'}`, { replace: true });
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="jumbotron">
                        <h1 className="display-4">Registro de apartado express!</h1>
                        <p className="lead">Puedes crear apartados desde el principio.</p>
                        <hr className="my-4" />
                        <p>No necesitas codigo QR para crearlo.</p>
                        <p className="lead">
                            <button className="btn btn-primary btn-lg" role="button" onClick={(e) => onclickHandlePullApart(e)}>Apartados</button>
                        </p>
                    </div>
                </div>
                <div className="col">
                    <div className="jumbotron">
                        <h1 className="display-4">Registro de pagos!</h1>
                        <p className="lead">Registro de pagos.</p>
                        <hr className="my-4" />
                        <p>Pagos por referencías, por medio de codigo de barras.</p>
                        <p className="lead">
                            <button className="btn btn-primary btn-lg" role="button" onClick={(e) => onclickHandlePayment(e)}>Abonos</button>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelOperation;