import React , { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Moment from "react-moment";
import { Utils } from '../../Utils/utils'
import { useAuth } from '../../Context/auth';

import { salesService } from '../../Services/salesService';
import { cxcService } from '../../Services/Cxc/cxcService';

import './PaymentPlanView.css'

const PaymentPlanView = () => {
    const auth = useAuth(); 

    const { id } = useParams();
    const isParamNull = !id;    

    const [ stateSaleInfo, setStateSaleInfo ] = useState({
        id: 0,
        businessId: 0,
        saleDate: Date.now,
        description: '',
        details: '',
        clientId: 0,
        productId: 0,
        sku: '',
        ammount: 0,
        ammountPre: 0,
        limitDatePay: Date.now,
        vigencyDateToLC: Date.now,
        companyId: 0,
        apartReference: 0,
        creationDate: Date.now,
        updateDate: Date.now,
        ssoId: 0,
        saleStatusId: 0,
        cancelDate: Date.now,
        cancelNotes: '',
        ssoIdBuyerCancel: 0,
        ssoIdBussineeCancel: 0,
        numNotificationCancel: 0
    });
    const [ stateCxcBySaleIdList, setstateCxcBySaleIdList] = useState([]);

    const [ stateLastItemSale, setstateLastItemSale ] = useState('');

    useEffect(() => {
        if (!isParamNull) {
            salesService.getById(auth?.user.token, id).then(response => {                      
                setStateSaleInfo(response.data);                         
            });
            
            cxcService.getByVentaId(id).then(response => {                
                setstateCxcBySaleIdList(response.data);
                               
                setstateLastItemSale(response.data[response.data.length-1]);
            });
        }        
    }, []);

    return (
        <>
            <h1>Plan de Pagos</h1>
            <br></br>
          
            <div className="container">
                <div className="row">
                    <div className="col" style={{margin:'25px'}}>
                        <div className="row">
                            <div className="col">
                                <h3>Total</h3>
                            </div>
                            <div className="col">
                                <h2>{Utils.currencyFormat(stateSaleInfo.ammount)} mnx</h2>
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col" style={{textAlign:'center'}}>
                                <h3 style={{color: '#646CD7', fontWeight: 'bold'}}>Plan de pagos</h3>
                                <h4>Sin intereses</h4>
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col">
                                <div id="progressDiv" className="progressContainer">
                                    {
                                        stateCxcBySaleIdList && stateCxcBySaleIdList?.map((item, index) => 
                                            <div className="progressContainer-son" key={item.id}>
                                                <div className="progress-circle">{index}</div>
                                                <div className="progress-line"></div>
                                                <div className="progress-prices-info">
                                                    <div className="text-center font-weight-bold">
                                                        <span className="align-baseline">{Utils.currencyFormat(item.valor)}</span>
                                                        <small class="align-text-top font-weight-bold"> mxn</small>
                                                    </div>
                                                    <div className="text-color-light">
                                                        <small className="font-weight-bold">
                                                            <Moment format="DD/MM/YYYY">
                                                                {item.fechaVencimiento}
                                                            </Moment>                                                     
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col" style={{margin:'25px'}}>
                        <div className="row">
                            <div className="col"></div>
                            <div className="col">
                                <h3>Tu compra</h3>
                            </div>
                            <div className="col"></div>
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col" style={{textAlign: 'center'}}>
                                <h1></h1>
                            </div>
                        </div>
                        <br></br>
                        <div className="row">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{color: '#646CD7', fontWeight: 'bold', fontSize: 'large'}}>Descripción</td>                                        
                                        <td><div style={{ marginLeft: '15px'}}>{stateSaleInfo.details}</div></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <hr></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{color: '#646CD7', fontWeight: 'bold', fontSize: 'large'}}>Detalle</td>                                        
                                        <td><div style={{ marginLeft: '15px'}}>{stateSaleInfo.description}</div></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <hr></hr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{color: '#646CD7', fontWeight: 'bold', fontSize: 'large'}}>Fecha limite de pago</td>                                        
                                        <td>
                                            <div style={{ marginLeft: '15px', fontWeight: 'bold'}}>
                                                <Moment format="DD/MM/YYYY">{stateLastItemSale.fechaVencimiento}</Moment> 
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{color: '#646CD7', fontWeight: 'bold', fontSize: '40px'}}>Total</td>                                        
                                        <td>
                                            <div style={{ marginLeft: '15px', fontWeight: 'bold', fontSize: '40px'}}>
                                            {Utils.currencyFormat(stateSaleInfo.ammount)} mnx
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>  

        </>
    )
}

export default PaymentPlanView;