import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import moment from 'moment';

import SalesWithPaymentsUI from './SalesWithPaymentsUI'

import { businessService } from '../../Services/businessService';
import { cxcService } from '../../Services/Cxc/cxcService';

import { useAuth }from '../../Context/auth'

const SalesWithPayments = () => {
    const auth = useAuth();         
    const current = new Date();
    
    const [stateBusiness, setStateBusiness] = useState([]);
    const [stateSelectedBusinessId, setStateSelectedBusinessId] = useState([]);

    const [stateSaleCxcPaymentArray, setStateSaleCxcPaymentArray] = useState([]);
    
    const [stateDateBegin, setStateDateBegin] = useState(moment(current).format("YYYY-MM-DD"));
    const [stateDateEnd, setStateDateEnd] = useState(moment(current).format("YYYY-MM-DD"));

    useEffect(() => {
        setTimeout(() => {
            businessService.getAllByCompanyId(auth.user.token, auth.user.companyId).then( res => {   
                let newList = [];
                auth.user.commerceList.map(item =>  {
                    const index = res.data.findIndex((x) => x.id === item);
                    if(index != -1) {
                        newList = [...newList, res.data[index]];
                    }
                });               
                setStateBusiness(newList);                
            });                 
        }, 1000); 
    }, []);

    const onChangeDOMEvent_inputDateBegin = (item) => {        
        setStateDateBegin(item.target.value);
    }

    const onChangeDOMEvent_inputDateEnd = (item) => {
        setStateDateEnd(item.target.value);
    }

    const onClickDOMEvent_btnFiltrar = (e) => {
        e.preventDefault();
        cxcService.getByBusinesidVentaDateH(auth.user.companyId,0,0, stateDateBegin, stateDateEnd).then(res => {                   
            var dataFiltered = res.data.filter(f => f.comercioId == stateSelectedBusinessId );    
            if(dataFiltered.length == 0) {
                setStateSaleCxcPaymentArray([]);
                swal("No existen datos!", "favor de realizar de nuevo la busqueda!", "success")
            } else {
                setStateSaleCxcPaymentArray(dataFiltered);
            }            
        });    
    }

    const onChangeDOMEvent_selectbusiness = (e) => {
        setStateSelectedBusinessId(e.target.value);
    }

    return (
        <SalesWithPaymentsUI 
            stateDateBegin = {stateDateBegin}           
            stateDateEnd = {stateDateEnd}            
            onChangeDOMEvent_inputDateBegin= {onChangeDOMEvent_inputDateBegin}
            onChangeDOMEvent_inputDateEnd= {onChangeDOMEvent_inputDateEnd}
            onClickDOMEvent_btnFiltrar= {onClickDOMEvent_btnFiltrar}
            stateSaleCxcPaymentArray= {stateSaleCxcPaymentArray}
            onChangeDOMEvent_selectbusiness = {onChangeDOMEvent_selectbusiness}
            stateBusiness  = {stateBusiness }
        > 
        </SalesWithPaymentsUI>
    );
}

export default SalesWithPayments;