import axios from 'axios';
import Config from "../../config.json";

const API_BASE_URL = `${Config.REACT_APP_APIREST_BOROMIR_URL}/Boromir`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
};    

const postProductApart = async (auth_token, values) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.post(  `${API_BASE_URL}/apartado`, values, CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}

const postProductPayment = async (auth_token, values) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.post(  `${API_BASE_URL}/abono`, values, CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}

const postSaleWithOutRef = async (auth_token, values) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    console.log(auth_token);
    console.log(JSON.stringify(values));

    const response = await axios.post(  `${API_BASE_URL}/ventaSinRef`, values, CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}


export const boromirService = {
    postProductApart,
    postProductPayment,
    postSaleWithOutRef
}