import React from "react";

const PullApartSelStoreUI = (props) => {

    return (
        <>
            {
                props.stateBusiness && props.stateBusiness?.map(item => 
                    <div className="card" style={{width: "18rem", display: 'inline-block', marginLeft:'25px', marginTop:'15px'}} key={item.id}>
                        {/* <img src="..." className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <h5 className="card-title">{item.businessName}</h5>
                            <p className="card-text">{item.rfc}</p>
                            <button className="btn btn-primary" id="btnopenpos" onClick={e => props.onClickBtnOpenPos(e, item.id)}>abrir caja</button>
                        </div>
                    </div>                    
                )
            }
        </>
    );
}

export default PullApartSelStoreUI;