import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {notificationService} from '../../Services/notificationsService';  

import { useAuth } from '../../Context/auth'

const ContactUs = () => {
    const auth = useAuth(); 
    
    const navigate =  useNavigate();   

    const [stateContactUs, setStateContactUs] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        country: '',
        email: '',
        companyName: '',
        message: ''
    });    
    
    useEffect(() => {
       
    }, []);

    const onChangehandleChange = (event) => {
        const {name, value} = event.target;
        setStateContactUs((prevState) => ({
            ...prevState,
            [name]: value
        }));        
    }

    const onClickbtnSend = (e) => {
        e.preventDefault();
     
        notificationService.postSendEmailContactUs(stateContactUs);
        swal("Correo enviado!", "Nos pondremos en contacto!", "success")
        .then((value) => {
            navigate('/businessDashboard', { replace: true });
        });    
    }

    
    return (
        <>
            <div className="container">              
                <div className='row'>
                    <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h1>Enviar un mensaje</h1>
                    </div>
                </div>
                <br></br>
                <div className='row'>
                    <div className='col'>
                        <h3>Su dirección de correo electrónico no será publicada. Los campos obligatorios están marcados.</h3>
                    </div>
                </div>
                <br></br>          
                <div className='row'>
                    <div className='col'>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="fistName" className="form-label">Nombre</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="fistName"
                                    name="fistName"
                                    type="text"
                                    onChange={onChangehandleChange}
                                    value={stateContactUs.fistName}            
                                />                                  
                            </div>
                            <div className="col">
                                <label htmlFor="lastName" className="form-label">Apellidos</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    onChange={onChangehandleChange}
                                    value={stateContactUs.lastName}              
                                />
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="phoneNumber" className="form-label">Telefono</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    type="text"
                                    onChange={onChangehandleChange}
                                    value={stateContactUs.phoneNumber}              
                                />                                    
                            </div>                    
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="email"
                                    name="email"
                                    type="text"
                                    onChange={onChangehandleChange}
                                    value={stateContactUs.email}              
                                />                                    
                            </div>                    
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="companyName" className="form-label">Compañia</label>
                                <input className="form-control" placeholder="" required="" 
                                    id="companyName"
                                    name="companyName"
                                    type="text"
                                    onChange={onChangehandleChange}
                                    value={stateContactUs.companyName}              
                                />                                    
                            </div>                    
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="message" className="form-label">Mensaje</label>
                                <textarea className="form-control" placeholder="" required="" 
                                    id="message"
                                    name="message"
                                    type="text"
                                    onChange={onChangehandleChange}
                                    value={stateContactUs.message}  
                                    rows="4" cols="50"            
                                />                                    
                            </div>                    
                        </div>
                        <div className='row'>
                            <hr></hr>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <button style={{backgroundColor:'#FD9913', color:'white'}} type="submit" className="w-100 btn btn-success" onClick={e => onClickbtnSend(e)}>Enviar</button>    
                            </div>                                      
                        </div>
                    </div>
                    <div className='col'>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <p>Axify</p>
                        <p>1er retorno de sierra itambe 125.</p>
                        <p>Col. Real de las lomas </p>
                        <br></br>
                        <p>CDMX</p> 
                        <br></br>
                        <p>Mexico</p>
                        <br></br>
                        <h3>Llamanos</h3>
                        <p>Tel: 5526909678</p>
                        <br></br>
                        <p>8:00AM - 5:00PM EST</p>
                        <br></br>
                        <p>Escribenos:</p>
                        <p>info@apapguitos.com</p>                        
                    </div>
                </div>      
                <br></br>                
            </div>                   
        </>
    )
}

export default ContactUs;