import React, { useEffect, useState } from 'react'

import { useAuth }from '../../Context/auth'
import {businessDashboardService} from '../../Services/businessDashboardService';
import { Utils } from '../../Utils/utils';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const options = {
    title: {
      text: 'My chart'
    },
    series: [{
      data: [1, 10, 3, 10]
    }]
  };

const BusinessDashboard = (props) => {    
    const auth = useAuth(); 

    const [useStateResumeAmmount, setuseStateResumeAmmount] = useState({
        'salesNumber': 0,
        'salesAmount':0,        
        'salesAmountCollected':0,
        'salesAmountforCollect':0,
    });   

    const [useStateResume03, setuseStateResume03] = useState([]);    

    const [useChart01Option, setuseChart01Option] = useState(
        {
            chart: {
                type: 'column'
            },
            title: {
              text: 'Información de ventas y monto cobrado'
            },
            subtitle: {
                text: 'Fuente: Axify.shop'
            },
            xAxis: {
                categories: [
                    'Ene',
                    'Feb',
                    'Mar',
                    'Abr',
                    'May',
                    'Jun',
                    'Jul',
                    'Ago',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dic'
                ],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Monto (pesos)'
                }
            },
            tooltip: {
                formatter: function(){
                    var formatStr = "<b>Balance</b><br/><span>comparativa...</span><br/>";
                    
                    for (var i = 0; i < this.points.length; i++) {
                            var point = this.points[i];
                            
                            // Highcharts wont format the numbers (point.y) once we've taken control of the tooltip
                            formatStr += '<span style="color:' + point.color + '">●</span>' + point.series.name + ' : <b>' + Utils.currencyFormat(point.y) + '</b><span> x periodo</span><br/>';
                    }
                    
                    return formatStr;
                  },
                shared: true                
            },            
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Venta',
                data: []
            },
            {
                name: 'Cobrado',
                data: []
            }]
        }     
    );

    const [useChart02Option, setuseChart02Option] = useState(
        {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Resumen de ventas y pagos',
                align: 'left'
            },
            subtitle: {
                text:
                    'Source: axify',
                align: 'left'
            },
            xAxis: {
                categories: [],
                crosshair: true,
                accessibility: {
                    description: 'Sucursales'
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                valueSuffix: ' '
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: []
        }
    );


    useEffect(() => {
        const currentDateBegin = new Date;

        businessDashboardService.GetBusinessDashboardData01(auth.user.token, 
            `${currentDateBegin.getFullYear()}-01-01`, `${currentDateBegin.getFullYear()}-12-31`,
            auth.user.companyId
            ).then(res => {                                    
                setuseStateResumeAmmount(res.data);                
        });  

        businessDashboardService.GetBusinessDashboardData02(auth.user.token, 
            `${currentDateBegin.getFullYear()}-01-01`, `${currentDateBegin.getFullYear()}-12-31`,
            auth.user.companyId
            ).then(res => {                
                
                //console.log(res.data);

                const serie01 = [{
                    name: 'Ventas',
                    data: [
                        parseInt(res.data.find(f =>f.month == 1)?.salesAmount) || 0,
                        parseInt(res.data.find(f =>f.month == 2)?.salesAmount) || 0,
                        parseInt(res.data.find(f =>f.month == 3)?.salesAmount) || 0,
                        parseInt(res.data.find(f =>f.month == 4)?.salesAmount) || 0,
                        parseInt(res.data.find(f =>f.month == 5)?.salesAmount) || 0,
                        parseInt(res.data.find(f =>f.month == 6)?.salesAmount) || 0,
                        parseInt(res.data.find(f =>f.month == 7)?.salesAmount) || 0,
                        parseInt(res.data.find(f =>f.month == 8)?.salesAmount) || 0,
                        parseInt(res.data.find(f =>f.month == 9)?.salesAmount) || 0,
                        parseInt(res.data.find(f =>f.month == 10)?.salesAmount) || 0,
                        parseInt(res.data.find(f =>f.month == 11)?.salesAmount) || 0,
                        parseInt(res.data.find(f =>f.month == 12)?.salesAmount) || 0,
                    ]
                },
                {
                    name: 'Monto cobrado',
                    data: [
                        parseInt(res.data.find(f =>f.month == 1)?.salesAmountCollected) || 0,
                        parseInt(res.data.find(f =>f.month == 2)?.salesAmountCollected) || 0,
                        parseInt(res.data.find(f =>f.month == 3)?.salesAmountCollected) || 0,
                        parseInt(res.data.find(f =>f.month == 4)?.salesAmountCollected) || 0,
                        parseInt(res.data.find(f =>f.month == 5)?.salesAmountCollected) || 0,
                        parseInt(res.data.find(f =>f.month == 6)?.salesAmountCollected) || 0,
                        parseInt(res.data.find(f =>f.month == 7)?.salesAmountCollected) || 0,
                        parseInt(res.data.find(f =>f.month == 8)?.salesAmountCollected) || 0,
                        parseInt(res.data.find(f =>f.month == 9)?.salesAmountCollected) || 0,
                        parseInt(res.data.find(f =>f.month == 10)?.salesAmountCollected) || 0,
                        parseInt(res.data.find(f =>f.month == 11)?.salesAmountCollected) || 0,
                        parseInt(res.data.find(f =>f.month == 12)?.salesAmountCollected) || 0,
                    ]
                }];
                
                setuseChart01Option((prevState) => ({
                    ...prevState,
                    ['series']: serie01
                }));                               
        });  
        businessDashboardService.GetBusinessDashboardData03(auth.user.token, 
            `${currentDateBegin.getFullYear()}-01-01`, `${currentDateBegin.getFullYear()}-12-31`,
            auth.user.companyId
            ).then(res => { setuseStateResume03(res.data);                
        });        
        
        businessDashboardService.GetBusinessDashboardData04(auth.user.token, 
            `${currentDateBegin.getFullYear()}-01-01`, `${currentDateBegin.getFullYear()}-12-31`,
            auth.user.companyId
            ).then(res => {
                //console.log(res);

                var catArray = res.data.map((item) => item.comercialName);

                //console.log(JSON.stringify(catArray));

                var seriesTemp = [];
                seriesTemp.push(
                {
                    name: '# Ventas',
                    data: res.data.map((item) => item.salesNumber)
                });
                seriesTemp.push(
                {
                    name: '$ Ventas',
                    data: res.data.map((item) => item.salesAmount)
                });
                seriesTemp.push(
                {
                    name: '# Pagado',
                    data: res.data.map((item) => item.salesAmountCollected)
                });
                seriesTemp.push(
                {
                    name: 'X Cobrar',
                    data: res.data.map((item) => item.salesAmountforCollect)
                });
        
                setuseChart02Option({
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Resumen de ventas y pagos',
                        align: 'left'
                    },
                    subtitle: {
                        text:
                            'Source: axify',
                        align: 'left'
                    },
                    xAxis: {
                        categories: catArray,
                        crosshair: true,
                        accessibility: {
                            description: 'Sucursales'
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        }
                    },
                    tooltip: {
                        valueSuffix: ' '
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: seriesTemp
                });

                // setuseChart02Option((prevState) => ({
                //     ...prevState,
                //     ['series']: seriesTemp
                // }));
        });     
        
    }
    ,[])
    
    return (
        <>
            <div className='row'>
                <div className='col'>
                    <h2>Información de este año</h2>
                </div>                
            </div>
            <div className='row'>
                
                <div className="col-xl-3">
                    <div className="card card-default  text-center">
                        <div className="card-header">                        
                            <div className="sub-title">
                                <span className="mr-1"># Ventas</span>                                                                 
                            </div>
                        </div>
                        <div className="card-body">
                            <h2>{useStateResumeAmmount.salesNumber}</h2>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3">
                    <div className="card card-default text-center">
                        <div className="card-header">                        
                            <div className="sub-title">
                                <span class="mr-1">$ Ventas</span>                                                                 
                            </div>
                        </div>
                        <div className="card-body">
                            <h2>{Utils.currencyFormat(useStateResumeAmmount.salesAmount)}</h2>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3">
                    <div className="card card-default text-center">
                        <div className="card-header">                        
                            <div className="sub-title">
                                <span className="mr-1">$ Cobrado</span>                                                                 
                            </div>
                        </div>
                        <div className="card-body">
                            <h2>{Utils.currencyFormat(useStateResumeAmmount.salesAmountCollected)}</h2>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3">
                    <div className="card card-default text-center">
                        <div className="card-header">                        
                            <div className="sub-title">
                                <span className="mr-1">$ Por cobrar</span>                                                                 
                            </div>
                        </div>
                        <div className="card-body">
                            <h2>{Utils.currencyFormat(useStateResumeAmmount.salesAmountforCollect)}</h2>
                        </div>
                    </div>
                </div>                                
            </div>
            <div className="row">
                <div className="col-xl-8">                    
                    {/* <!-- Income and Express --> */}
                    <div className="card card-default">
                        <div className="card-header">
                            
                        </div>
                        <div className="card-body">                            
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={useChart01Option}
                            />                        
                        </div>
                    </div>
                </div>
                <div className="col-xl-4">
                    {/* <!-- Top Customers --> */}
                    <div className="card card-default">
                      <div className="card-header">
                        <h2>Productos top</h2>
                      </div>
                      <div className="card-body">
                        <table className="table table-borderless table-thead-border">
                          <thead>
                            <tr>
                              <th>Producto</th>
                              <th className="text-right">#</th>
                              <th className="text-right">$</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                                useStateResume03 && useStateResume03?.map(item =>                                 
                                    <tr key={item.id}>
                                        <td>{item.productName}</td>
                                        <td>{item.salesNumber}</td>   
                                        <td style={{textAlign: 'right'}}>{Utils.currencyFormat(item.salesAmount)}</td>                                        
                                    </tr>                                    
                                )
                            }
                          </tbody>
                          <tfoot className="border-top">
                            {/* <tr>
                              <td><a href="#" class="text-uppercase">See All</a></td>
                            </tr> */}
                          </tfoot>
                        </table>
                      </div>
                    </div>

                  </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={useChart02Option}
                    />  
                </div>
            </div>
        </>
    );
};

export default BusinessDashboard;