import React, { useState, useEffect, useContext} from 'react';
import { useNavigate, NavLink} from 'react-router-dom';

import {ssoService} from '../../Services/ssoService';

import base64 from 'react-native-base64'

import { GlobalVariableContext } from '../../Context/globalVariablesContext';

import swal from 'sweetalert';

const ChangeYourPassword = () => {
    const navigate =  useNavigate();    

    const useGlobalVariableContextTmp = useContext(GlobalVariableContext);

    const [stateCompany, setstateCompany] = useState({
        businessName: ''
    });
    const [statePasswordA, setstatePasswordA] = useState('');
    const [statePasswordB, setstatePasswordB] = useState('');

    const [statedisabledBtnSave, setstatedisabledBtnSave] = useState(false);
    const [stateShowValidPassword, setstateShowValidPassword] = useState(false);

    useEffect(() => {
        //const refReserPass = sessionStorage.getItem('refChangePass');

        const refReserPass = useGlobalVariableContextTmp.refChangePass;

        if(refReserPass == undefined || refReserPass == ''){
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'No me proporcionaste un usuario para cambiar el password!',
                footer: 'Favor de revisar los datos'
            }).then((value) => {
                navigate('/login');
            });;
        } else {
            const decText = base64.decode(refReserPass); 

            ssoService.getbyEmail(decText).then(response => {
                setstateCompany(response.data)
            });
        }
    }, []);

    const onChangehandleChangePasswordA = (e) => {
        setstatePasswordA(e.target.value);        
    }

    const onChangehandleChangePasswordB = (e) => {
        setstatePasswordB(e.target.value);        
    }

    const onclickDOMEventBtnsubmit = (e) => {
        e.preventDefault();     

        const validtoSave = passwordABValidation();
        
        if (validtoSave) {            
            
            stateCompany.password = statePasswordB; 

            ssoService.putPassword(stateCompany.id, stateCompany).then(response => {
                if (response.isSuccess){
                    swal("Felicidades!", "Se ha cambiado tu contraseña correctamente!", "success")
                    .then((value) => {
                        navigate('/login', { replace: true });
                    });                    
                }
            });
        }
    }

    const passwordABValidation = () => {

        let validToUpdate = false;

        if (statePasswordA == statePasswordB) {
            setstatedisabledBtnSave(false);
            setstateShowValidPassword(false);
            validToUpdate = true;
        } else {
            setstatedisabledBtnSave(false);
            setstateShowValidPassword(true);
            validToUpdate = false;
        }

        return validToUpdate;
    }

    const onclickBtnHideAlertInvalidPass = ()=> {
        setstateShowValidPassword(false);
    }

    return(
        <>
            <div className="container d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}}>
                <div className="d-flex flex-column justify-content-between">
                    <div className="row justify-content-center mt-5">
                        <div className="col-md-12">
                            <div className="card card-default">
                                <div className="card-header">
                                    <div className="app-brand w-100 d-flex justify-content-center border-bottom-0">
                                        <a className="w-auto pl-0" href="/">
                                            <img src="./assets/images/logoaxify.jpg" alt="Mono" />                                            
                                        </a>
                                    </div>                                    
                                </div>    
                                <div className="card-body p-5" style={{textAlign: 'center'}}>
                                    <div>
                                        <h4 className="text-dark mb-5">Cambia tu password</h4>
                                        <h4 className="text-dark mb-5">{stateCompany.businessName}</h4>
                                    </div> 
                                    <div className="row">
                                        <div className="form-group col-md-12 mb-4">
                                            <input 
                                                type="password" 
                                                className="form-control input-lg" 
                                                id="PasswordA" 
                                                name="PasswordA"
                                                aria-describedby="nameHelp" 
                                                placeholder="Password" 
                                                autoComplete='off'  
                                                onChange={e => onChangehandleChangePasswordA(e)}
                                                />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-12 mb-4">
                                            <input 
                                                type="password" 
                                                className="form-control input-lg" 
                                                id="PasswordB" 
                                                name="PasswordB"
                                                aria-describedby="nameHelp" 
                                                placeholder="Confirmar password"
                                                autoComplete='off'  
                                                onChange={e => onChangehandleChangePasswordB(e)}
                                            />
                                        </div>    
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12">
                                        {                                        
                                                stateShowValidPassword ? 
                                                <div className="alert alert-warning" role="alert">
                                                    Password <strong>Incorrecto!</strong>.
                                                    <button className='btn' onClick={e => onclickBtnHideAlertInvalidPass(e)}>X</button>
                                                </div>                     
                                                : null                                        
                                        }    
                                        </div>
                                    </div>                                        
                                    <div className="row">
                                        <div className="col">
                                            <button className="btn btn-primary btn-pill"  style={{fontSize:"x-small"}}
                                                disabled={statedisabledBtnSave}
                                                onClick={e => onclickDOMEventBtnsubmit(e)}>Aplicar</button>
                                            
                                        </div>
                                        <div className='col'>
                                            <NavLink
                                                className="btn btn-primary btn-pill" style={{fontSize:"x-small"}}
                                                aria-current="page"
                                                to="/login"
                                                >
                                                    Regresar            
                                            </NavLink>                                     
                                        </div>                                    
                                    </div>                        
                                </div>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangeYourPassword;