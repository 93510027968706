import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { businessService } from '../../Services/businessService';
import { businessProductService } from '../../Services/businessProductService';
import { paymentFrecuencyService } from '../../Services/paymentFrecuencyService';

import CommerceProductAddEditUI from './CommerceProductAddEditUI';

import { useAuth } from '../../Context/auth';

const CommerceAddEdit = () => {
    const auth = useAuth();
    const { id } = useParams();

    const isAddMode = !id;    
    const navigate =  useNavigate();       

    const [stateBusiness, setStateBusiness] = useState([]);   
    const [stateProductBusiness, setStateProductBusiness] = useState({
        id : 0,
        businessId : 0,
        name : '',
        description : '',
        sku : '',
        salePrice : 0,
        percentDeposit: 0,
        fracuencyPay: 0,
        planPaymentDuration: 0,
        ammountoCancel: 0
    });           

    const [statePaymentFrecuency, setStatePaymentFrecuency] = useState([]);

    const validKeyForPayment = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
      ];
    const validKeyForPaymentForPerDep = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "."
      ];

    useEffect(() => {
        businessService.getAllByCompanyId(auth.user.token, auth.user.companyId).then( res => {            
            setStateBusiness(res.data);            
        });    

        paymentFrecuencyService.getAll(auth.user.token).then( response => {                  
            setStatePaymentFrecuency(response.data);
        }); 

        if (!isAddMode) {
            businessProductService.getById(auth.user.token, id).then(res => {                     
                setStateProductBusiness(res.data);                    
            });      
        }
    }, []);

    const onClickbtnCancel = (e) => {
        e.preventDefault();

        try {
          navigate('/commerceProductList', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }
   
    const handleChange = (event) => {
        const {name, value} = event.target;
        setStateProductBusiness((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const onClickbtnSaveUpdate = () => {
        const validateResult = validateFormData();       

        if(validateResult) {
            stateProductBusiness.salePrice = +stateProductBusiness.salePrice.toString().replace(',','');
            stateProductBusiness.percentDeposit = +stateProductBusiness.percentDeposit;
            stateProductBusiness.fracuencyPay = +stateProductBusiness.fracuencyPay;
            stateProductBusiness.planPaymentDuration = +stateProductBusiness.planPaymentDuration;
            stateProductBusiness.ammountoCancel = +stateProductBusiness.ammountoCancel.toString().replace(',','');
            if (isAddMode) {               
                businessProductService.post(auth.user.token, stateProductBusiness);
            } else {
                // stateProductBusiness.salePrice = +stateProductBusiness.salePrice.toString().replace(',','');
                // stateProductBusiness.ammountoCancel = +stateProductBusiness.ammountoCancel.toString().replace(',','');
                console.log(JSON.stringify(stateProductBusiness));
                businessProductService.put(auth.user.token, stateProductBusiness.id, stateProductBusiness);
            }    
            // Redireccionar
            navigate('/commerceProductList', { replace: true });
        }     
    }    

    const validateFormData = () => {
        const validData = true;

        if (stateProductBusiness.businessId < 0 || stateProductBusiness.businessId == '') {
            validData = false;
        }
        if (stateProductBusiness.name.lenght == 0 || stateProductBusiness.name == '') {
            validData = false;
        }
        if (stateProductBusiness.description.lenght == 0 || stateProductBusiness.description == '') {
            validData = false;
        }
        if (stateProductBusiness.sku.lenght == 0 || stateProductBusiness.sku == '') {
            validData = false;
        }
        if (stateProductBusiness.salePrice <= 0 || stateProductBusiness.salePrice == '') {
            validData = false;
        }
        if (stateProductBusiness.percentDeposit == 0 || stateProductBusiness.percentDeposit == '') {
            validData = false;            
        }
        if (stateProductBusiness.fracuencyPay == 0 || stateProductBusiness.fracuencyPay == '') {
            validData = false;            
        }
        if (stateProductBusiness.planPaymentDuration == 0 || stateProductBusiness.planPaymentDuration == '') {
            validData = false;          
        }
        if (stateProductBusiness.ammountoCancel == 0 || stateProductBusiness.ammountoCancel == '') {
            validData = false;
        }

        return validData;
    }

    return (
        <>
            <CommerceProductAddEditUI                
                auth={auth}
                isAddMode={isAddMode}                
                stateBusiness={stateBusiness}         
                stateProductBusiness={stateProductBusiness}      
                statePaymentFrecuency={statePaymentFrecuency}  
                validKeyForPayment={validKeyForPayment}       
                validKeyForPaymentForPerDep={validKeyForPaymentForPerDep}                 
                onClickbtnCancel={onClickbtnCancel}   
                handleChange={handleChange}
                onClickbtnSaveUpdate={onClickbtnSaveUpdate}   
                
            ></CommerceProductAddEditUI>            
        </>
    )
}

export default CommerceAddEdit;