import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';

import "./popupPlanSimulator.css";

import { cxcService } from '../../Services/Cxc/cxcService';

import { Utils } from '../../Utils/utils'

const PopUpPlanSimulator = ({ ventaid, datebegin, dateend, ammount, frecuency, togglePop, setStatePlanFromPopUp}) => {       
    
    const [statePlan, setStatePlan] = useState([]);

    useEffect(() => {            
        setTimeout(() => {
            if(ventaid>0){
                cxcService.getByVentaId(ventaid)
                .then(res => {
                    setStatePlan(res); 
                })
                .catch(error => {
                    setStatePlan([]);
                });     
            } else {
                cxcService.getPlan(ventaid, datebegin, dateend, ammount, frecuency)
                .then(res => {
                    setStatePlan(res); 
                })
                .catch(error => {
                    setStatePlan([]);
                });     
            }           
        }, 1000);
    }, []);

    const onClickDomEventbtnSavefrompopup = (e) => {
        setStatePlanFromPopUp(statePlan);
        togglePop();        
    }

    const onClickDomEventbtnClosefrompopup = (e) => {
        togglePop();
    }


    return (
        <div className='popup'>           
            <div className='popup_inner'>
                <div className="popup_header">  
                    { ventaid === 0 ? 'Simulador de' : ''} plan de pagos
                </div>
                <div className="popup_body">          
                    <br></br>
                    <div className='divscroll'>
                        <table className="table">
                            <thead>
                                <tr>
                                <th scope="col">Vencimiento</th>
                                <th scope="col">Importe</th>                            
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    statePlan && statePlan?.map((pg, index) => 
                                        <tr key={index}>
                                            <td>
                                                <Moment format="DD/MM/YYYY">
                                                    {pg.fechaVencimiento}
                                                </Moment>                                                
                                            </td>
                                            <td>{Utils.currencyFormat(pg.valor)}</td>   
                                        </tr>                                
                                    )
                                }
                            </tbody>
                        </table>
                    </div>           
                </div>
                <div className="sectionBtnClose">
                    { ventaid === 0 ? <button className="btn btn-primary" onClick={onClickDomEventbtnSavefrompopup}>Grabar</button>: null }
                    | 
                    <button style={{backgroundColor:'#FD9913', color:'white'}} className="btn btn-warning" onClick={onClickDomEventbtnClosefrompopup}>Cerrar</button>
                </div>
            </div>
        </div>         
    )
}

export default PopUpPlanSimulator;