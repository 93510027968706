import React, {useState, useEffect} from 'react';
//import { useNavigate } from 'react-router-dom';

import ProductByCommerceQRUI from './ProductByCommerceQRUI';

import { businessService } from '../../Services/businessService';
import { businessProductService } from '../../Services/businessProductService';

import { useAuth }from '../../Context/auth'

import base64 from 'react-native-base64'

//const API_BASE_BUYER_URL = process.env.REACT_APP_SPA_BUYER_URL;
import Config from "../../config.json";

import { Utils } from '../../Utils/utils';

const ProductByCommerceQR = () => {

    const auth = useAuth(); 
    
    //const navigate =  useNavigate(); 

    const [stateBusiness, setStateBusiness] = useState([]);
    const [stateSelectedBusinessId, setStateSelectedBusinessId] = useState([]);
    const [stateBusinessProduct, setStateBusinessProduct] = useState([]);

    const [stateSku, setStateSku] = useState("");

    //const [stateBP, setStateBP] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            businessService.getAllByCompanyId(auth?.user.token, auth?.user.companyId).then( res => {    
                let newList = [];
                auth.user.commerceList.map(item =>  {
                    const index = res.data.findIndex((x) => x.id === item);
                    if(index != -1) {
                        newList = [...newList, res.data[index]];
                    }
                });               
                setStateBusiness(newList);                             
            });             
          }, 1000);
    }, []);    

    const onClickDOMEvent_btnFiltrar = (e) => {
        e.preventDefault();

        if(stateSku.length == 0){
            businessProductService.getByBusinessId(auth?.user.token, stateSelectedBusinessId).then(res => {           
                const arrayTmp = [];
                if (res.data){
                    res.data.map((item) =>{                     

                        let salePriceTmp = Utils.roundToTwo(item.salePrice);
                        const salePriceAmountapart = Utils.roundToTwo(item.salePrice*(item.percentDeposit/100));
                        
                        //const refApartado = `Ap|${item.businessId}|${item.id}|0|${Math.round(item.salePrice)}|1|${Math.round(item.salePrice*(item.percentDeposit/100))}`;                    
                        const refApartado = `Ap|${item.businessId}|${item.id}|0|${salePriceTmp}|1|${salePriceAmountapart}`;  
                        
                        const encText = base64.encode(refApartado);         

                        const ciphertext = `${Config.REACT_APP_SPA_BUYER_URL}?ref=${encText}`;      
                        
                        console.log(refApartado);
                        console.log(ciphertext);

                        const objItem = {
                            businessId:item.businessId,
                            id:item.id,
                            name: item.name,
                            sku: item.sku,
                            salePrice: item.salePrice,
                            encryptText: ciphertext
                        }                               
                        arrayTmp.push(objItem);    
                    })
                }            
                setStateBusinessProduct(arrayTmp);            
            });
        } else {
            businessProductService.getBySku(auth?.user.token, stateSelectedBusinessId, stateSku).then(res => {           
                const arrayTmp = [];
                if (res.data){                  
                    
                    let salePriceTmp = Utils.roundToTwo(res.data.salePrice);
                    const salePriceAmountapart = Utils.roundToTwo(res.data.salePrice*(item.percentDeposit/100));

                    //const refApartado = `Ap|${res.data.businessId}|${res.data.id}|0|${Math.round(res.data.salePrice)}|1|${Math.round(res.data.salePrice*(item.percentDeposit/100))}`;                    
                    const refApartado = `Ap|${res.data.businessId}|${res.data.id}|0|${salePriceTmp}|1|${salePriceAmountapart}`;                    
                    
                    const encText = base64.encode(refApartado);         

                    const ciphertext = `${Config.REACT_APP_SPA_BUYER_URL}?ref=${encText}`;      
                    
                    console.log(refApartado);
                    console.log(ciphertext);

                    const objItem = {
                        businessId: res.data.businessId,
                        id: res.data.id,
                        name: res.data.name,
                        sku: res.data.sku,
                        salePrice: res.data.salePrice,
                        encryptText: ciphertext
                    }                               
                    arrayTmp.push(objItem);    
                   
                }            
                setStateBusinessProduct(arrayTmp);            
            });

        }
    }

    const onChangeDOMEvent_selectbusiness = (e) => {
        setStateSelectedBusinessId(e.target.value);
    }

    const onChangeDOMEvent_inputSku = (e) => {
        setStateSku(e.target.value);
    }

    return (
        <ProductByCommerceQRUI 
            stateBusinessProduct={stateBusinessProduct} 
            stateBusiness= {stateBusiness}            
            stateSku={stateSku}
            onClickDOMEvent_btnFiltrar= {onClickDOMEvent_btnFiltrar}
            onChangeDOMEvent_selectbusiness= {onChangeDOMEvent_selectbusiness}   
            onChangeDOMEvent_inputSku={onChangeDOMEvent_inputSku}         
        > 
        </ProductByCommerceQRUI>
    );
}

export default ProductByCommerceQR;