import axios from 'axios';
import Config from "../config.json";

///const API_BASE_URL = `${process.env.REACT_APP_APIREST_BASE_URL}/v1/Client`;
const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/Client`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
};    

const getAllBycompanyId = async (auth_token, id) => { 
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetAllByCompanyIdAsync/${id}`, CONFIG_REQUEST);
    const data = await response.data;

    return data;
}

const getById = async (auth_token, id) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetAsync/${id}`, CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}

const getByEmail = async (auth_token, strEmail) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetByEmailAsync/${strEmail}`, CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}

const post = async (auth_token, values) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.post(  `${API_BASE_URL}/InsertAsync`, values , CONFIG_REQUEST);
    const data = await response.data;   

    return data;
}

const put  = async (auth_token, id, values) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.put( `${API_BASE_URL}/UpdateAsync/${id}`, values , CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}

const _delete  = async (auth_token, id) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.delete( `${API_BASE_URL}/DeleteAsync/${id}`, CONFIG_REQUEST );
    const data = await response.data;       

    return data;
}

export const clientService = {
    getAllBycompanyId,
    getById,
    getByEmail,
    put,
    post,
    delete: _delete
}