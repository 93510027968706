import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import './Login.css';

const LoginUI = (props) => {   
 

  return (    
    <>        

        <div className="container d-flex align-items-center justify-content-center" style={{minheight: "100vh"}}>
          <div className="d-flex flex-column justify-content-between">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-10">
                <div className="card card-default mb-0">
                  <div className="card-header pb-0">
                    <div className="app-brand w-100 d-flex justify-content-center border-bottom-0">
                      <a className="w-auto pl-0" href="/">
                        <img src="./assets/images/logoaxify.jpg" alt="Mono" />
                        <span className="brand-name text-dark"></span>
                      </a>
                    </div>
                  </div>
                  <div className="card-body px-5 pb-5 pt-0">

                    <h4 className="text-dark mb-6 text-center">Iniciar sesion</h4>
                    {
                      props.stateShowValidUser ? 
                      <div className="alert alert-warning" role="alert">
                        Usuario o Password <strong>Incorrecto!</strong>.
                        <button className='btn' onClick={e => props.onclickBtnHideAlertUserNotExist(e)}>X</button>
                      </div>                     
                      : null
                    }
                    <form action="/">
                      <div className="row">
                        <div className="form-group col-md-12 mb-4">
                          <input type="email" className="form-control input-lg" 
                            id="email" 
                            name="email"
                            aria-describedby="emailHelp"
                            placeholder="email"            
                            autoComplete='off'                
                            onChange={props.handleChange}
                        />
                        </div>
                        <div className="form-group col-md-12 ">
                          <input type="password" className="form-control input-lg" 
                                id="password" 
                                name="password"
                                placeholder="Password"          
                                autoComplete='off'                                                                              
                                onChange={props.handleChange}
                            />
                        </div>
                        <div className="col-md-12">
                          <div className="d-flex justify-content-between mb-3">

                            {/* <div className="custom-control custom-checkbox mr-3 mb-3">
                              <input type="checkbox" className="custom-control-input" id="customCheck2" />
                              <label className="custom-control-label" for="customCheck2">Remember me</label>
                            </div> */}

                          </div>
                          <div className='row'>     
                            <div className='col'></div>                       
                            <div className='col'>
                              <button type="submit" 
                                  className="btn btn-primary btn-pill mb-4"
                                  disabled={!props.stateEnableBtnLogIn}
                                  onClick={e => props.onClickDOMEvent_btnI(e)}
                                >Ingresar</button>                                
                            </div>                              
                            <div className='col'></div>                       
                          </div>
                          <div className='row'>
                            <div className='col'>
                              { props.hiddenSpinner1 &&
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div>
                              }
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col'>                              
                              <NavLink className="btn btn-primary btn-pill" style={{fontSize:"x-small"}}                             
                                  aria-current="page"
                                  to="/resetYourPassword"
                                  >
                                    Olvidé mi password      
                              </NavLink> 
                            </div>
                            <div className='col'>
                                <NavLink                                    
                                    className="btn btn-primary btn-pill" style={{fontSize:"x-small"}}
                                    aria-current="page"
                                    to="/commerceRegister"
                                    >
                                      No tengo cuenta            
                                </NavLink>                                
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>        
    </>
  );
}

export default LoginUI;