import React, { useState } from 'react';

import { useAuth } from '../../Context/auth'
import "./popupAddPay.css";
import { cxcPaymentService } from '../../Services/Cxc/cxcPaymentService';

const PopUpAddPay = ({ cxcid, togglePop}) => {       
    const auth = useAuth(); 

    const [stateDatePay, setStateDatePay] = useState(new Date());
    const [stateNote, setStateNote] = useState('');
    const [stateAmmount, setStateAmmount] = useState(0);

    const onChangeDOMEvent_inputdatepay = (e) => {
        setStateDatePay(e.target.value);
    }

    const onChangeDOMEvent_inputnote = (e) => {
        setStateNote(e.target.value);
    }

    const onChangeDOMEvent_inputammount = (e) => {
        setStateAmmount(e.target.value);
    }

    const onClickDomEventbtnSavefrompopup = (e) => {
        e.preventDefault();

        const value = {
            Id: 0,
            CxcId: cxcid,
            FechaExpedicion: stateDatePay, 
            Monto: stateAmmount,
            Notas: stateNote,
            Referencia01: '',
            SSOId: auth.user.id
        };
        
        cxcPaymentService.post(value).then(res => {                                
            console.log(res);
        });
        togglePop('Refresh');
    }

    const onClickDomEventbtnClosefrompopup = (e) => {
        togglePop();
    }
   

    return (
        <div className='popup'>           
            <div className='popup_inner'>
                <div className="popup_header">  
                    Agregar pago
                </div>
                <div className="popup_body">                              
                        <div className="row g-3">
                            <div className="col-sm-12">
                                <label htmlFor="datePay" className="form-label">Fecha</label>
                                <input type="text" className="form-control" placeholder="" required="" 
                                    id="datePay"
                                    name="datePay"                                    
                                    onChange={onChangeDOMEvent_inputdatepay.bind(this)}                                    
                                />                                
                            </div>
                        </div>                        
                        <div className='row g-3'>
                            <div className="col-sm-12">
                                <label htmlFor="notes" className="form-label">Notas</label>
                                <input type="text" className="form-control" placeholder="" required="" 
                                    id="notes"
                                    name="notes"                                    
                                    onChange={onChangeDOMEvent_inputnote.bind(this)}
                                />                                
                            </div>
                        </div>
                        
                        <div className="row g-3">
                            <div className="col-sm-12">
                                <label htmlFor="ammount" className="form-label">Importe</label>
                                <input type="number" className="form-control" placeholder="" required="" 
                                    id="ammount"
                                    name="ammount"                                    
                                    onChange={onChangeDOMEvent_inputammount.bind(this)}
                                />                                
                            </div>
                        </div>
                    
                </div>
                <div className="sectionBtnClose">
                    <button className="btn btn-primary" onClick={onClickDomEventbtnSavefrompopup}>Grabar</button>
                    | 
                    <button className="btn btn-warning" onClick={onClickDomEventbtnClosefrompopup}>Cerrar</button>
                </div>
            </div>
        </div>         
    )
}

export default PopUpAddPay;