import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/auth'

import { paymentgatewayService } from '../../Services/paymentGatewayService';
import { businessService } from '../../Services/businessService';

import { AiOutlineDislike, AiOutlineLike } from 'react-icons/ai';

const Home = () => {    
  const auth = useAuth();  
  const navigate =  useNavigate(); 

  const [stateNumPaymentGateway, setstateNumPaymentGateway] = useState(0);
  const [stateNumBussines, setstateNumBusisness] = useState(0);

  useEffect(() => {    
    paymentgatewayService.getAllByCompanyId(auth.user.token, auth.user.companyId).then( res => {                  
      setstateNumPaymentGateway(res.data.length);            
    });
    businessService.getAllByCompanyId(auth.user.token, auth.user.companyId).then( res => {                  
      setstateNumBusisness(res.data.length);            
    });
    navigate('/businessDashboard', { replace: true });
  }, []);

  return (    
    <>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="container">
        <div className="row">
          <div className="col"></div>          
              {
                stateNumPaymentGateway === 0 || stateNumBussines === 0 ?
                <div className="col" style={{textAlign: 'center',fontSize: '25px'}}>
                  Estas listo para iniciar?
                  <ul>
                    <li>Paso 1 Pasarelas de pago: {stateNumPaymentGateway} | { stateNumPaymentGateway > 0 ? <AiOutlineLike style={{fontSize: '50px'}}/> : <AiOutlineDislike style={{fontSize: '50px'}}/>}</li>
                    <li>Paso 2 Comercio: {stateNumBussines} | { stateNumBussines > 0 ? <AiOutlineLike style={{fontSize: '50px'}}/> : <AiOutlineDislike style={{fontSize: '50px'}}/>}</li>
                  </ul>      
                </div>
                : null
              }
          <div className="col"></div>          
        </div>
      </div>


      
    </>
  );
}

export default Home;