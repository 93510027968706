const currencyFormat = (num) => {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const roundToTwo = (num) => {
    return +(Math.round(num + 'e+2') + 'e-2');
}

const OperationTypeEnum = {
    PullApart: '1',
    PaymentA: '2',
    PaymentB: '3',
}

export const Utils = {
    currencyFormat,
    OperationTypeEnum,
    roundToTwo
}