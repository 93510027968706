import React, { useRef, useEffect, useState }from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from "react-to-print";
import ComponentToPrintA from './ComponentToPrintA';
import { useAuth }from '../../Context/auth';

import { Utils } from '../../Utils/utils';

import { salesService } from '../../Services/salesService';
import { businessService } from '../../Services/businessService';

const PosPymeTicketA = () => {
    const auth = useAuth(); 
    const { id } = useParams();
    const componentRef = useRef();
    const navigate =  useNavigate(); 

    const [stateSaleData, setstateSaleData] = useState({
        companyName: '',
        saleDate: new Date,
        reference: 0,
        businessName: '',
        cashier: '',
        ammount: 0,
        commision:0,
        total: 0
    });

    useEffect(() => {
        
        GetInfo();

    }, []);
    
    const GetInfo = async () => {
        const responseSale = await salesService.getById(auth.user.token, id);
        
        const businessId = +responseSale.data.businessId;
        const responseBusiness = await businessService.getById(auth.user.token, businessId);
        
        setstateSaleData({
                companyName: auth.user.companyName,
                saleDate: responseSale.data.creationDate,
                reference: id,
                businessName: responseBusiness.data.businessName,
                cashier: auth.user.userName,
                ammount: Utils.currencyFormat(responseSale.data.ammountPre),
                commision: Utils.currencyFormat(0),
                total: Utils.currencyFormat(responseSale.data.ammountPre)
            });
             
    }

    const onclickBackPullApart = (e) => {
        e.preventDefault();

        navigate('/ServicePaymentA', { replace: true });
    }

    return (
        <>        
            <ReactToPrint
                trigger={() => <button type="button" className="btn btn-primary btn-md">Imprimir</button>}
                content={() => componentRef.current}
            />
            <button type="button" className="btn btn-primary btn-md" onClick={onclickBackPullApart}>Regresar a Apartados</button>}
            <ComponentToPrintA ref={componentRef} stateSaleData={JSON.stringify(stateSaleData)} />
        </>
    )
};

export default PosPymeTicketA;