import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import PopUpPlanSimulator from './popupPlanSimulator';

import { businessService } from '../../Services/businessService';
import { businessProductService } from '../../Services/businessProductService';
import { clientService } from '../../Services/clientService';
import { salesService } from '../../Services/salesService';
import { cxcService } from '../../Services/Cxc/cxcService';

import { useAuth } from '../../Context/auth'

const SalesAddEdit = () => {
    const auth = useAuth(); 

    const { id } = useParams();
    const isAddMode = !id;    
    const navigate =  useNavigate(); 

    const [stateBusiness, setStateBusiness] = useState([]);
    const [stateClient, setStateClient] = useState([]);
    const [stateBalance, setStateBalance] = useState(0);
    const [statefrecuency, setStateFrecuency] = useState(0);
    const [stateSale, setStateSale] = useState({
        id:0,
        businessId: 0,
		saleDate: Date,
		description: '',
		details:'',
		clientId:0,		
		ammount:0,
		ammountPre:0,
		limitDatePay: Date,
		vigencyDateToLC: Date,
		sSOId: auth.user.id,
        companyId: auth.user.companyId,
        productId: 0,
        sku:''
    });

    const [statePlan, setStatePlan] = useState([]);
    const [stateSeenPopup, setstateSeenPopup] = useState(false);

    const [stateBusinessProduct, setStateBusinessProduct] = useState([]);
    
    // // Pass the useFormik() hook initial form values and a submit function that will
    // // be called when the form is submitted
    // const formik = useFormik({
    // initialValues: {
	// 	businessId: 0,
	// 	saleDate: Date,
	// 	description: '',
	// 	details:'',
	// 	clientId:0,		
	// 	ammount:0,
	// 	ammountPre:0,
	// 	limitDatePay: Date,
	// 	vigencyDateToLC: Date,
	// 	sSOId: auth.user.id,
    //     companyId: auth.user.companyId,
    //     productId: 0,
    //     sku:''
    // },
    // validationSchema: Yup.object({
    //     description: Yup.string()
    //         .min(4, 'El nombre no puede ser menor a 4 caracteres')
    //         .required('La descripcion es obligatorio')     
    // }),
    // onSubmit: async values => {        
    //     if (isAddMode) {           
    //         let localStatePlan = statePlan;            
    //         const pasa =  stateBusiness.filter(f => f.id === +values.businessId);                    
    //         //console.log(JSON.stringify(values));
    //         const sale = await salesService.post(values);
    //         localStatePlan.map(item => {
    //             item.ventaId = sale.data.id;
    //             item.pasarelaPagoId = pasa[0].paymentGatewayId;
    //             item.status = true;
    //         });                
    //         //console.log(JSON.stringify(localStatePlan));
    //         await cxcService.postList(localStatePlan);
    //     } else {
    //         await salesService.put(values.id, values);
    //     }     

    //     // Redireccionar
    //     setTimeout(() => {
    //         navigate('/saleslist', { replace: true });    
    //     }, 1500);        
    // }, });

    useEffect(() => {
        // set a clean up flag
        let isSubscribed = true;
        
        businessService.getAllByCompanyId(auth.user.token, auth.user.companyId).then( res => {     
            setStateBusiness(res.data);
        });    

        clientService.getAllBycompanyId(auth.user.token, auth.user.companyId).then( res => {            
            setStateClient(res.data);
        });       


        if (!isAddMode) {
            salesService.getById(id).then(response => {              
                setStateSale(response.data);                
            });      
        }

        // cancel subscription to useEffect
        return () => (isSubscribed = false)
    }, []);

    const onChangehandleChange = (event) => {
        const {name, value} = event.target;
        setStateSale((prevState) => ({...prevState, [name]: value }));        
    }

    const onClickbtnSaveUpdate = async () => {
        if (isAddMode) {           
            let localStatePlan = statePlan;            
            const pasa =  stateBusiness.filter(f => f.id === +stateSale.businessId);                    
            //console.log(JSON.stringify(values));
            const sale = await salesService.post(stateSale);
            localStatePlan.map(item => {
                item.ventaId = sale.data.id;
                item.pasarelaPagoId = pasa[0].paymentGatewayId;
                item.status = true;
            });                
            //console.log(JSON.stringify(localStatePlan));
            await cxcService.postList(localStatePlan);
        } else {
            await salesService.put(stateSale.id, stateSale);
        }     

        // Redireccionar
        setTimeout(() => {
            navigate('/saleslist', { replace: true });    
        }, 1500);        
    }

    const onClickbtnCancel = (e) => {
        e.preventDefault();

        try {
          navigate('/saleslist', { replace: true });
        } catch(e) {
            console.log('error: ' + e);
        }    
    }

    const onClickbtnseePlanSimulator = async (e) => {
        e.preventDefault();        
        togglePop();
    }

    const onchange_selectFrecuency = (e) => {
        setStateFrecuency(+e.target.value)
    }

    const onBlur_inputamount = (e) => {
        e.preventDefault();        
        sacarSaldo();
    }

    const onBlur_inputpreamount = (e) => {
        e.preventDefault();
        sacarSaldo();
    }

    const sacarSaldo = () => {
        setStateBalance( stateSale.ammount - stateSale.ammountPre);
    }

    const togglePop = () => {
        setstateSeenPopup(!stateSeenPopup);    
    };

    const setStatePlanFromPopUp = (plan) => {
        setStatePlan(plan);
    };

    const onBlurSelectBusinessId = (event)=> {        
        businessProductService.getByBusinessId(auth.user.token, event.target.value).then(res => {                    
            setStateBusinessProduct(res.data);
        });
    }

    return (
        <>                     
            <div className="container">
                
                    <div className='row'>
                        <div className='col d-grid gap-2 d-md-flex justify-content-md-begin'>
                        <h2>{ isAddMode ? 'Crear venta' : 'Editar venta' }</h2>
                        </div>
                    </div>
                    <br></br>
                    <div className='row'>
                        <hr></hr>
                    </div>
                    <br></br>                    
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='container rounded border border-primary'>
                                <div className="row g-3">
                                    <div className="col-sm-6">
                                        <label htmlFor="id" className="form-label">Id</label>
                                        <input className="form-control" placeholder="" required="" disabled
                                            id="id"
                                            name="id"                                            
                                            type="text"
                                            onChange={onChangehandleChange}
                                            value={stateSale.id}
                                        />                                                
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="businessId" className="form-label">Comercio</label>
                                        <select
                                            className="form-control"
                                            id="businessId"
                                            name="businessId"                                            
                                            onChange={onChangehandleChange}  
                                            onBlur={onBlurSelectBusinessId}                                                                            
                                            value={stateSale.businessId}
                                        > 
                                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                                        {
                                            stateBusiness.map((item, index) => <option key={index} value={item.id}>{item.businessName}</option>)
                                        }
                                        </select>
                                    </div>
                                </div>   
                                <div className='row g3'>
                                    <div className="col-sm-6">
                                        <label htmlFor="description" className="form-label">Descripcion</label>
                                        <input className="form-control" placeholder="" required="" 
                                            id="description"
                                            name="description"                                            
                                            type="text"
                                            onChange={onChangehandleChange}
                                            value={stateSale.description}
                                        />
                                        
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="details" className="form-label">Detalle</label>
                                        <input className="form-control" placeholder="" required="" 
                                            id="details"
                                            name="details"                                            
                                            type="text"
                                            onChange={onChangehandleChange}                                
                                            value={stateSale.details}  
                                        />
                                        
                                    </div>
                                </div>     
                                <div className='row g-3'>
                                    <div className="col-sm-6">                                            
                                        <label htmlFor="clientId" className="form-label">Cliente</label>
                                        <select
                                            className="form-control"
                                            id="clientId"
                                            name="clientId"                                            
                                            onChange={onChangehandleChange}                                                                              
                                            value={stateSale.clientId}
                                        > 
                                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                                        {
                                            stateClient.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)
                                        }
                                        </select>                                            
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="productId" className="form-label">Producto</label>
                                        <select
                                            className="form-control"
                                            id="productId"
                                            name="productId"                                            
                                            onChange={onChangehandleChange}                                                      
                                            value={stateSale.productId}
                                        > 
                                        <option value="0" seleted="true">Selecciona una opcion</option>                 
                                        {
                                            stateBusinessProduct.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)
                                        }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='container rounded border border-primary'>
                                <div className='row g-3'>
                                    <div className='col-sm-6'>
                                        <label htmlFor="saleDate" className="form-label">Fecha de venta</label>
                                        <input className="form-control" placeholder="" required="" 
                                            id="saleDate"
                                            name="saleDate"  
                                            type="date"
                                            onChange={onChangehandleChange}
                                            value={stateSale.saleDate}
                                        />
                                        
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="limitDatePay" className="form-label">Fecha de pago hasta</label>
                                        <input className="form-control" placeholder="" required="" 
                                            id="limitDatePay"
                                            name="limitDatePay"                                            
                                            type="date"
                                            onChange={onChangehandleChange}
                                            value={stateSale.limitDatePay}
                                        />
                                        
                                    </div>
                                </div>   
                                <div className='row g-3'>
                                    <div className='col-sm-6'>
                                        <label htmlFor="frecuency" className="form-label">Frecuencia</label>
                                        <select
                                            className="form-control"
                                            id="frecuency"
                                            name="frecuency"     
                                            onChange={ e => onchange_selectFrecuency(e) }                                               
                                        > 
                                        <option value="0" seleted="true">Selecciona una opcion</option>
                                        <option value="7" seleted="true">Cada 7 dias</option>
                                        <option value="15" seleted="true">Cada 15 dias</option>
                                        <option value="30" seleted="true">Cada 30 dias</option>
                                        </select>
                                    </div>
                                    <div className='col-sm-6'>
                                        <label htmlFor="ammount" className="form-label">Importe</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text">$</span>
                                            <input className="form-control" placeholder="" required="" 
                                                id="ammount"
                                                name="ammount"                                            
                                                type="number"
                                                onChange={onChangehandleChange}
                                                onBlur={ e => onBlur_inputamount(e) }
                                                value={stateSale.ammount}
                                                style={{ textAlign: 'right' }}
                                            />
                                            <span className="input-group-text">.00</span>
                                        </div>
                                        
                                    </div>                                            
                                </div>    
                                <div className='row g-3'>
                                    <div className='col-sm-6'>

                                    </div>
                                    <div className='col-sm-6'>
                                        <label htmlFor="ammountPre" className="form-label">Importe de adelanto</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text">$</span>
                                            <input className="form-control" placeholder="" required="" 
                                                id="ammountPre"
                                                name="ammountPre"                                            
                                                type="number"
                                                onChange={onChangehandleChange}       
                                                onBlur={ e => onBlur_inputpreamount(e) }                         
                                                value={stateSale.ammountPre}  
                                                style={{ textAlign: 'right' }}
                                            />
                                            <span className="input-group-text">.00</span>
                                        </div>
                                        
                                    </div>
                                </div>    
                                <div className='row g-3'>
                                    <div className='col-sm-6'>

                                    </div>
                                    <div className='col-sm-6'>
                                        <label htmlFor="ammountPre" className="form-label">Saldo</label>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text">$</span>
                                            <input type="number" className="form-control" value={ stateBalance } style={{ textAlign: 'right' }} disabled/>    
                                            <span className="input-group-text">.00</span>
                                        </div>
                                    </div>
                                </div>    
                                <div className='row g-3'>
                                    <div className='col-sm-6'>
                                        {/* <label htmlFor="vigencyDateToLC" className="form-label">Fecha vigencia liga de cobro</label>
                                        <input className="form-control" placeholder="" required="" 
                                            id="vigencyDateToLC"
                                            name="vigencyDateToLC"                                            
                                            type="date"
                                            onChange={formik.handleChange}                                
                                            value={stateSale.vigencyDateToLC}  
                                        />
                                        {formik.errors.vigencyDateToLC ? <div className="invalid-feedback">{formik.errors.vigencyDateToLC}</div> : null} */}
                                    </div>
                                    <div className='col-sm-6' style={{ marginTop: "45px"}}>
                                        <button className="w-100 btn btn-primary"
                                            onClick={e => onClickbtnseePlanSimulator(e)}>                                                   
                                            Simulador 
                                        </button>                                                
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className='row'>
                        <hr></hr>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <button style={{backgroundColor:'#FD9913', color:'white'}} type="button" 
                                className="w-100 btn btn-success" onClick={onClickbtnSaveUpdate}>
                                    {isAddMode ? ' Agregar venta ' : ' Actualizar venta '}
                            </button>    
                        </div>
                        <div className='col'>
                            <button style={{backgroundColor:'#0D6EFD', color:'white'}} type="button" 
                                className="w-100 btn btn-secondary" onClick={e => onClickbtnCancel(e)}>Cancelar</button>                        
                        </div>    
                    </div>                            
                    
                    {stateSeenPopup ? <div> <PopUpPlanSimulator                                 
                                ventaid={ 0 }                            
                                datebegin={stateSale.saleDate}
                                dateend={stateSale.limitDatePay}
                                ammount={stateSale.ammount - stateSale.ammountPre}
                                frecuency={statefrecuency}
                                togglePop={ togglePop } 
                                setStatePlanFromPopUp = { setStatePlanFromPopUp }
                            /> 
                        </div> : null}                 
               
            </div>
        </>
    );
}

export default SalesAddEdit;