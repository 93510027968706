import React, {  useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/auth'

import { businessService } from '../../Services/businessService';

import PullApartSelStoreUI from "./PullApartSelStoreUI";

const PullApartSelStore = () => {
    const auth = useAuth();
    const navigate =  useNavigate();   

    const [stateBusiness, setStateBusiness] = useState([]);
        
    useEffect(() => {
        businessService.getAllByCompanyId(auth.user.token, auth.user.companyId).then( res => {   
            let newList = [];
            auth.user.commerceList.map(item =>  {
                const index = res.data.findIndex((x) => x.id === item);
                if(index != -1) {
                    newList = [...newList, res.data[index]];
                }
            });               
            setStateBusiness(newList);                
        });   
    }, []);


    const onClickBtnOpenPos = (e, id) => {
        navigate(`/SelOperation/${id}`, { replace: true });
    }

    return (
        <>
            <h2>Seleccionar sucursal</h2>
            <PullApartSelStoreUI
                stateBusiness = {stateBusiness}    
                onClickBtnOpenPos = {onClickBtnOpenPos}        
            />
        </>
    );
}

export default PullApartSelStore;