import React, {useState} from "react";
import { NavLink } from "react-router-dom";

import { PhoneInput,
    defaultCountries,
    parseCountry 
} from 'react-international-phone';

import 'react-international-phone/style.css';

import './commerceRegister.css';

const CommerceregisterUI = (props) =>{
    const countries = defaultCountries.filter((country) => {
        const { iso2 } = parseCountry(country);
        return ['us', 'mx'].includes(iso2);
    });
   

    const handleChange = (event) => {                
        props.setstateRegisterData({...props.stateRegisterData, phone: event});
    };

    return (
        <>
            <div className="container d-flex align-items-center justify-content-center" style={{minheight: "100vh"}}>
                <div className="d-flex flex-column justify-content-between">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-xl-5 col-md-10 ">
                            <div className="card card-default mb-0">
                                <div className="card-header pb-0">
                                    <div className="app-brand w-100 d-flex justify-content-center border-bottom-0">
                                        <a className="w-auto pl-0" href="/login">
                                            <img src="./assets/images/logoaxify.jpg" alt="Mono" />
                                            <span className="brand-name text-dark"></span>
                                        </a>                                    
                                    </div>
                                </div>                            
                                <div className="card-body px-5 pb-5 pt-0">
                                    <h4 className="text-dark text-center mb-5">Alta de usuario sucursal</h4>
                                    <div className="row">
                                        <div className="form-group col-md-12 mb-4">
                                            <input type="text" className="form-control input-lg"    
                                                aria-describedby="nameHelp" placeholder="Nombre"                     
                                                name="userName" 
                                                id="userName"                                                
                                                onChange={props.handleChange}
                                                value={props.stateRegisterData.userName}
                                            />                          
                                        </div>
                                        <div className="form-group col-md-12">
                                            <input type="text" className={`form-control input-lg ${props.stateCompanyValid ? 'is-valid':'is-invalid'}`}  
                                                aria-describedby="businessnameHelp" placeholder="Comercio" 
                                                name="businessName" 
                                                id="businessName"                                                                              
                                                onChange={props.handleChange}    
                                                value={props.stateRegisterData.businessName}                                            
                                                onBlur={e => props.onBlur_inputCompanyName(e)}
                                            />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <PhoneInput 
                                                        inputStyle={{width:'100%'}}                                          
                                                        initialCountry="mx"                                                        
                                                        countries={countries}             
                                                        value={props.stateRegisterData.phone}                                           
                                                        onChange={(phone) => handleChange(phone)}                                                        
                                                    />
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <input type="email" className={`form-control input-lg ${props.stateisEmailFromatValid ? 'is-valid':'is-invalid'}`}                         
                                                aria-describedby="userHelp" placeholder="Email"                       
                                                name="user"
                                                id="user"
                                                onChange={props.handleChange}
                                                onBlur={e => props.onBlur_inputEmail(e)}
                                                value={props.stateRegisterData.user}
                                            />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <input type="password" className="form-control input-lg"                    
                                            aria-describedby="passwordHelp" placeholder="Password"                            
                                            name="password" 
                                            id="password"
                                            onChange={props.handleChange}
                                            value={props.stateRegisterData.password}                                                
                                            />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <input type="password" className="form-control input-lg"                    
                                            aria-describedby="passwordHelp" placeholder="Confirmar password"                            
                                            name="password2" 
                                            id="password2"
                                            onChange={props.handleChange}
                                            value={props.stateRegisterData.password2}                                                
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between mb-3">
                                                <div className="custom-control custom-checkbox mr-3 mb-3">
                                                <input type="checkbox" className="custom-control-input" id="customCheck2" />
                                                <label className="custom-control-label" htmlFor="customCheck2">Yo estoy de acuerdo con los terminos y condiciones.</label>
                                                </div>
                                            </div>


                                            <div className="row">         
                                                <div className="col">
                                                    {
                                                        props.stateCompanyValid > 0 ?
                                                            <button type="button" className="btn btn-primary btn-pill mb-4" onClick={e => props.onclickbuttonCrear(e)}>
                                                                Crear cuenta
                                                            </button>
                                                        : null
                                                    }
                                                </div>                                       
                                                <div className="col" style={{textAlign: "right"}}>
                                                    <NavLink                                                        
                                                        className="btn btn-primary btn-pill mb-4"
                                                        aria-current="page"
                                                        to="/login"
                                                        >
                                                            Regresar           
                                                    </NavLink>                                                  
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>        
            </div>
        </>
    )
}

export default CommerceregisterUI;